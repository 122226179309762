<template>
    <div>
        <div class="row w-100 justify-content-center">
            <h3 class="info-text">Payment Method {{ title }}</h3>
        </div>
        <div class="row w-100 justify-content-center">
            <div class="col-lg-12" style="max-width:700px">
                
                <collapse :multiple-active="false" ref="collapse" :activeIndex="activeIndex">
                    
                    <collapse-item @collapse-click="init" id="creditCard">
                        <template slot="title" >
                            <mdicon name="credit-card" size="32" class="tim-icons text-white"/>&nbsp;&nbsp;Card Payment
                        </template>
                        <CreditCard ref="creditCard" 
                        :existingCard="existingCard"
                        :cards="cards" 
                        :wizardModel="wizardModel" 
                        @loading="loading"/>
                    </collapse-item>
                    <!--
                    <collapse-item @collapse-click="init" id="sepaTransfer">
                        <template slot="title">
                            <SepaSvg/>
                            &nbsp;&nbsp; Single Euro Payments Area (SEPA) Transfer
                        </template>
                        <Sepa ref="sepaTransfer" :sepas="sepas" :wizardModel="wizardModel" @loading="loading"/>
                    </collapse-item>                   
                    -->
                    <collapse-item @collapse-click="init" id="bankTransfer">
                        <template slot="title">
                            <mdicon name="bank" size="32" class="tim-iconsext-white"/>&nbsp;&nbsp;Bank Transfer
                        </template>
                        <Bank ref="bankTransfer" :wizardModel="wizardModel"/>
                    </collapse-item>
                    
                </collapse>
            </div>
        </div>
    </div>
</template>

<script>
import { TabPane, Tabs, Collapse, CollapseItem } from 'src/components';
import CreditCard from './Methods/CreditCard/Layout';
//import SepaSvg from './Stripe/brandSvgs/Sepa.vue'
//import Sepa from './Methods/Sepa/Layout.vue'
import Bank from './Methods/Bank/Layout.vue'
const sepaCountries = require('./sepaCountries.json')
export default {
    components: {
        Bank,
        //Sepa,
        //SepaSvg,
        TabPane, 
        Tabs,
        Collapse,
        CollapseItem,
        CreditCard
    },
    props:{
        wizardModel: {
            type: Object
        },
        paymentMethod: {
            type: String
        }
    },
    data(){
        return {
            activeIndex: 0,
            paymentType: 'creditCard',
            //paymentType: null,
            //paymentType: this.paymentMethod,
            //activeIndex: 1,
            //paymentType: 'sepaTransfer',
            //activeInex: 2,
            //paymentType: 'bankTransfer',
            cards: [],
            existingCard: null,
            sepas: [],
            title: null,
            card: null,
            sepaCountries: sepaCountries
        }
    },
    computed:{
        isSepa(cCode){
            return !!this.sepaCountries[this.wizardModel.billing_entity_country_code]
        }
    },
    methods:{
        async init(paymentType = this.paymentType){
            this.paymentType = paymentType
            switch(paymentType){
                case 'bankTransfer':
                    this.activeIndex = 1
                break;
                default:
                    this.activeIndex = 0
                    await this.listMethods()
            }
            await this.$refs[this.paymentType].init()
        },
        loading(value){
            this.$emit('loading', value)
        },
        
        async validate(){
            try{
                //TODO: check if to add tax to charge    
                
                const { amount } = this.wizardModel
                let { intent, tax } = this.wizardModel

                let fees = 0, percent = 0, base = 5

                if( "id" in intent ){
                    const { metadata } = intent
                    
                    if("fees" in metadata) 
                        fees = metadata.fees

                    if("tax" in metadata) 
                        tax = metadata.tax
                }else{
                    switch(this.paymentType) { 
                        case 'creditCard':
                            base = 0
                            percent = (Number(amount) * 0.029).toFixed(1)
                        break;
                        case 'sepaTransfer':
                            percent = (Number(amount) * 0.015).toFixed(1)
                        break;    
                        default:
                            fees = 0
                    }
                    
                    fees = Number(base) + Number(percent)
                    intent = await this.createIntent(fees)  

                }

                const { id, client_secret } = intent

                const validated = await this.$refs[this.paymentType].validate()
                const status = !!validated
                this.$emit("on-validated", status, {
                    ...validated, 
                    ...{ paymentMethod: this.paymentType }, 
                    ...{ fees: fees },
                    ...{ intent: {
                        id, client_secret
                    } }
                } );
                return status
                
                //return false
            }catch(e){
                this.$toast.error(e.message)
            }
        },
        //async initcreditCard(){
        //    await this.$refs[this.paymentType].init()
        //    const { id, client_secret } = this.wizardModel
        //},
        reset(){
            //this.activeIndex = 0
            //this.paymentType = 'creditCard'

            //this.activeIndex = 1
            //this.paymentType = 'sepaTransfer'
            //this.activeIndex = 2
            //this.paymentType = 'bankTransfer'
            
            this.title= null
            this.card= null
        },
        async createIntent(fees){
                const intentData = { 
                    ...this.wizardModel, 
                    //...this.model, 
                    ...{  
                        fees,
                        currency: this.$store.getters.account.client_account_currency 
                    } 
                }

                const {status, data} = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/billing/stripe/payment/intent/create`, intentData)
                if(status == 200) return data
            
        },
        async listMethods(){
            this.loading(true)
            const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/billing/stripe/payment/method/read`, this.wizardModel)
            this.loading(false)
            if(res && res.status == 200){
                let { data:methods } = res
                
                if(methods){
                    this.cards = methods.filter(item => item.type == 'card').sort((a,b) => b.created - a.created)
                    this.sepas = methods.filter(item => item.type == 'sepa_debit').sort((a,b) => b.created - a.created)
                    if(this.cards.length)
                        this.$refs['creditCard'].setCard(this.cards[0])
                    
                }
                
                

            }
        },
        async currentTab(){},
    }
}
</script>

<style>

</style>