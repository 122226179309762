<template>
 <card>
  
        <h5 slot="header" class="title">Account Details</h5>
        <ValidationObserver v-slot="{ handleSubmit, valid }">
        <form @submit.prevent="handleSubmit(updateAccountDetailes)">
          <div class="row">
            <div class="col-lg-12">
              <ValidationProvider
              rules="required|account_name:4"
              v-slot="{ passed, failed, errors }">
              <base-input
                
                :class="{ 
                  'has-success': !errors[0] && account.client_account_name, 
                  'has-danger': errors[0] || !account.client_account_name 
                }"
                type="text"
                label="Account Name"
                placeholder="Account Name"
                v-model="account.client_account_name"
              >
              </base-input>
              <span class="validation-error">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <base-input
                type="text"
                label="Account UUID"
                :disabled="true"
                :textLight="true"
                placeholder="Account UUID"
                v-model="account.client_accounts_cliend_uuid"
              >
              </base-input>
            </div>        
          </div>
          <div class="row">
            <div class="col-lg-4">
              <base-input
                type="text"
                label="Current Balace"
                :disabled="true"
                :textLight="true"
                placeholder="Current Balace"
                v-model="account.client_account_balance"
              >
              </base-input>
            </div> 
            <div class="col-lg-4">
              <base-input
                type="text"
                label="Credit"
                :disabled="true"
                :textLight="true"
                placeholder="Credit"
                v-model="account.client_allowed_balance"
              >
              </base-input>
            </div>        
            <div class="col-lg-4">
              <base-input
                type="text"
                label="Currency"
                :disabled="true"
                :textLight="true"
                placeholder="Currency"
                v-model="account.client_account_currency"
              >
              </base-input>
            </div>        
          </div>
          <div class="row">
            <div class="col-lg-12">
              <ValidationProvider
              rules="required|numeric|greaterThanZero"
              v-slot="{ passed, failed, errors }">

              <base-input
                type="text"
                label="Low balance limit notification"
                placeholder="Low balance limit notification"
                :class="{ 
                  'has-success': !errors[0] && account.client_low_balance_notification_limit, 
                  'has-danger': errors[0] || !account.client_low_balance_notification_limit 
                }"
                v-model="account.client_low_balance_notification_limit"
              >
              </base-input>
              <span class="validation-error">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>                    
          </div>

           

          <base-button :loading="submitInProccess" simple :disabled="!valid" native-type="submit" type="primary" class="btn-fill">
            Save account details
          </base-button>
        </form> 
        </ValidationObserver>   
      </card>
</template>
<script>
import {Select, Option} from 'element-ui'
import { extend } from "vee-validate";
import { required,min,max,numeric } from "vee-validate/dist/rules";

extend("account_name",{
  ...min,
  message: 'Name must be at least 4 charachters long'
})
extend("max",{
  ...max,
  message: 'Number is too long'
})

extend("required", {
  ...required,
  message: 'This field is required'
});

extend("numeric", {
  ...required,
  message: 'This field is required'
});
extend(
  'greaterThanZero',{
   getMessage: field =>  field + ' needs to be > zero.',
   validate: (value) => {
     // value must be > zero
     if (value > 0 ) return true;
     return false;
    }
})


export default {
  name: 'account-form',
  components:{
    
  },
  props: {
    //accountsObject:{
    //  type: Object,
    //  description: 'Account JSON object'
    //}
  },
  //watch: {
  //  /*
  //    Use watch istead of loading icon
  //  */
  //  accountsObject: function(oldValue, newValue){
  //    this.account = this.accountsObject 
  //  }
  //},
  //mounted: function(){ 
  //    this.account = this.accountsObject;
  //},
  data() {
    return {      
      submitInProccess: false,
      account: {
        client_account_name: null,
        client_accounts_cliend_uuid:  null,
        client_account_currency: null,
        client_low_balance_notification_limit: null
      }
    };
  },
  methods: {
    async fetch(){
      this.submitInProccess = !this.submitInProccess
      const res = await this.$http.get(`${process.env.VUE_APP_API_ENDPOINT}/gateway/accounts/read`)

      this.submitInProccess = !this.submitInProccess
      if(res && res.status == 200){
        this.account = {...res.data}
      }
    },
    
    async updateAccountDetailes() {
      
      this.submitInProccess = !this.submitInProccess;
      const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/gateway/accounts/update`, this.account)
      this.submitInProccess = !this.submitInProccess;
      if(res && res.status == 200){
        return this.$toast.success("Billing details updated");
      }
      return this.$toast.error(`An error accured ${res.status}`);
      
    },
    
  }
};
</script>
<style>
.el-table{
  border:none
}
.el-table__cell{
  background: none;
}

.validation-error{
  color:bisque !important;
  font-size:12px
}

</style>
