<template>
<div class="row">
  <div class="col-lg-12">
    
    <i v-if="submitInProccess" class="fa fa-spinner fa-spin" style="color:white"></i>
    <div v-else>
      <div id="expand-table" class="p-2">
        <base-table 
          :data="paginatedRows" 
          thead-classes="text-primary">
          <template slot="columns" slot-scope="{ columns }">
            <th>Group</th>
            <th>Custom1
              &nbsp; 
                <span @click="sortme('desc', 'contact_custom1')" style="cursor:pointer" v-if="sort.direction=='asc'" >
                    <mdicon name="sort-ascending" size="14"/>
                </span>
                <span style="cursor:pointer" @click="sortme('asc', 'contact_custom1')" v-if="sort.direction=='desc'" >
                    <mdicon name="sort-descending" size="14"/>
                </span>
            </th>
            <th>Custom2
              &nbsp; 
                <span @click="sortme('desc', 'contact_custom2')" style="cursor:pointer" v-if="sort.direction=='asc'" >
                    <mdicon name="sort-ascending" size="14"/>
                </span>
                <span style="cursor:pointer" @click="sortme('asc', 'contact_custom2')" v-if="sort.direction=='desc'" >
                    <mdicon name="sort-descending" size="14"/>
                </span>
            </th>
            <th>Custom3
              &nbsp; 
                <span @click="sortme('desc', 'contact_custom3')" style="cursor:pointer" v-if="sort.direction=='asc'" >
                    <mdicon name="sort-ascending" size="14"/>
                </span>
                <span style="cursor:pointer" @click="sortme('asc', 'contact_custom3')" v-if="sort.direction=='desc'" >
                    <mdicon name="sort-descending" size="14"/>
                </span>
            </th>
            <th>Custom4
              &nbsp; 
                <span @click="sortme('desc', 'contact_custom4')" style="cursor:pointer" v-if="sort.direction=='asc'" >
                    <mdicon name="sort-ascending" size="14"/>
                </span>
                <span style="cursor:pointer" @click="sortme('asc', 'contact_custom4')" v-if="sort.direction=='desc'" >
                    <mdicon name="sort-descending" size="14"/>
                </span></th>
            <th>Custom5
              &nbsp; 
                <span @click="sortme('desc', 'contact_custom5')" style="cursor:pointer" v-if="sort.direction=='asc'" >
                    <mdicon name="sort-ascending" size="14"/>
                </span>
                <span style="cursor:pointer" @click="sortme('asc', 'contact_custom5')" v-if="sort.direction=='desc'" >
                    <mdicon name="sort-descending" size="14"/>
                </span>
            </th>
            <th>Total
              &nbsp; 
                <span @click="sortme('desc', 'count')" style="cursor:pointer" v-if="sort.direction=='asc'" >
                    <mdicon name="sort-ascending" size="14"/>
                </span>
                <span style="cursor:pointer" @click="sortme('asc', 'count')" v-if="sort.direction=='desc'" >
                    <mdicon name="sort-descending" size="14"/>
                </span></th>
          </template>
          
          <template slot-scope="{ row, index }">
            <td>{{ row.contact_list_name }}</td>
            <td>{{ row.contact_custom1 }}</td>
            <td>{{ row.contact_custom2 }}</td>
            <td>{{ row.contact_custom3 }}</td>
            <td>{{ row.contact_custom4 }}</td>
            <td>{{ row.contact_custom5 }}</td>
            <td>{{ row.count }}</td>
          </template>
        </base-table>
        <div class="d-flex justify-content-center">        
          <base-pagination 
            
            type="info"
            :pageCount="pageCount" 
            :perPage="perPage"
            :total="totalRecords"
            :value="page"
            :pagesToDisplay=10
            @input="pagination"></base-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
  
</template>

<script>

import { BaseTable, BasePagination} from '@/components';
const api_endpoint = process.env.VUE_APP_API_ENDPOINT

export default {
  props: [ 'rowData', 'expandedData'], 
  data() {
    return {
      submitInProccess: false,
      paginatedRows: [],
      rows: [],
      pageCount: 1,
      perPage: 5,
      totalRecords: 1,
      page: 1,
      sort: {
        by: 'contact_custom1',
        direction: 'desc'
      }
    }
  },
  mounted(){
    this.getRows()
  },
  watch:{
    'expandedData': function(nv) {
      this.submitInProccess = !this.submitInProccess
      console.log(nv)
      //this.paginatedRows = [...nv]
      this.rows = [...nv]
      this.pagination(1)
      
    }
  },
  components: {
    BaseTable,
    BasePagination,
  },
  methods:{
    sortme(direction, column){
      this.sort = {direction: direction, by:column }
      this.getRows()
    },
    pagination(page){
      this.page = page 
      this.paginatedRows = [...this.rows].slice( ( this.page - 1 )  * this.perPage, this.page * this.perPage)
    },
    async getRows(){
      this.submitInProccess = !this.submitInProccess
      this.$emit('getMoreDataRows', { ...this.rowData, ...this.sort})
      //this.submitInProccess = !this.submitInProccess
      //const res = await this.$http.post(`${api_endpoint}/contacts/read/countries/expended`, { ...this.rowData, ...this.sort})
      //if(res && res.status == 200 && res.data) this.rows = res.data
      //this.pageCount = Number((this.rows.length / this.perPage).toFixed(0))
      //this.pagination(1)
      //this.submitInProccess = !this.submitInProccess
    }
  }

}
</script>

<style>
  #expand-table .page-link {
    border-radius: 10px !important;
  }
</style>