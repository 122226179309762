var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var valid = ref.valid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('card',{staticClass:"card card-register card-white"},[_c('template',{slot:"header"},[_c('img',{staticClass:"card-img",staticStyle:{"max-height":"300px"},attrs:{"src":"/img/card-primary.png","alt":"Card image"}}),_c('h4',{staticClass:"card-title"},[_vm._v("Email")])]),_c('div',[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:{ 'has-success': !errors[0] && _vm.user_email, 'has-danger': errors[0] },attrs:{"required":"","type":"email","label":"Email address"},model:{value:(_vm.user_email),callback:function ($$v) {_vm.user_email=$$v},expression:"user_email"}}),_c('span',{staticClass:"validation-error-register",domProps:{"innerHTML":_vm._s(errors[0])}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"confirm","rules":"required|passwordValidator"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input',{class:{ 'has-success': !errors[0] && _vm.user_password, 'has-danger': errors[0] },attrs:{"required":"","type":"password","label":"Password"},model:{value:(_vm.user_password),callback:function ($$v) {_vm.user_password=$$v},expression:"user_password"}}),_c('span',{staticClass:"validation-error-register",domProps:{"innerHTML":_vm._s(errors[0])}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|password:@confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input',{class:{ 'has-success': !errors[0] && _vm.user_password_confirmation, 'has-danger': errors[0] },attrs:{"required":"","type":"password","label":"Confirm Password","error":errors[0]},model:{value:(_vm.user_password_confirmation),callback:function ($$v) {_vm.user_password_confirmation=$$v},expression:"user_password_confirmation"}})]}}],null,true)}),_c('div',{staticClass:"category form-category"},[_vm._v("* Required fields")])],1),_c('ValidationProvider',{attrs:{"rules":"agreeValidator"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-checkbox',{staticClass:"text-left",model:{value:(_vm.user_agree),callback:function ($$v) {_vm.user_agree=$$v},expression:"user_agree"}},[_vm._v(" I agree to the "),_c('a',{attrs:{"target":"blank","href":"https://message-deck.com/General-terms-and-conditions/"}},[_vm._v(" terms & conditions ")]),_vm._v(" and "),_c('a',{attrs:{"target":"blank","href":"https://message-deck.com/privacy-policy/"}},[_vm._v(" privacy policy ")]),_vm._v(". ")]),_c('span',{staticClass:"validation-error-register",domProps:{"innerHTML":_vm._s(errors[0])}})]}}],null,true)}),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"loading":_vm.submitInProccess,"disabled":!valid || !_vm.recaptcha,"native-type":"submit","type":"primary","round":"","block":"","size":"lg"}},[_vm._v(" Signup ")]),_c('div',{staticClass:"d-flex justify-content-left"},[_c('base-button',{staticClass:"border-0 pl-0",attrs:{"simple":"","small":"","round":"","type":"primary"},on:{"click":_vm.Back}},[_c('i',{staticClass:"tim-icons icon-minimal-left"}),_vm._v("     Signup options ")])],1)],1),_c('div',{staticClass:"d-flex justify-content-center mt-3"},[_c('VueRecaptcha',{ref:"recaptcha",attrs:{"sitekey":_vm.siteKey,"load-recaptcha-script":true},on:{"verify":_vm.onVerify,"error":_vm.onVerifyError,"expired":_vm.onExpired}})],1)],2)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }