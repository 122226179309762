var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('card',[_c('h5',{staticClass:"title",attrs:{"slot":"header"},slot:"header"},[_vm._v("Change password")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var valid = ref.valid;
return [(_vm.submitInProccess)?_c('Loading'):_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.update)}}},[(_vm.hasCurrentPassword)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('ValidationProvider',{attrs:{"name":"currentPassword","rules":"required|passwordValidator"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input',{class:{ 
                  'has-success': !errors[0] && _vm.user.currentPassword, 
                  'has-danger': errors[0] 
              },attrs:{"toggleIcon":"","addonRightIcon":"eye","addonRightIconToggle":"eye-off","type":"password","label":"Current Password","placeholder":"Enter Current Password","error":errors[0]},model:{value:(_vm.user.currentPassword),callback:function ($$v) {_vm.$set(_vm.user, "currentPassword", $$v)},expression:"user.currentPassword"}})]}}],null,false,4147622911)})],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('ValidationProvider',{attrs:{"name":"password","rules":"required|passwordValidator"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('base-input',{class:{ 
                  'has-success': !errors[0] && _vm.user.newPassword, 
                  'has-danger': errors[0] 
              },attrs:{"toggleIcon":"","addonRightIcon":"eye","addonRightIconToggle":"eye-off","type":"password","label":"New Password","placeholder":"Enter New Password","error":errors[0]},model:{value:(_vm.user.newPassword),callback:function ($$v) {_vm.$set(_vm.user, "newPassword", $$v)},expression:"user.newPassword"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('base-input',{class:{ 
                  'has-success': !errors[0] && _vm.user.verifyNewPassword, 
                  'has-danger': errors[0] 
              },attrs:{"toggleIcon":"","addonRightIcon":"eye","addonRightIconToggle":"eye-off","type":"password","name":"password","label":"Verify New Password","placeholder":"Verify New Password","error":errors[0]},model:{value:(_vm.user.verifyNewPassword),callback:function ($$v) {_vm.$set(_vm.user, "verifyNewPassword", $$v)},expression:"user.verifyNewPassword"}})]}}])})],1)]),_c('base-button',{staticClass:"btn-fill",attrs:{"loading":_vm.submitInProccess,"simple":"","disabled":!valid,"native-type":"submit","type":"primary"}},[_vm._v(" Update password ")])],1)]}}])})],1)],1),_c('div',{staticClass:"col-lg-6"},[_c('TWOFALogin',{attrs:{"user":_vm.user}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }