<template>
  <card>
    <template slot="header">
        <h5 class="card-category">Group</h5>
        <h3 class="card-title">Members of {{ currentGroup }} contacts</h3>
    </template>

    
    <i v-if="submitInProccess" class="fa fa-spinner fa-spin" style="color:white"></i>
    <div v-else>

        <div class="d-flex justify-content-center">  
            <base-pagination 
                type="info"
                :pageCount="pageCount" 
                :perPage="perPage"
                :total="totalRecords"
                :value="page"
                :pagesToDisplay=10
                @input="pagination">
            </base-pagination>
        </div>

        <el-table
            
            id="campaigns-table"
            :data="paginatedRows"
            style="width: 100%;margin-bottom: 20px;min-height:600px">
            <el-table-column prop="contact_country_code" label="" width="50">
                <template slot-scope="scope">
                    <country-flag :country='scope.row.contact_country_code' size='normal'/>
                </template>
            </el-table-column>
            <!--
            <el-table-column type="expand" >
                <template slot-scope="scope">
                    <ContactsByCountryBreakDown 
                        :rowData="scope.row" 
                        :expandedData="expandedData"
                        :submitInProccess="submitInProccess" 
                        @getMoreDataRows="getMoreDataRows"
                    />
                </template>
            </el-table-column>
            -->
            <el-table-column prop="contact_country_code" label="Country">
                <template slot-scope="scope">
                    {{ ccodeTocName[scope.row.contact_country_code] ? ccodeTocName[scope.row.contact_country_code].name : scope.row.contact_country_code }}
                </template>
            </el-table-column>
            <el-table-column prop="contact_mcc" label="MCC">
                <template slot-scope="scope">
                    {{ scope.row.contact_mcc || 'N/A' }}
                </template>
            </el-table-column>
            <el-table-column prop="contact_mnc" label="MNC">
                <template slot-scope="scope">
                    {{ scope.row.contact_mnc || 'N/A' }}
                </template>
            </el-table-column>
            <el-table-column prop="contact_country_code" label="Operator">
                <template slot-scope="scope">
                    <div v-if="scope.row.contact_mcc && scope.row.contact_mnc">
                        <span v-if="mccmnc[`${scope.row.contact_mcc}${scope.row.contact_mnc}`]">
                        {{ mccmnc[`${scope.row.contact_mcc}${scope.row.contact_mnc}`]['network_name'] }}
                        </span>
                        <span v-else>
                            {{ scope.row.contact_mnc ? scope.row.contact_mnc : '-' }}{{ scope.row.contact_mnc ? scope.row.contact_mnc : '-' }}
                        </span>
                    </div>
                    <div v-else>
                        N/A
                    </div>
                    
                    
                    
                </template>
            </el-table-column>
            <el-table-column prop="" label="Last Status">
                <template slot-scope="scope">
                    {{ scope.row.contact_current_dlr_mask_code == 1 ? 'Delivered' : 'Failed/Other' }}
                </template>
            </el-table-column>
            
            <el-table-column prop="contact_last_times" label="Times Sent">
                <template slot-scope="scope">
                    {{ scope.row.contact_last_times || 0}}
                </template>
            </el-table-column>
            <el-table-column prop="groups_size" label="Total">
            </el-table-column>
        </el-table>

    </div>
  </card>
</template>

<script>
import { BasePagination} from '@/components';
import {Table, TableColumn, Pagination } from 'element-ui'
import ContactsByCountryBreakDown from './ContactsByCountryBreakDown.vue'
import CountryFlag from 'vue-country-flag'
const  mccmnc = require('../../../../json/mccmnc.json')
const ccodeTocName = require('../../../../json/iso-3166-2-country')
const api_endpoint = process.env.VUE_APP_API_ENDPOINT


export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Pagination.name]: Pagination,
        CountryFlag,
        BasePagination,
        ContactsByCountryBreakDown
    },
    props:[
        'submitInProccess',
        'currentGroup', 
        'groupMembersArray', 
        'totalRecords'
    ],
    watch: {
        'groupMembersArray': function (nv) {
            this.pagination(1)
        }
    },
    data() {
        return {
            expandedSubmitInProcess: false,
            expandedData: [],
            paginatedRows: [],
            mccmnc: mccmnc,
            ccodeTocName: ccodeTocName,
            pageCount: 1,
            perPage: 10,
            page: 1
        }
    },
    methods: {
        async getMoreDataRows(filterData){
            if(this.expandedSubmitInProcess) return
            
            this.expandedSubmitInProcess = !this.expandedSubmitInProcess
            this.$emit('expandedSubmitInProcess', this.expandedSubmitInProcess)

            const res = await this.$http.post(`${api_endpoint}/contacts/read/countries/expended`, filterData)
            if(res && res.status == 200 && res.data) 
              this.expandedData = res.data
            this.expandedSubmitInProcess = !this.expandedSubmitInProcess
            this.$emit('expandedSubmitInProcess', this.expandedSubmitInProcess)

        },
        pagination(page){
            this.page = page
            this.pageCount = Number((this.groupMembersArray.length / this.perPage).toFixed(0))
            this.paginatedRows = [...this.groupMembersArray].slice( ( this.page - 1 )  * this.perPage, this.page * this.perPage)
        }
    }
}
</script>

<style>

</style>