<template>
<div>
  <h2>Payment Summery</h2>
  <div class="row justify-content-center">
    

    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex justify-content-between">
          <h4>Purchase Amount</h4>
          <h4>{{amount}}&nbsp;EUR</h4>
          </div>
        </div>
      </div>

      
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex justify-content-between">
          <h4>VAT ({{vatpct}}%)</h4>
          <h4>{{ ( amount * (vatpct / 100) ).toFixed(2) }}&nbsp;EUR</h4>
          </div>
        </div>
      </div>
      <hr>

      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex justify-content-between">
          <h4>Total To Pay</h4>
          <h4>{{ ( amount * (vatpct / 100) ) + amount}} &nbsp;EUR</h4>
          </div>
        </div>
      </div>


        
    </div>
  </div>
</div>
</template>

<script>

export default {
    components: {

    },
    props: {
      amount: {
        type: Number,
        default: 0
      },
      vatpct: {
        type: Number,
        default: 0
      }
    }
}
</script>

<style>

</style>