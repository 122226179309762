<template>
  <div class="row" v-loading="submitInProccess">
        <div class="col-lg-12">
            <card style="min-height:600px">
                <template slot="header">
                    <h4 class="card-title">Test Summery</h4>
                </template>
                <h6>Operators:</h6>
                <div id="operators" >
                    <p v-if="!operators.length" class="text-warning">
                        Please select at least 1 operator
                    </p>
                    
                    <div v-for="operator in operators" style="float:left">
                        <badge type="info">
                            <span slot="badgeContent">
                                {{operator.country}} - {{operator.network}} ({{operator.mccmnc}})
                                <span style="cursor:pointer"  @click="operatorRemove(operator)">
                                    <mdicon name="close" size="16" />
                                </span>
                                

                            </span>
                            
                        </badge>
                        &nbsp;&nbsp;
                    </div>
                </div>
                
                <div style="margin-top: 10px;"></div>
                <h6 >Sender IDs:</h6>
                <div id="senders" >
                    <p v-if="!senderIds.length" class="text-warning">
                        Please add at least 1 sender id
                    </p>
                    <div v-for="sender in senderIds" style="float:left">
                        <badge type="info">
                            <span slot="badgeContent">
                                {{sender}}
                            </span>                    
                        </badge>
                        &nbsp;&nbsp;
                    </div>
                </div>
                

                
                <div style="margin-top: 10px;"></div>
                <h6>Total tests: {{ Number(operators.length * senderIds.length) }}</h6>    
                <div id="container">
                    <span v-for="operator in operators">
                        <span v-for="sender in senderIds">
                            <summery-item 
                            :operator="operator"
                            :sender="sender"
                            :text="text">
                            </summery-item>
                        </span>
                    </span>
                    <!--
                    <span v-for="operator in operators">
                    <span v-for="sender in senderIds">
                        {{operator.network}}  {{ sender }} {{ text }}
                    </span>
                    </span>
                    -->
                

                </div>
            
                <template slot="footer">
                    <base-button 
                    @click="modalConfrim = true"
                    type="primary"
                    :disabled="!operators.length || !senderIds.length || !text || !selectedToken"
                    :loading="submitInProccess"
                    style="margin:0">Run</base-button>
                </template>
                
                
            </card>
        </div>

        <modal :show.sync="modalConfrim">
            <template slot="header">
                <h4 class="text-default">
                    <i class="fa fa-list-ol" aria-hidden="true"></i>
                    &nbsp;&nbsp;Test review and confirmation
                </h4>
            </template>    
            <div class="d-flex justify-content-center">
                <ul class="list-group text-default border" style="max-width:380px" v-if="selectedToken">
                    <li class="list-group-item text-default">
                        <div class="d-flex justify-content-between">
                            <div><i class="fa fa-key" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;<u>Api key:</u></div>
                            <div>
                            <b><u>{{ selectedToken.http_api_jwt_short_token }}</u>&nbsp;&nbsp;<small>({{ selectedToken.http_api_jwt_type }})</small></b>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item text-default">
                        <div class="d-flex justify-content-between">
                            <div><i class="fa fa-mobile" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;<u>Mobile network operators:</u></div>
                            <div>
                                <b><u>{{ operators.length  }}</u>&nbsp;&nbsp;<small>(MNOs)</small></b>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item text-default">
                        <div class="d-flex justify-content-between">
                            <div><i class="fa fa-bullhorn" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;<u>Sender ids:</u></div>
                            <div>
                                <b><u>{{ senderIds.length  }}</u>&nbsp;&nbsp;<small>(Count)</small></b>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item text-default">
                        <div class="d-flex justify-content-between">
                            <div><i class="fa fa-text-width" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;<u>Message length is:</u></div>
                            <div>
                                <b><u>{{ (testText ? testText.length : 0) }}</u>&nbsp;&nbsp;<small>(Charachters)</small></b>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item text-default">
                        <div class="d-flex justify-content-between">
                            <div><i class="fa fa-font" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;<u>Message coding:</u>&nbsp;&nbsp;&nbsp;</div>
                            <div>
                                <b><u>{{ ( textIsGsm ? '7bit GSM' : 'Unicode' )  }}</u>&nbsp;&nbsp;<small>(GSM7/Unicode)</small></b>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item text-default">
                        <div class="d-flex justify-content-between">
                            <div><i class="fa fa-bolt" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;<u>Tests to perform:</u></div>
                            <div>
                                <b><u>{{ operators.length * senderIds.length * messageCount }}</u>&nbsp;&nbsp;<small> (MNOs x Senders)</small></b>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item text-default">
                        <div class="d-flex justify-content-between">
                            <div>
                                <i class="fa fa-credit-card" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;<u>Test price:</u>
                            </div>
                            <div>
                                <b><u>{{ operators.length * senderIds.length * messageCount * 0.4 }} &#8364;</u>&nbsp;&nbsp;<small>(Excluding SMS transit costs)</small></b> 
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            
            
            
            <template slot="footer">
                <base-button style="margin:15px" type="primary" @click="confirmTest">Confirm</base-button>
                <base-button style="margin:15px" type="secondary" class="ml-auto" @click="modalConfrim = false;">Close
                </base-button>
            </template>
            
        </modal>
    </div>
</template>

<script>

import SummeryItem from './SummeryItem.vue'
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import {
    Badge,
    Modal
} from 'src/components/index';
import BaseButton from '../../../components/BaseButton.vue';



export default {
    components: {
        Badge,
        SummeryItem,
        BaseButton,
        Modal
    },
    data() {
        return {
            modalConfrim: false
        }
    },
    props:[
        'operators', 
        'senderIds', 
        'text', 
        'messageCount', 
        'testText', 
        'textIsGsm', 
        'selectedToken', 
        'submitInProccess'
    ],
    methods: {
        operatorRemove(data){
            this.$emit('operatorRemove', data)
        },
        confirmTest(){
            this.modalConfrim = !this.modalConfrim
            this.$emit('confirmTest', true)
        },
        initScrollbar() {
            new PerfectScrollbar('#container');
            new PerfectScrollbar('#operators');
            new PerfectScrollbar('#senders');
        }
        
    },
    mounted() {
        this.initScrollbar();
    }
}
</script>

<style scoped>
#operators, #senders {
    display: inline-block;
    position: relative;
    min-width: 100%;
    height:75px;    
    border-radius: 0.2857rem;
    padding:5px;
    border-bottom: 1px solid #2b3553;
}
#container{
    position: relative;
    height:200px;
    border: 1px solid #2b3553;
    border-radius: 0.2857rem;
    padding:5px;
}

.list-group-item{
    padding: 0.8rem 0.8rem !important;

}

</style>