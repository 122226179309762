<template>
   <card type="secondary"
                  header-classes="bg-white pb-5"
                  body-classes="px-lg-5 py-lg-5"
                  class="border-0 mb-0">
                <template>
                    <div class="text-white text-center mb-3">
                        <small>Invite a user</small>
                    </div>
                </template>
                <template>
                    <div class="text-center text-white mb-4">
                        <small>send an invitation email</small>
                    </div>

                    <ValidationObserver  v-slot="{ handleSubmit, valid }">
                      
                      <form @submit.prevent="handleSubmit(sendInvitation)">
                        <ValidationProvider
                          rules="required|email"
                          v-slot="{ passed, failed, errors }">
                          <base-input
                            :class="{ 
                              'has-success': !errors[0] && userEmail, 
                              'has-danger': errors[0] || !userEmail 
                            }"
                            type="email"
                            label="Email"
                            placeholder="Email"
                            v-model="userEmail"
                          >
                          </base-input>
                          <span class="validation-error">{{ errors[0] }}</span>
                          </ValidationProvider>
                          <br>
                          <base-button :loading="submitInProccess" 
                          :disabled="!valid" native-type="submit" type="primary" class="btn-fill">
                            Invite
                          </base-button>
                          <base-button @click="closeModal()" native-type="button" type="primary" class="btn-fill">
                            Close
                          </base-button>      
                      </form>
                    </ValidationObserver>

                    

                </template>
                <template slot="footer"></template>
            </card>
</template>
<script>

export default {
  components: {

  },
  methods:{
    async sendInvitation(){
      const thisUser = this.$store.getters.user
      
      const invitedUser = {
        user_email: this.userEmail,
        user_first_name: null,
        user_last_name: null,
        user_subscribe_by: 'INVITE',
        user_account_uuid: thisUser.user_account_uuid,
        user_role: 'sms-client',
        user_phone_number: null,
        user_skype_id: null,
        user_is_developer: thisUser.user_is_developer == true,
        user_confirm_is_developer: true
      }
      
      const res = await this.$http.post(process.env.VUE_APP_API_ENDPOINT + '/users/add', invitedUser)
      if(res && res.status == 200){
        this.$toast.success('An invitation email was sent');
        this.closeModalUserAdded()
      }
      
      
    },
    closeModalUserAdded(){
      this.$emit('showUserInviteUserAdded', this.userEmail)
      this.$emit('showUserInvite', false)
      this.userEmail = null
    },
    closeModal(){
      this.userEmail = null
      this.$emit('showUserInvite', false)
    }
  },
  props:[],
  data(){
    return {
      userEmail: null,
      submitInProccess: false
    }
  }
};
</script>
<style>

</style>
