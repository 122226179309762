<template>
    <div id="card-success-wrapper">
        <div class="panel-header">
            <div class="header text-center">
            <div class="d-flex">     
                <div class="card-name">
                    <div class="d-block">
                        <span class="name">{{card.name}}</span>
                    </div>
                </div>
                <div v-if="card.brand" class="ml-auto" style="padding-right: 30px;" >
                    <component 
                        v-if="card.brand && card.brand != 'unknown'" 
                        :is="card.brand"
                        width="90px" 
                        height="90px" 
                    />
                    <mdicon v-else name="credit-card" size="72" class="tim-icons"/>
                    
                </div>
                <div v-else class="ml-auto mt-3" style="padding-right: 30px;">
                    <mdicon name="credit-card" size="64" class="tim-icons"/>
                </div>
            </div>
            
            
            <div class="d-block">
                <div class="d-flex justify-content-center">
                    <span class="credit-card-number">
                        <p class="credit-card-format">XXXX XXXX XXXX {{card.last4}}</p>
                    </span>
                </div>
                <div class="d-flex">       
                    
                    
                    
                    <div class="ml-auto">
                        <div class="card-expiery">
                            <span class="label">Expires</span>
                            <div class="d-block">
                                <span class="name">{{card.exp_month.toString().padStart(2, '0')}}/{{card.exp_year.toString().substr(2)}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            </div>
        </div>
    </div>    
</template>

<script>
import visa from './brandSvgs/Visa.vue';
import mastercard from './brandSvgs/MasterCard.vue';
import unionpay from './brandSvgs/Unionpay.vue';
import JCB from './brandSvgs/Jcb.vue';
import Discover from './brandSvgs/Discover.vue';
import DinersClub from './brandSvgs/Diners.vue';
import AmericanExpress from './brandSvgs/American.vue';

export default {
    components: {
        visa,
        mastercard,
        unionpay,
        JCB,
        Discover,
        DinersClub,
        AmericanExpress
    },
    props: {
        card: {
            type: Object,
            default:  {
                last4: "",
                month: "",
                year: 0,
                brand: "",
                name: "",
            }
        },
    },
    data(){
        return {
            
        }
    }
}
</script>

<style>

#card-success-wrapper .credit-card-format {
    letter-spacing: 2px;
    font-family: monospace;
}
#card-success-wrapper .card-success-wrapper {
    background: none;
    border: 1px solid #f4f5f77e;
    border-radius:15px;
    color:white !important;
}

#card-success-wrapper .panel-header {
    padding: 0px !important;
    color:white !important;
    border-radius:15px;
}

#card-success-wrapper .credit-card-number {
    font-size: 30px;
}

#card-success-wrapper .card-name {
    padding: 25px 0px 50px 50px;
}

#card-success-wrapper .card-expiery {
    padding: 25px 30px 50px 50px;
}


#card-success-wrapper .card-expiery .name {
    font-size:26px;
}

#card-success-wrapper .card-name .name {
    font-size:26px;
}

</style>