<template>
  <card>
    <h5 slot="header" class="title">User Details</h5>
    <ValidationObserver v-slot="{ handleSubmit,valid }">
     
      <form  @submit.prevent="handleSubmit(update)">
        <div class="row">
          <div class="col-md-6">
            <ValidationProvider
              rules="required"
              v-slot="{ passed, failed, errors }"
            >
              <base-input
                
                :class="{ 
                  'has-success': !errors[0] && user.user_first_name, 
                  'has-danger': errors[0] || !user.user_first_name 
                }"

                name="firstName"
                type="text"
                label="First name"
                placeholder="First name"
                v-model="user.user_first_name"
              >
              </base-input>
              <span class="validation-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>  
          <div class="col-md-6">
            <ValidationProvider
            rules="required"
            v-slot="{ passed, failed, errors }"
            >
              <base-input
                
                :class="{ 
                  'has-success': !errors[0] && user.user_last_name, 
                  'has-danger': errors[0] || !user.user_last_name 
                }"
                type="text"
                label="Last Name"
                placeholder="Last name"
                v-model="user.user_last_name"
              >
              </base-input>
              <span class="validation-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          
          
        </div>
        <div class="row">
          <div class="col-md-6">

            <ValidationProvider
            rules="required|numeric|min:6|max:13"
            v-slot="{ passed, failed, errors }"
            >
            <base-input
              
              :class="{ 
                  'has-success': !errors[0] && user.user_phone_number, 
                  'has-danger': errors[0] || !user.user_phone_number 
              }"
              type="phone"
              label="Phone number"
              placeholder="Phone number"
              v-model="user.user_phone_number"
            >
            </base-input>
            <span class="validation-error">{{ errors[0] }}</span>
            </ValidationProvider>
            
          </div>
          <div class="col-md-6">
            <base-input

              :class="{ 
                  'has-success': user.user_skype_id, 
                  'has-danger': !user.user_skype_id 
              }"

              type="text"
              label="Skype ID"
              placeholder="Skype ID"
              v-model="user.user_skype_id"
            >
            </base-input>
          </div>
          </div>
        <div class="row">
          <div class="col-md-10">
            <ValidationProvider
            rules="required|email"
            v-slot="{ passed, failed, errors }"
            >
            <base-input
               :class="{ 
                  'has-success': !errors[0] && user.user_email, 
                  'has-danger': errors[0] || !user.user_email 
              }"
              type="email"
              label="Email address"
              placeholder="Email address"
              v-model="user.user_email"
            >
            </base-input>
            <span class="validation-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>     
        
        <base-button :loading="loading" simple :disabled="!valid" native-type="submit" type="primary" class="btn-fill">
          Update user details
        </base-button>
      </form>
    </ValidationObserver>
  </card>
</template>
<script>
import Loading from 'src/components/LoadingPanel.vue';
import { extend } from "vee-validate";
import { required,numeric,min,max,email } from "vee-validate/dist/rules";
extend("email",email)
extend("min",{
  ...min,
  message: 'Number is too short'
})
extend("max",{
  ...max,
  message: 'Number is too long'
})
//extend("numeric",numeric)

extend("numeric", {
  validate: (value) => {    
    return (/^[0-9]{7,13}$/).test(value);
  }
});



extend("required", {
  ...required,
  message: 'This field is required'
});

export default {
  name: 'profile-form',
  components: {
    Loading
  },
  props: {
  },
  data() {
    return {
      loading: false,
      user: {
        user_first_name: null,
        user_last_name: null,
        user_phone_number: null,
        user_skype_id: null,
        user_role: null,
        user_email: null,
        user_existing_email: null,
        user_uuid: null 
      }
    };
  },
  methods: {
    async fetch(){
      this.loading = !this.loading
      const res = await this.$http.get(`${process.env.VUE_APP_API_ENDPOINT}/users/read/one`)
      this.loading = !this.loading
      if(res && res.status == 200){
        this.user = {...res.data}
        this.user.user_existing_email = this.user.user_email
      }
    },
    async update() {
      
      this.loading = !this.loading
      const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/users/update/one`, this.user)
      this.loading = !this.loading
      if(res && res.status == 200){
        if(this.user.user_email != this.user.user_existing_email){
          this.$toast.success("Email changed, please login");
          this.$store.dispatch('logout')
          this.$router.push('/login')
        }else{
          this.$toast.success("Contact details updated");
        }
        return
      }

    }
    
  }
};
</script>
<style>
.input-group-text{
  padding:0 15px 0 0 !important;
}

.validation-error{
  color:bisque !important;
  font-size:12px
}
</style>
