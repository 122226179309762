<template>
   <div class="stages-wrapper">   

        <div class="row ml-0 mr-0" style="height:50vh">
            <div class="col-lg-6 d-none d-sm-none d-md-none d-lg-block" style="border-right:1px solid">
                <div class="h-100 d-flex align-items-center justify-content-end p-4">
                    <div class="d-block">
                        <h3>You are good to go!</h3>
                        <p class="text-primary">What would you like to do next?</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="h-100 right-column">
                    <div class="h-100 d-flex justify-content-start align-items-center p-4">

                        <div class="d-block w-100">
                            <h3>I would like to</h3><br/>
                            <router-link 
                                class="btn btn-primary btn w-100" 
                                to="/campaigns/new" 
                                tag="button">  
                                Create My First SMS Campaign    
                            </router-link>
                            <br/>
                            <router-link 
                                class="btn btn-primary btn w-100" 
                                to="/home" 
                                tag="button">  
                                Explore My Dashboard
                            </router-link>
                        </div>

                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>

import Selection from './Purpose/Selection.vue'
export default {
    components: {
        
    },
    beforeMount(){
   
    },
    mounted(){
        
    }, 
    methods:{
   
    },
    data(){
        return {

        }
    }
}
</script>

<style>
    
    #whats-next-action{
        height: 100%;
    }

    .right-column {
        max-width:430px;
    } 

    @media (max-width: 767px) {
        .w-sm-100 {
            width:  100% !important;
        }
    }

    @media (max-width: 992px) {
        .stages-wrapper .right-column{
            margin-left: auto;
            margin-right: auto;
        }
    }
    
</style>