<template>
  <div class="row">
    <div class="col-lg-12">
        <div class="row justify-content-center">
            <h3 class="info-text">Billing information</h3>
        </div>
        
        <div :class="`${isLoading ? 'd-flex' : 'd-none'} justify-content-center align-items-center`" style="height: 10vh;" >
              <i class="fa fa-spinner fa-spin fa-3x text-primary"></i>
        </div>
        <div v-show="!isLoading">
            <BillingInformation 
                v-if="isEdit" 
                
                :isEdit="isEdit"
                :billinInfo="billinInfo"
                :addCloseButton="true" 
                @loading="loading"
                @billingUpdated="billingUpdated"
                @close="(value) => isEdit = !value"
            />

            <Details 
                v-else
                
                @edit="edit" 
                :isEdit="true"
                :billing="billing"
            />
        </div>
    </div>
  </div>
</template>

<script>
import BillingInformation from '../../../Profile/components/EditUserSettingsBillingForm.vue'
import Details from './Details.vue';
export default {
    components: {
        BillingInformation,
        Details
    },
    props:{
        isLoading: {
            type: Boolean,
            default: true
        }
    },
    data(){
        return {
            isEdit: true,
            disabled: true,
            billing: {},
            billinInfo: {},
        }
    },
    async mounted() {
        //await this.init()
    },
    methods: {
        close(value){
            this.isEdit = !value
        },
        billingUpdated(billing){
            
            this.billing = billing//{ ...billing, ...this.billing }
            this.isEdit = false
            
        },
        async edit(){
            this.isEdit = !this.isEdit
            this.billinInfo = this.billing
        },
        loading(value){
            
            this.$emit('loading', value)
        },
        async validate() {
            
            if(!Object.values(this.billing).length) return
            

            if(this.billing.billing_stripe_customer_id){
                // customer exists
            }else{
                
                this.$emit('loading', true)
                const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/billing/stripe/customer/create`, this.billing)      
                this.$emit('loading', false)
                if(res && res.status == 200){
                    const { id } = res.data
                    this.billing = {...this.billing, ...{ billing_stripe_customer_id: id } }
                }else{
                    return false
                }
            }
            
            

            this.$emit("on-validated", true, this.billing);
            return true
            
        },
        async init(){
            await this.getBillingData()
        },
        async getBillingData(){
            this.$emit('loading', true)
            
            const res = await this.$http.get(`${process.env.VUE_APP_API_ENDPOINT}/billing/read`)
            this.$emit('loading', false)
            if(res && res.status == 200){
                this.billing = res.data
                if(Object.values(res.data).length) {
                    this.isEdit = false
                    this.billing = res.data
                }else{
                    this.isEdit = true
                }

            }
            
        },
    }
}
</script>

<style>

</style>