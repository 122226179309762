<template>
  <div>
      
    <h3 class="local-h2">Thank you for registering </h3>
    <p style="color:black">
        To continue please agree to our terms and privacy policy <br>
    </p>
    <ValidationObserver v-slot="{ handleSubmit, valid }">
    <form @submit.prevent="handleSubmit(submit)">

    <ValidationProvider
        rules="agreeValidator"
        v-slot="{ passed, failed, errors }"
      >
      <base-checkbox 
      v-model="user_agree"
      class="text-left">
        I agree to the 
        <a target="blank" href="https://message-deck.com/General-terms-and-conditions/">
            terms & conditions 
        </a>
        and
        <a target="blank" href="https://message-deck.com/privacy-policy/">
            privacy policy
        </a>.
        
      </base-checkbox>
      <span v-html="errors[0]"></span>
    </ValidationProvider>

    
    <base-button :loading="submitInProccess" 
    :disabled="!valid" native-type="submit" type="primary" round block size="lg">
          Continue
        </base-button>

    </form>
    </ValidationObserver>

  </div>
</template>

<script>
import { BaseCheckbox } from 'src/components/index';
import { extend } from "vee-validate";
extend("agreeValidator", {
  params: ["user_agree"],
  validate: (value) => {    
   
    return value
  },
  message:`Please agree to terms and conditions`
});

export default {
    components: {
        BaseCheckbox
    },
    props:['registeredData'],
    data() {
        return {
            user_agree: false,
            submitInProccess: false
        };
    },
    methods:{
        async submit(){
            try{
              const res = await this.$http.post(`${process.env.VUE_APP_LOCAL_AUTH_ENDPOINT}/users/update/one`, {
                user_email: this.registeredData.user.user_email,
                user_account_uuid: this.registeredData.user.user_account_uuid
              })
              
              if(res && res.status == 200) this.$emit('socialTermsConfirmed', res.data.user_confirm_terms);
              //this.$emit('socialTermsConfirmed', true);
            }catch(error){
              return this.$emit('socialTermsConfirmed', error.response.status);  
            }
            
            
            

        }
    }
}
</script>

<style>
.local-h2{
    color: #e14eca !important;
}
</style>