<template>
<div>
  <div class="row justify-content-center">
    <div class="col-lg-3 col-sm-12 p-3 mr-5">
      <CheckOutAmounts 
        :amount="parseInt(summeryData.amount)" 
        :vatpct="parseFloat(summeryData.billing_entity_eu_vat)"
      />
    </div>
    <div class="col-lg-4 col-sm-12  p-3 mr-5">
      <PaymentInstructions :email="summeryData.billing_entity_email" :proforma="proforma"/>
    </div>

  </div>
</div>
</template>
<script>
const euCountries = require('../../../json/eu-countries.json')
import CheckOutAmounts from './Summery/bank/CheckOutAmounts.vue';
import PaymentInstructions from './Summery/bank/PaymentInstructions.vue';

export default {
  components: {
    CheckOutAmounts,
    PaymentInstructions
  },
  props: ['summeryData', 'proforma'],
  data() {
    return {
      vatRate : 0,
      vatTotal : 0,
      checkOutAmount: 0,
      euCountries: euCountries
    };
  },
  watch:{
    'summeryData': function(nv){
      
      
      if(nv.billing_entity_name_eu_vat_registery_name){
        this.summeryData.billing_entity_name = nv.billing_entity_name_eu_vat_registery_name
        this.summeryData.billing_entity_address_one = nv.billing_entity_name_eu_vat_registery_address
        this.summeryData.billing_entity_address_two = null
        this.summeryData.billing_entity_postal_code = null
        this.summeryData.billing_entity_city = null
        this.summeryData.billing_entity_country_name = nv.billing_entity_name_eu_vat_registery_country_code
        
        
      }

      let isCountryInUnion = this.euCountries.find(obj => {
        if(obj.code == nv.billing_entity_country_code){
          return obj
        }
      })

      if(isCountryInUnion && Object.keys(isCountryInUnion)){
        isCountryInUnion = true
      }else{
        isCountryInUnion = false
      }

      
      
      
      if(nv.billing_entity_country_continent == 'EU'){
        if(
          ( !nv.billing_entity_valid_eu_vat && isCountryInUnion) || 
            nv.billing_entity_name_eu_vat_registery_country_code == 'CY'
          ){

          this.vatRate = nv.billing_entity_eu_vat
          this.vatTotal = ( this.vatRate / 100 ) * nv.amount
          this.checkOutAmount = Number(nv.amount) + this.vatTotal

        }
      }
    

    }
  },
  methods: {
    
    validate() {
      return true;
    }
  }
};
</script>
<style></style>

