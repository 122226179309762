var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_vm._m(0),_c('h4',{staticClass:"text-muted"},[_vm._v("Click to add a placehoder to your media link")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},_vm._l((_vm.custom),function(item,index){return _c('Custom',{key:index,attrs:{"value":item.value,"label":item.label,"tooltip":item.tooltip},on:{"click":_vm.customized}})}),1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var valid = ref.valid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{ref:"linkInput",class:{  
                                        'has-success': !errors[0] && _vm.trackingLink,
                                        'has-danger': errors[0] || !_vm.trackingLink
                                    },attrs:{"id":"trackinkLinkInput","type":"url","label":"Media (Tracking) Link","placeholder":"http[s]://yoururl.com?click_id={tid}"},model:{value:(_vm.trackingLink),callback:function ($$v) {_vm.trackingLink=$$v},expression:"trackingLink"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('button',{ref:"myButton",staticStyle:{"display":"none"},attrs:{"type":"submit"}},[_vm._v(" Click me ")])])]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('blockquote',[_c('p',{staticClass:"blockquote blockquote-primary"},[_vm._v(" You can use the following replaceable placeholders to customize your media link with your contacts information. "),_c('br'),_vm._v(" These placeholders will be dynamically replaced with actual contact information before a message is sent: "),_c('br'),_c('br')])])}]

export { render, staticRenderFns }