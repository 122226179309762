<template>
  <div class="w-100">
    <tabs type="primary" square :clickHandler="getApiKeys">

      <tab-pane label="http" @click="getApiKeys('http')"> <!-- this label prop is used as type selector in getList-->
        <div slot="label">
          HTTP API
        </div>
        <HttpApiSettings :existingApiKeys="keys.http"/>
      </tab-pane>

      <tab-pane label="smpp" @click="getApiKeys('smpp')">
        <div slot="label">
          SMPP API &nbsp;
          <badge type="success">
            <span slot="badgeContent">BETA</span>
          </badge>
        </div>
        &nbsp;
        <SmppApiSettings :tableData="keys.smpp" @created="addNewSmppKey" @updated="updatedSmppKey"/>
      </tab-pane>

      <tab-pane label="campaign" @click="getApiKeys('campaign')">
        <div slot="label">
          CAMPAIGN
        </div>
        &nbsp;
        <CampToken :tableData="keys.campaign" @created="addCampKey"/>
      </tab-pane>

    </tabs>
  </div>
</template>

<script>
import { TabPane, Tabs, Badge } from 'src/components';
import HttpApiSettings from './components/HttpApiSettings.vue';
import SmppApiSettings from './components/SmppApiSettings.vue';
import CampToken from './components/CampToken.vue';
export default {
 components: {
    TabPane,
    Tabs,
    HttpApiSettings,
    SmppApiSettings,
    CampToken,
    Badge
  },
  data(){
    return {
      campKey: null,
      keys: {
        http: [],
        smpp: [],
        campaign: []
      }
    }
  },
  mounted(){
    this.getApiKeys('http')
    //this.getApiKeys('smpp')
  },
  methods: {
    updatedSmppKey(rowData){
    },
    addCampKey(key){
      console.log(key)
      this.keys.campaign.push(key)
    },
    addNewSmppKey(key){
      this.keys.smpp.push(key)
    },
    async getApiKeys(tabLabel){
      const res = await this.$http.get(`${process.env.VUE_APP_API_ENDPOINT}/apis/keys/${tabLabel}/read`)
      if(res && res.status == 200){
        this.keys[tabLabel] = res.data
      }
    }
  }
}
</script>

<style>

</style>