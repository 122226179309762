<template>
  <div id="campaign-more-details" >
    <div class="row">
        <div class="col-lg-12 mb-3 p-0">
            <div class="btn-group">
                <base-button 
                    v-if="!/[0,3]/.test(dataObject.campaign_last_status)"
                    size="sm"
                    :loading="loading"
                    @click="action('stop', dataObject)" 
                    simple type="danger">
                    <i  class="fa fa-stop"></i>
                    Stop this campaign
                </base-button>
                <base-button 
                    v-else-if="/[0]/.test(dataObject.campaign_last_status)"
                    size="sm"
                    :loading="loading"
                    @click="action('start', dataObject)" 
                    simple type="success">
                    <i  class="fa fa-play"></i>
                    Resume this campaign
                </base-button>
            </div>
        </div>
    </div>
    <tabs type="info" square class="row">
        <tab-pane label="message">
            <div slot="label">
                Message
            </div>
            <div class="p-2">
                <table>
                    <tr>
                        <td width="300px"><h6>Sender: (Originator/Sender ID):</h6></td>
                        <td>
                            <p v-if="dataObject.campaign_local_originator">
                                Local Random Number
                            </p>
                            <p v-else>{{dataObject.campaign_mt_sender}}</p>
                        </td>
                    </tr>
                    <tr>
                        <td><h6>Text:</h6></td>
                        <td><p>{{dataObject.campaign_mt_text}}</p></td>
                    </tr>
                </table>
            </div>
        </tab-pane>
        <tab-pane label="Contacts">
            <div slot="label">Contacts</div>
            <div class="p-2">
                <table>
                    <tr>
                        <td width="200px"><h6>Rotate Contacts:</h6></td>
                        <td><p>{{getRotation()}}</p></td>
                    </tr>
                    <tr>
                        <td width="200px"><h6>Contacts Filters:</h6></td>
                        <td>
                            <span v-if="dataObject.filters && dataObject.filters.length">
                                <el-tag
                                    v-for="(item, index) in dataObject.filters" :key="index"
                                    :closable="false"
                                    :close-transition="false"
                                    class="filter-tags">
                                    {{item.filter_key}} {{item.filter_action}} {{item.filter_value}}
                                </el-tag>
                            </span>
                            <div v-else>
                                <p>No contact filters</p>
                            </div>
                        </td>
                    </tr>
                   
                </table>
            </div>
        </tab-pane>
        <tab-pane label="Media" v-if="dataObject.campaign_tracking_link">
            <div slot="label">Media Link</div>
            <div class="p-2">
                <table>
                    <tr>
                        <td width="200px"><h6>Destination URL:</h6></td>
                        <td><p>{{dataObject.campaign_tracking_link}}</p></td>
                    </tr>
                    <tr>
                        <td width="200px"><h6>Advanced Options</h6></td>
                        <td>
                            <el-tooltip 
                                v-if="dataObject.campaign_shorten_url"
                                content="Your media (tracking) link will be replaced with a short link" 
                                effect="light" :open-delay="300" placement="top">
                                <i style="cursor: help" class="fa fa-magic text-info" aria-hidden="true"></i> 
                            </el-tooltip>
                            &nbsp;&nbsp;
                            <el-tooltip 
                                v-if="dataObject.campaign_filter_crawlers"
                                content="Filter out apps that crawl your link, not all apps are filtered" 
                                effect="light" :open-delay="300" placement="top">
                                <i style="cursor: help" class="fa fa-eye-slash text-info" aria-hidden="true"></i> 
                            </el-tooltip>
                            &nbsp;&nbsp;
                            <el-tooltip 
                                v-if="dataObject.campaign_append_operator"
                                content="Append operator mcc, mnc and name to your media link, 0.002EUR/SMS." effect="light" :open-delay="300" placement="top">
                                <i style="cursor: help" class="fa fa-mobile text-info" aria-hidden="true"></i>
                            </el-tooltip>
                            &nbsp;&nbsp;
                             <el-tooltip 
                                v-if="dataObject.campaign_append_price"
                                content="Append the price of the message to your media link" effect="light" :open-delay="300" placement="top">
                                <span style="cursor: help" class="text-info">&euro;</span>
                            </el-tooltip>
                            &nbsp;&nbsp;
                            <el-tooltip 
                            v-if="dataObject.campaign_append_geo_data"
                            content="Append IP city and country ISO 2 letter code to your media link, 0.002EUR/SMS. (city not always availble)" effect="light" :open-delay="300" placement="top">
                                <i style="cursor: help" class="fa fa-globe text-info" aria-hidden="true"></i> 
                            </el-tooltip>
                        </td>
                            
                    </tr>
                </table>
            </div>
        </tab-pane>
        <tab-pane label="general">
            <div slot="label">General</div>
            <div class="p-2">
                <table>
                    
                    <tr>
                        <td width="100px"><h6>Description:</h6></td>
                        <td><p>{{dataObject.campaign_description}}</p></td>
                    </tr>
                    <tr>
                        <td width="100px"><h6>Created:</h6></td>
                        <td><p>{{dataObject.createdAt}}</p></td>
                    </tr>
                    <tr>
                        <td width="100px"><h6>Updated:</h6></td>
                        <td><p>{{dataObject.updatedAt}}</p></td>
                    </tr>
                </table>
            </div>
        </tab-pane>
       
    </tabs>
  </div>
</template>

<script>
import { TabPane, Tabs, Badge } from 'src/components';
import { Tag } from 'element-ui'
export default {
    components: {
        [Tag.name]: Tag,
        TabPane,
        Tabs,
        Badge
    },
    props: {
        action:  { type: Function },
        //TODO PASS action HANDLER AS PROP
        dataObject: {
            type: Object,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getRotation(){
            if(this.dataObject.campaign_contact_rotate_period)
                return `By ${this.dataObject.campaign_contact_rotate_period}. Wait for ${this.dataObject.campaign_contact_rotate_value} ${this.dataObject.campaign_contact_rotate_period}s.`
            else
                return 'No Rotation'
        }
    }
}
</script>

<style>
    #campaign-more-details .nav-link {
        margin-right: 5px;
        margin-bottom: 0px;
        padding: 5px !important; 
    }
</style>