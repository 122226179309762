<template>
    <div class="card flex-row">
        <div class="m-0 d-inline-block pl-3 pt-2">
            <base-radio 
                name="newCard" 
                :value="selected"
                @input="(value) => $emit('input', value)">
            </base-radio>
        </div>
        <div class="card-body">
            <Loading v-show="!ibanMounted"/>
            <div class="row" v-show="ibanMounted">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <div id="card-number-element"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-8">
                            <div id="card-expiry-element"></div>
                        </div>
                        <div class="col-lg-4">
                            <div id="card-cvc-element"></div>
                        </div>
                    </div>
                    <div class="text-danger" id="card-error-message"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BaseRadio } from 'src/components/index';
import Loading from 'src/components/LoadingPanel.vue';
export default {
    components:{
        Loading,
        BaseRadio
    },
    props: {
        intent:{
            type: Object
        },
        selected: {
            type: String
        },
        addNewMethod: {
            type: Function
        }
    },
    data(){
        return {
            valid: false,
            elements: {},
            loading: false,
            cardNumber: {},
            cardExpiry: {},
            cardCvc: {},
            states: {
                cardNumber: false,
                cardExpiry: false,
                cardCvc: false,
            },
            ibanMounted: false
        }
    },
    methods: {
        async addNewMethodd(){
            
            if(this.valid) 
                return await this.addNewMethod(this.cardNumber)
        },
        getElements(){
            return this.elements.getElement('payment')
        },
        setState(entity, state){
            this.states[entity] = state
            const inValid = Object.values(this.states).filter(state => !state)
            
            if(inValid.length){
                this.valid = false
            }else{
                this.valid = true
            }

            
            
        },
        async init(){
            try{
                this.existingCard = false
                const clientSecret = this.intent.client_secret

                const style = {
                    base: { color: '#ffffffcc' },
                    invalid: {
                        iconColor: '#f33620',
                        color: '#ec250d',
                        //borderColor: '#f33620',
                    },
                };

                const options = {
                    clientSecret,
                    //appearance,
                    //link: {
                    //    enabled: false, // Disable Link one-click checkout
                    //}
                }

                const classes = {
                    base: 'form-group form-control',
                    complete: 'has-success StripeElementComplete',
                    empty: 'has-danger StripeElementError',
                    invalid: 'has-danger StripeElementError',
                }


                //this.elements = await this.$stripe.elements({clientSecret, appearance});
                this.elements = await this.$stripe.elements(options);

                this.cardNumber = this.elements.create('cardNumber',{ showIcon: true, placeholder: 'Card Number', style, classes });
                this.cardExpiry = this.elements.create('cardExpiry',{ placeholder: 'Card Expiry', style, classes });
                this.cardCvc = this.elements.create('cardCvc',{ placeholder: 'CVC', style, classes });

                this.cardNumber.mount('#card-number-element');
                this.cardExpiry.mount('#card-expiry-element');
                this.cardCvc.mount('#card-cvc-element');

                const displayError = document.getElementById('card-error-message');

                this.cardNumber.on('ready', () => {
                    this.ibanMounted = true;
                });

                this.cardNumber.on('change', (event) => {
                    if (event.error) {
                        displayError.textContent = event.error.message;
                        return
                    }else{
                        displayError.textContent = '';
                    }

                    this.setState('cardNumber', event.complete)
                });

                this.cardExpiry.on('change', (event) => {
                    if (event.error) {
                        displayError.textContent = event.error.message;
                        return
                    }else{
                        displayError.textContent = '';
                    }
                    this.setState('cardExpiry', event.complete)
                });

                this.cardCvc.on('change', (event) => {
                    if (event.error) {
                        displayError.textContent = event.error.message;
                        return
                    } else { 
                        displayError.textContent = '';
                    }
                    this.setState('cardCvc', event.complete)
                });
                    
                
            } catch(e) {
                this.$toast.error(e.message)
            }
        },
        async validate(){

        }
    }
}
</script>

<style >
#payment-element {
  width: 100%;
  padding: 12px;
  border: 1px solid #e2e8f0; /* Same border as the PaymentElement */
  border-radius: 4px; /* Consistent rounded corners */
  background-color: #fff; /* White background */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Light shadow for consistency */
}

.StripeElementComplete {
    border:1px #2bffc6 solid !important;
}

.StripeElementError {
    border:1px #f33620 solid !important;
}

.item-boder {
    box-shadow: 0px 1px 1px 0 #f4f5f7 !important;
}


</style>