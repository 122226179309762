<template>
    <div class="card p-3" id="proforma-invoices">
        <ul class="list-group list-group-flush">
            <h5 class="text-white" v-if="proformas.length < maxProformaPending"><b><u>ADD A NEW PAYMENT</u></b></h5>
            <h5 v-else>
                <b class="text-warning"><u>CAN'T ADD NEW INVOICES. </u></b><br>
            </h5>
            <li class="list-group-item w-100 mb-3 p-0"
                style="border-bottom: 1px solid #ffffff1a">
                
                <NewPayment 
                    v-model="proforma" 
                    :disabled="proformas.length >= maxProformaPending"
                    :selected="proforma" 
                />    
            </li>
            <h5 class="text-white" v-if="proformas.length"><b><u>UNPAID INVOICES</u></b></h5>
            <div v-if="proformas.length >= maxProformaPending">
                <b class="text-warning"><u>YOU HAVE {{proformas.length}} EXISTING INVOICES. </u></b><br>
                <small class="text-info">PLEASE PAY OR DELETE AN EXISTING INVOICE</small>
            </div>
            <li v-for="(item, index) in proformas" :key="index"  
                class="list-group-item w-100 mb-3 p-0"
                style="border-bottom: 1px solid #ffffff1a">        
                <div class="card flex-row">
                    <div class="m-0 d-inline-block pl-3 pt-2">
                        <base-radio 
                            :name="item.proforma_invoice_number" 
                            v-model="proforma">
                        </base-radio>
                    </div>
                    
                    <div class="card-body">
                        <h5 class="card-title">
                            <table class="table b-none">
                                <tbody>
                                    <tr>
                                        <td><span class="text-white"><b>{{item.proforma_invoice_number}}</b></span></td>
                                        <td>{{item.proforma_invoice_due_date}}</td>
                                        <td>{{item.proforma_invoice_sub_total}}</td>
                                        <td>{{ item.proforma_invoice_total_tax1 + item.proforma_invoice_total_tax2 }}</td>
                                        <td>{{ item.proforma_invoice_transaction_fee }}</td>
                                        <td><span class="text-white">{{item.proforma_invoice_total}}&nbsp;€</span></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Invoice Number</th>
                                        <th scope="row">Issue Date</th>
                                        <th scope="row">Amount</th>
                                        <th scope="row">Tax</th>
                                        <th scope="row">Fees</th>
                                        <th scope="row">Total</th>
                                    </tr>
                                </tbody>
                                </table>
                        </h5>
                    </div>
                    <div class="card-img-right pt-3">
                        <base-button danger simple style="border:none;" @click="remove(item, index)" :loading="loading">
                            <i class="tim-icons icon-trash-simple text-warning"></i>
                        </base-button>
                    </div>
                </div>
                
            </li>
        </ul>
    </div>
</template>

<script>
import { BaseRadio } from 'src/components/index'; 
import NewPayment from './NewPayment.vue';

export default {
    components:{
        BaseRadio,
        NewPayment
    },
    props:{
        wizardModel: {
            type: Object
        },
    },
    data(){
        return {
            loading: false,
            proforma: 'newProforma',
            proformas:[],
            maxProformaPending: 5
        }
    },
    methods:{
        async toggleLoading(){
            this.loading = !this.loading
            this.$emit('loading', this.loading)
        },
        async remove(item, index){            
            this.toggleLoading()
            const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/billing/payments/proforma/delete`, item)
            this.toggleLoading()
            const { status, data:deleted} = res
            if(status == 200){
                if(deleted) 
                    this.proformas.splice(index, 1)
            }
            

        },
        async init(){
            this.toggleLoading()
            //const res = await this.$http.get(`${process.env.VUE_APP_API_ENDPOINT}/billing/history/pending/proforma/read`)
            const res = await this.$http.get(`${process.env.VUE_APP_API_ENDPOINT}/billing/payments/pending/all`)
            this.toggleLoading()
            if(res && res.status == 200){
                const { data } = res
                this.proformas = data.sort((a,b) => a.payment_issue_date - b.payment_issue_date)
                
                if(this.proformas.length >= this.maxProformaPending){
                    const proforma = this.proformas[0]
                    if(proforma) this.proforma = proforma.payment_proforma_invoice_number
                }
            }
        },
        async validate(){
            let invoice = this.proforma


            if(this.proforma == 'newProforma'){
                //const res = await this.$http.delete(`${process.env.VUE_APP_API_ENDPOINT}/billing/stripe/payment/intent/delete/${intent.id}` )
                //const { status, data } = res
                //if(status == 200){
                //    
                //}
                /*
                const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/billing/invoice/proforma`, this.wizardModel)
                const { status, statusText, data } = res
                if(status && status == 200){
                    invoice = data
                }else{
                    throw new Error(`Something went wrong ${statusText}`)
                }
*/
            }else{
                invoice = this.proformas.filter((proforma) => proforma.proforma_invoice_number == this.proforma).pop()
            }

            return { proforma: invoice }
        }
    }
    
}
</script>

<style>
#proforma-invoices td {
    border: none !important;
}
</style>