<template>
  <div class="row">
    <div class="col-lg-12" id="groups-wrapper">
        <card>
            <template slot="header">
                <div class="row">
                    <div class="col-lg-6">
                        <h3 class="card-title">Contacts by {{pane}}</h3>
                        <h5 class="card-category">A List of you contacts grouped by {{pane}} names</h5>
                    </div>
                    <!--
                    <div class="col-lg-6 text-right">
                        <base-button type="primary" class="btn btn-simple" @click="toggle()">
                            <i class="fa fa-plus"></i> &nbsp; Upload Contacts
                        </base-button> 
                    </div>
                    -->
                </div>
                
            </template>

            
                <div class="row">
                    <div class="col-lg-6">
                        <base-input label="Search" v-model="search" @input="searchInput"></base-input>
                    </div>
                </div>



                <div class="row">
                    <div class="col-lg-12" id="group-wraper">
                        <div ref="scrollContainer" class="scroll-container" >
                            
                            <div v-for="(item, index) in filterData" :key="index"  @click="toggleActive(item, index)" style="display: inline-block;">
                                <card  
                                    :class="{ 'group-card contacts-card active': index == active, 'group-card contacts-card': index != active }"
                                    >
                                    <div slot="header" class="card-header-success" >
                                        
                                        <mdicon v-if="index == active" name="folder-open-outline" size="28"/> 
                                        <mdicon v-else name="folder-outline" size="28"/> 
                                        &nbsp;
                                        <span v-if="item.cCode">
                                            {{ isoCc[item.groups_name]['name'] }}
                                        </span>
                                        <span v-else>{{ item.groups_name }} </span>
                                        
                                        &nbsp;
                                        &nbsp; <i v-if="item.refresh" class="fa fa-spinner fa-spin" style="color: white"></i>
                                    </div>
                                    <p class="" style="height:20px;cursor:pointer;margin: 0px !important;" @click="toggleActive(item, index)">
                                        {{ item.groups_size }} Contacts
                                    </p>
                                    <div slot="footer">
                                        <el-tooltip v-if="!item.groups_status && !item.cCode" content="Processing" effect="light" :open-delay="300" placement="top">
                                            <i class="fa fa-hourglass spin"></i>
                                        </el-tooltip>
                                        <el-tooltip v-else-if="item.groups_name != 'all' && !item.cCode" content="Delete Group" effect="light" :open-delay="300" placement="top">
                                            <base-button :loading="submitInProccess" size="sm" simple type="danger" @click="deleteGroup(item, index)">
                                                <i class="fa fa-trash" aria-hidden="true"></i>
                                            </base-button>
                                        </el-tooltip>
                                        
                                        <country-flag v-if="item.cCode"  style="margin: 0 0 -13px 0;" :country="item.cCode" size='normal'/>
                                        

                                    </div>
                                </card>
                            </div>
                        </div>
                        
                    </div>
                </div>

                
            
            
        </card>

    </div>
    
  </div>
</template>

<script>

import CountryFlag from 'vue-country-flag'

import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import { BaseTable } from '@/components';
//import CsvUpload from 'src/components/CsvUpload.vue';
import UploadContactsModalVue from './UploadContactsModal.vue'
const isoCc = require('../../../../json/iso-3166-2-country.json')
export default {
    components: {
        BaseTable,
        CountryFlag,
        //CsvUpload
        UploadContactsModalVue
    },
    props:['groups', 'deleteGroup', 'getGroups', 'submitInProccess', 'pane'],
    mounted() {
        // Initialize Perfect Scrollbar when the component is mounted
        this.ps = new PerfectScrollbar(this.$refs.scrollContainer, {
            minScrollbarLength: 20,
        });
    },
    watch: {
        'groups': {
            handler: function(nv){
                this.filterData = nv
            },
            deep:true
        }
    },
    methods:{
        searchInput(string){
            this.filterData = [...this.groups].filter(group => {
                return group.groups_name.toLowerCase().includes(string) ||
                isoCc[group.groups_name] && isoCc[group.groups_name]['name'].toLowerCase().includes(string)
            })
        },
        toggle(){
            return this.$refs['contactsModal'].toggle();
        },
        toggleActive(item, index){      
            
            if(this.submitInProccess) return
            if('groups_status' in  item && !item.groups_status) return
            if(item.refresh) item.refresh = false
            
            this.active = index
            const what = {...item, ...{pane: this.pane}}
            
            this.$emit('getList', what)
        }
    },
    data() {
        return {
            filterData: [],
            search: null,
            active: -1,
            uploadFile: false,
            isoCc: isoCc
        }
    }
}
</script>

<style scoped>

.contacts-card {
    position: relative;
    max-width: 100px;
    display: ruby;
    cursor: pointer;
}

.contacts-card
.card-header {
    padding: 0px !important;
}

.input-group-prepend .input-group-text {
    padding: 6px !important;
}
.group-card:hover{
    background: #344675;
}

.group-card:focus {
    background:#344675;
}

.active {
    background:#344675;
}

.group-card{
    
    margin: 10px 5px 0px 5px;
    border-radius: 3px;
    border: 1px solid #344675;
    padding: 10px;
}

.scroll-container {
    position: relative;
    max-height: 300px; 
    overflow: hidden;
    box-sizing: border-box;
}


@media screen and (max-width: 600px) {
    .scroll-container {
        max-height: 300px; 
    }


}

.spin {
    display: inline-block;
    animation: spin 2s linear infinite; /* Adjust the duration (2s) and speed (linear) as needed */
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

</style>