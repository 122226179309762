<template>
  <div class="w-100">
    <div class="row">
        <div class="col-lg-12" >
          <filter-area :tokenSelectData="tokensList" :submitInProccess="submitInProccess" @fiterData="filterOptions"/>
      </div>      
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12" >
            <summery-table :submitInProccess="submitInProccess"  :FilterChartData="topTableChartData" @fiterData="filterOptions"/>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12" >
            <summery-chart :submitInProccess="submitInProccess"  :FilterChartData="{sent: topLineChartData, received: topLineMoChartData }" @fiterData="filterOptions"/>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12" >
            <summery-break-down :submitInProccess="submitInProccess" :countryCodes="countryCodes" :moByCcode="moByCcode" :FilterChartData="countryOpData" @fiterData="filterOptions"/>
        </div>
    </div>
  </div>
</template>

<script>
import FilterArea from './components/FilterArea.vue';
import SummeryChart from './components/SummeryChart.vue';
import SummeryTable from './components/SummeryTable.vue';
import SummeryBreakDown from './components/SummeryBreakDown.vue'
import countryByAbr from '../../../json/iso-3166-2-country.json';

export default {
    components: {
        FilterArea,
        SummeryChart,
        SummeryTable,
        SummeryBreakDown
    },
    data(){
        return {
            countryCodes: countryByAbr,
            submitInProccess: false,
            tokensList:[],
            countryOpData:[],
            topTableChartData: {
                totalSent: 0,
                totalCost: 0,
                totalParts: 0,
                totalSentMo: 0,
                totalCostMo: 0,
                totalPartsMo: 0
            },
            moByCcode: {},
            topLineMoChartData:{
                labels: [],
                data: {
                    total: []
                }
            },
            topLineChartData: {
                labels: [],
                data: {
                    total: []
                }
            }
        }
    },
    beforeMount(){ 
        this.getApiKeys()
    },
    methods: {
        async getApiKeys(){
            try{
                const api_endpoint = process.env.VUE_APP_API_ENDPOINT
                this.submitInProccess = !this.submitInProccess
                const [resultHttpKeys, resultSmppKeys] = await Promise.all([
                    this.$http.get(api_endpoint + '/apis/keys/http/read'),
                    this.$http.get(api_endpoint + '/apis/keys/smpp/read')
                ])
                this.submitInProccess = !this.submitInProccess
                const { status:httpStatus, data:httpData } = resultHttpKeys
                const { status:smppStatus, data:smppData } = resultSmppKeys

                if(httpStatus == 200) 
                this.tokensList = httpData

                if(smppStatus == 200) {
                    const smppKeys = smppData.map(key => {
                        const {
                            key_type: type,key_tp: tp,key_uuid: uuid,
                            key_accounting_token:token, 
                            key_desc: desc, key_status: status, key_mode: mode
                        } = key
                        
                        return {
                            "http_api_jwt_type": type,
                            "http_api_jwt_tp": tp,
                            "http_api_jwt_uuid": uuid,
                            "http_api_jwt_short_token": token,
                            "http_api_jwt_description": desc,
                            "http_api_jwt_status": status,
                            "http_api_jwt_mode": mode
                        }
                    })
                    
                    this.tokensList = [
                        ...this.tokensList,
                        ...smppKeys
                    ]
                        
                }
                
            }catch(e){
                this.$toast.error(e.message)
                this.submitInProccess = !this.submitInProccess
            }
        },
        getApiKeyss(){
            const api_endpoint = process.env.VUE_APP_API_ENDPOINT
            Promise.all([
                //this.$http.get(api_endpoint + '/api/get-http-api-keys')
                this.$http.get(api_endpoint + '/apis/keys/http/read'),
            ])
            .then(([result1]) => {
                this.tokensList = result1.data
                this.submitInProccess = false
            })
            .catch((err)=>{
                this.$toast.error("Error server side data")
                console.log('There is an error', err);
            });
        },
        parseAnalyticsData(data){
            
            
            this.topTableChartData = {
                totalSent: 0,
                totalCost: 0,
                totalParts: 0,
                totalSentMo: 0,
                totalCostMo: 0,
                totalPartsMo: 0
            };
            this.topLineChartData.labels = [];
            this.topLineChartData.data = {
                total: []
            };

            this.topLineMoChartData.labels = []
            this.topLineMoChartData.data = {
                total: []
            };

            this.moByCcode = data.moByCcode

            const moChartMap = {}

            for(var date in data.moByData){
                // todo: add mop button to charts and show this infromation
                //this.topLineChartData.labels.push(date)
                //this.topLineChartData.data.total.push(data.byDate[date].cost.toFixed(4)) 
                this.topTableChartData.totalSentMo += data.moByData[date].sent
                this.topTableChartData.totalPartsMo += data.moByData[date].parts
                this.topTableChartData.totalCostMo += data.moByData[date].cost
                //moChartMap[date]['']
                this.topLineMoChartData.labels.push(date)
                //moChartMap[date] = moChartMap[date] == 0 ? moChartMap[date] + data.moByData[date].cost : 0
                this.topLineMoChartData.data.total.push(data.moByData[date].cost)
                
            }

            //console.log(moChartMap)

            //this.topLineMoChartData.data.total = Object.values(moChartMap)


            for(var date in data.byDate){
                this.topLineChartData.labels.push(date)
                this.topLineChartData.data.total.push(data.byDate[date].cost.toFixed(4)) 
                this.topTableChartData.totalSent += data.byDate[date].sent
                this.topTableChartData.totalCost += data.byDate[date].cost
            }

            this.topTableChartData.totalSent = this.topTableChartData.totalSent.toLocaleString('en', {maximumFractionDigits: 0})
            this.topTableChartData.totalCost = this.topTableChartData.totalCost.toLocaleString('en', {maximumFractionDigits: 4})

            this.countryOpData=[]
            if(data.byCountryByOp){
                Object.keys(data.byCountryByOp).forEach(ccName=>{
                    Object.keys(data.byCountryByOp[ccName]).forEach(opName => {
                        this.topTableChartData.totalParts +=  data.byCountryByOp[ccName][opName].parts
                        this.countryOpData.push({
                            country: ccName,
                            operator: opName,
                            sent: data.byCountryByOp[ccName][opName].sent,
                            parts: data.byCountryByOp[ccName][opName].parts,
                            cost: data.byCountryByOp[ccName][opName].cost
                        })
                    })
                })
                this.topTableChartData.totalParts = this.topTableChartData.totalParts.toLocaleString('en', {maximumFractionDigits: 0})
                this.countryOpData = this.countryOpData.sort(function(a, b){
                    return b.sent - a.sent
                })
            }

        },
        async filterOptions(dataa){
            
            this.submitInProccess = true
            const api_endpoint = process.env.VUE_APP_API_ENDPOINT           
            const res = await this.$http.post(api_endpoint + '/analytics/sms/client/usage', dataa)
            this.submitInProccess = false
            const { status, data } = res
            if(status == 200){
                this.parseAnalyticsData(data)
            }
            
            
        }
    }
}
</script>

<style>

</style>