<template>
  <card>
    <template slot="header">
        <h3 class="card-title">Pending Payments</h3>
        <p class="card-category">Incomplete Payments</p>
        <!--
        <router-link to="/billing/funds"
            tag="a" 
            class="btn btn-primary btn-simple">
            Add Funds
        </router-link>
        -->
    </template>

    <Table 
        :loading="loading"
        :cols="cols" 
        :rows="rows"
        downLoadButtonLabel="invoice download"
        comments="Payment is pending"
        :options="[
            {
                method: download,
                icon: 'cloud-download-outline',
                iconSize: 18
            }
        ]"
    />
    
  </card>
</template>

<script>
import Table from '../Table.vue'
export default {
    components:{
        Table
    },
    props:{
        rows: {
            type: Array
        }
    },
    data(){
        return {
            loading: false,
            cols: [
                {
                    prop: 'proforma_invoice_number',
                    label: 'Pending Invoice Number'
                },
                {
                    prop: 'proforma_invoice_payment_type',
                    label: 'Payment Type'
                },
                {
                    prop: 'proforma_invoice_sub_total',
                    label: 'Amount'
                },
                {
                    prop: 'proforma_invoice_total_tax1',
                    label: 'Taxes (VAT)'
                },
                {
                    prop: 'proforma_invoice_transaction_fee',
                    label: 'Transaction fee'
                },
                {
                    prop: 'proforma_invoice_total',
                    label: 'Invoice total'
                },
                {
                    prop: 'proforma_invoice_date',
                    label: 'Issue Date'
                }
            ]
        }
    },
    methods: {
        async download(proforma){
            this.loading = !this.loading
            const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/billing/invoice/proforma/download`, proforma)
            this.loading = !this.loading
            const {status, data} = res
            if(status == 200){
                var docUrl = document.createElement('a');
                docUrl.href=`data:application/pdf;base64,${data}`
                docUrl.setAttribute('download', proforma.proforma_invoice_number + '.pdf');   
                document.body.appendChild(docUrl);
                docUrl.click();
            }
        }
    }
}
</script>

<style>

</style>