<template>
  <el-tooltip :content="tooltip" effect="light" :open-delay="300" placement="top">
  <base-button 
    size="sm"
    @click="click"
    active 
    tag="button" 
    type="primary"
    class="buttonMessage">
      { {{label}} }  
  </base-button>
  </el-tooltip>
</template>

<script>
export default {
   props: {
    label: {
      type: String,
      default: 'link',
    },
    value: {
      type: String,
      default: 'link',
    },
    tooltip: {
      type: String,
      default: undefined
    }
   },
   methods: {
    click(){
        this.$emit('click', this.value)
    }
   }
}
</script>

<style>

  .buttonMessage{
    padding: 2px !important;
    font-size: 10px;
    margin:0px !important;
  }
</style>