<template>
  
  
    <card class="card card-login card-white">
    
    <template slot="header">
      <img class="card-img" style="max-height:250px" src="/img/card-primary.png" alt="Card image"/>
      <h4 class="card-title">Email</h4>
    </template>

      <ValidationObserver v-slot="{ handleSubmit, valid }">
      <form @submit.prevent="handleSubmit(submit)">
      
          <ValidationProvider
            name="email"
            rules="required|email"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
          :class="{ 
                    'has-success': !errors[0] && email, 
                    'has-danger': errors[0] 
                }"
            required
            v-model="email"
            type="email"
            label="Email address">
          </base-input>
          <span  v-html="errors[0]"></span>
         </ValidationProvider>

         <ValidationProvider
           name="password"
           :rules="{ 
                  required: true, 
                  passwordValidator: { } 
                }" 
           v-slot="{ passed, failed, errors }"
         >
         <base-input
           required
           v-model="password"
           type="password"
           label="Password"
          :error="errors[0]"
           :class="{ 
                    'has-success': !errors[0] && password, 
                    'has-danger': errors[0] 
                }">
         </base-input>
         
        </ValidationProvider>
        <div class="category form-category">* Required fields</div>
    

        <base-button  :disabled="!valid || !recaptcha" native-type="submit" type="primary" round block size="lg">
          Login
        </base-button>
        <!--
        <div class="row">
          <div class="col-lg-12">
            <base-button @click="resendConfirmationEmail()" :loading="submitInProccess" :disabled="!valid || !recaptcha" type="primary" round block simple size="lg">
              Resend confirmation email
            </base-button>
          </div>
        </div>
        -->
        <div class="d-flex justify-content-center mt-3">
          
          <VueRecaptcha
              ref="recaptcha"
              :sitekey="siteKey"
              :load-recaptcha-script="true"
              @verify="onVerify"
              @error="onVerifyError"
              @expired="onExpired">
          </VueRecaptcha>
          
        </div>

      </form>
      </ValidationObserver>
      
      <template slot="footer">
        
        <div class="d-flex justify-content-between">
          <base-button @click="back" simple small round type="primary" style="width:-webkit-fill-available;font-weight:300" class="border-0 pl-0">  
            <i class="tim-icons icon-minimal-left"></i> 
            &nbsp;&nbsp;&nbsp; Login options
          </base-button>
          <base-button @click="recover" simple small round type="primary" style="width:-webkit-fill-available;font-weight:300" class="border-0">  
            Forgot my password &nbsp;&nbsp;&nbsp;
            <i class="tim-icons icon-minimal-right"></i>
          </base-button>
        </div>
        
        <!--
        <div class="row">
          <div class="col-lg-5 d-flex justify-content-start">
            <a @click=back() class="nav-link text-primary" style="cursor:pointer">
              <i class="tim-icons icon-minimal-left"></i> Login options
            </a>
          </div>
          <div class="col-lg-7 d-flex justify-content-end">
            <a @click="recover()" class="nav-link text-primary" style="cursor:pointer">
              Fotgot my password
              <i class="tim-icons icon-minimal-right"></i>
            </a>
          </div>
        </div>
        -->
      </template>

    </card>
  
 
</template>
<script>
import VueRecaptcha from 'vue-recaptcha';
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("email", email);
extend("required", required);
extend("min", min);

extend("passwordValidator", {
  params: ["password"],
  validate: (value) => {    
    //return value.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{8,}$/);
    //(?=.*[A-Z]) look at entire string, evaluate true if Find a capital letter (assertion behaves like bollean and is ignored for captures)
    //(?=.*[a-z]) look at entire string, evaluate true if  Find a lower case letter
    //(?=.*\d) look at entire string, evaluate true if  Find a lower case letter
    // all three act like and operator. 
    // [A-Za-z0-9]{8,} count entire string is 8 chars long

    return value.match(/(?=.*[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/])(?=.*[A-Z])(?=.*\d)[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/A-Za-z0-9]{8,}/);
  },
  message:` Password must be at least 8 charachters long it must have: one number, one capital letter, one special charachter.`
});

export default {
  components:{
    VueRecaptcha
  },
  data() {
    return {
      siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaTs: null,
      recaptchaDomain: null,
      captchaJwtToken: null,
      //recaptcha: true,
      //email: "abdul.aziz.couch@gmail.com",
      //password: "Aa!1aaaaaa",
      recaptcha: false,
      email: "",
      password: "",
      submitInProccess: false
    };
  },
  
  methods: {
    onExpired(){
      this.recaptcha = false
    },
    async onVerify(token){

      const res = await this.$http.post(`${process.env.VUE_APP_BASE_DOMAIN_URI}/auth/google/recaptcha/verify`, {
          token: token
      })  
      
      if(res && res.status == 200){
        const { recaptchaJwtToken } = res.data
        this.recaptchaJwtToken = recaptchaJwtToken
        this.recaptcha = recaptchaJwtToken ? true : false
        //this.recaptchaTs = res.data.challenge_ts
        //this.recaptchaDomain = res.data.hostname

      }
    },
    onVerifyError(error){
        console.log(error)
    },
    resendConfirmationEmail(){
      const local_auth_base_url = process.env.VUE_APP_LOCAL_AUTH_ENDPOINT
      this.submitInProccess = true
      this.$http.post( local_auth_base_url + '/resend-confiramion-email', {
        user_email: this.email,
        user_password: this.password
      })
      .then( (response) => {
        this.submitInProccess = false
        
        this.message = response.data.message  
        this.$toast.success(this.message);
        //this.$router.push('/login')
        
      })
      .catch( (error) => {
        this.submitInProccess = false
        //console.log(error);
        //this.$toast.error(error);
      });
      
    },
    async submit() {
      try{
        const login = await this.$store.dispatch('login', {
          user_email: this.email,
          user_password: this.password,
          jwtToken: this.recaptchaJwtToken
          //user_recaptcha: this.recaptcha,
          //user_recaptcha_host: this.recaptchaDomain
        })

        if(login){
          this.$toast.success("Logged in succefully");
          const nextPage = await this.$store.dispatch('nextPage')
          this.$router.push(nextPage)
        }

      }catch(e){
        if(e && Object.keys(e).includes('user')){
          const {user, reason, message} = e
          if(reason == "confirm2FA" && user){
            this.$router.push('/Confrim2FA')
            this.$toast.info(`We have sent you a two factor verification code to ${user.user_email}`);
          }
        }
      }
    },
    recover: function () {
      this.$emit('recoverPassword', true)
    },
    back: function () {
        this.$emit('emailLogin', false);
    }
  }
};
</script>
<style>

</style>
