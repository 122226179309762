<template>
  <ValidationObserver v-slot="{ handleSubmit, valid }">
  <form @submit.prevent="handleSubmit(submit)">
    <card class="card card-login  card-white">
    <template slot="header">
      <img class="card-img" style="max-height:250px" src="/img/card-primary.png" alt="Card image"/>
      <h4 class="card-title">Recover</h4>
    </template>
      <div>
          <ValidationProvider
            name="email"
            rules="required|email"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            required
            v-model="user_email"
            type="email"
            label="Email address"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>

        <div class="category form-category">* Required fields</div>
      </div>

      <template slot="footer">
        
        <base-button  :loading="submitInProccess" :disabled="!valid" native-type="submit" type="primary" round block size="lg">
          Send a verification code
        </base-button>
        
        <div class="d-flex justify-content-center mt-3">    
          <VueRecaptcha
              ref="recaptcha"
              :sitekey="siteKey"
              :load-recaptcha-script="true"
              @verify="onVerify"
              @error="onVerifyError"
              @expired="onExpired">
          </VueRecaptcha>
        </div>

        <div class="d-flex justify-content-left">
          <base-button @click="back" simple small round type="primary" class="border-0 pl-0" style="font-weight: 300;">  
            <i class="tim-icons icon-minimal-left"></i> 
            &nbsp;&nbsp;&nbsp; Login with email
          </base-button>
        </div>
        
        
      </template>

     


    </card>
  </form>
 </ValidationObserver>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha';
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components:{
    VueRecaptcha
  },
  data() {
    return {
      siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaTs: null,
      recaptchaDomain: null,
      recaptcha: true,
      user_email: "",
      message: null,
      submitInProccess : false,
      recaptchaJwtToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWNjZXNzIjp0cnVlLCJjaGFsbGVuZ2VfdHMiOiIyMDI0LTA5LTA2VDEwOjMwOjE5WiIsImhvc3RuYW1lIjoibG9jYWxob3N0IiwiaWF0IjoxNzI1NjE4NjMwLCJleHAiOjE3MjU2MTg5MzB9.9yUytnJyBR-1P3X4zNOdfqnQMpdOxGzEXWeMH31ktag"
    };
  },
  methods: {
    onExpired(){
      this.recaptcha = false
    },
    async onVerify(token){

      const res = await this.$http.post(`${process.env.VUE_APP_BASE_DOMAIN_URI}/auth/google/recaptcha/verify`, {
          token: token
      })  
      
      if(res && res.status == 200){
        const { recaptchaJwtToken } = res.data
        this.recaptchaJwtToken = recaptchaJwtToken
      }
    },
    onVerifyError(error){
        console.log(error)
    },
    recover(){
      this.$emit('recoverPassword', true);
    },
    back: function () {
        this.$emit('emailLogin', false);
    },
    async submit(){
      try{
        this.submitInProccess = !this.submitInProccess
        const recover = await this.$store.dispatch('recover', {
          user_email: this.user_email,
          jwtToken: this.recaptchaJwtToken
        })

        this.submitInProccess = !this.submitInProccess
        if(recover){
          this.$toast.info(`We have sent you a two factor verification code to ${this.user_email}`);
          this.$router.push({ path: '/Confrim2FA', query: { recover: recover } })
        }
      }catch(e){
        this.submitInProccess = !this.submitInProccess
        if(e) this.$toast.error(e.message)
      }
      
    }

    
  }
};
</script>
<style></style>
