<template>
    <div>
        <div class="row">
            <div class="col-lg-12 pb-3">
                <router-link to="/billing/funds"
                    tag="a" 
                    class="btn btn-primary btn">
                    + Add Funds
                </router-link>        
            </div>
        </div>
        <tabs type="primary" square :clickHandler="getData">
            <tab-pane :label="`Pending (${counts.PROFORMA || 0})`" >
                <Proforma :rows="list"/>
            </tab-pane>
            <tab-pane :label="`Received (${counts.DRAFT || 0})`" >
                <Draft :rows="list"/>
            </tab-pane>
            <tab-pane :label="`Confirmed (${counts.SAVED || 0})`" >
                <Approved :rows="list"/>
            </tab-pane>
            <!--
            <tab-pane :label="`Canceled (${counts.DELETED || 0})`" >
                <Deleted :rows="list"/>
            </tab-pane>
            -->
        </tabs>
    </div>
</template>

<script>
import { TabPane, Tabs } from 'src/components';
import { Approved, Draft, Proforma, Deleted } from './tables'
export default {
    components:{
        TabPane,
        Tabs,
        Approved, 
        Draft, 
        Proforma,
        Deleted
    },
    data(){
        return {
            list: [],
            counts: {}
        }
    },
    async mounted(){
        await this.getData('pending')
    },
    methods: {
        async getData(type){
            
            
            const res = await this.$http.get(`${process.env.VUE_APP_API_ENDPOINT}/billing/invoice/read/one`)
            const {status, data} = res
            if(status == 200){
                type = type.replace(/[^A-Za-z]/g, '').toLowerCase()    

                this.counts = data.reduce((hash, row)=>{
                    const {proforma_invoice_wave_invoice_status:status} = row

                    if(hash[status]) hash[status]+=1
                    else hash[status]  = 1

                    return hash
                },{})

                let filterBy = 'proforma'
                switch (type) {
                    case 'received':
                        filterBy = 'draft'
                        break;
                    case 'confirmed':
                        filterBy = 'saved'
                        break;
                    case 'canceled':
                        filterBy = 'deleted'
                        break;
                    default:
                }


                this.list = data.filter(row => row.proforma_invoice_wave_invoice_status && row.proforma_invoice_wave_invoice_status.toLowerCase() == filterBy)
                
            }
        }
    }
}
</script>

<style>

</style>