<template>
    <div class="row">
        <div class="col-lg-12">
            <card style="min-height:600px">
                <template slot="header">
                    <h4 class="card-title">Sender, text and token</h4>
                </template>
                <div class="d-flex justify-content-center">
                    <el-select 
                        style="width:100%;margin-bottom:15px"
                        :disabled="!tokenSelectData.length"
                        filterable
                        @change="selectToken"
                        class="select-primary"
                        v-model="accountigToken" 
                        value-key="mnc"
                        label="API short access token"
                        placeholder="API short access token">
                        <el-option
                            v-for="(item, index) in tokenSelectData"
                            :key="index"
                            :label="`${item.http_api_jwt_short_token}-(${item.http_api_jwt_type}) ${item.http_api_jwt_description}`"
                            :value="`${item.http_api_jwt_short_token}-(${item.http_api_jwt_type}) ${item.http_api_jwt_description}`">
                        </el-option>
                    </el-select>
                    </div>

                <p>
                    Sender IDs to test:
                </p>
            
                <tags-input @change="addedSenderId" placeholder="Enter sender " v-model="dynamicTags"></tags-input>
                </br>
                </br>
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form class="form-horizontal" @submit.prevent="handleSubmit(submit)">
                        <p>
                            Message text:
                        </p>
                        <ValidationProvider
                            rules="required"
                            v-slot="{ passed, failed, errors }">

                            <div class="form-group" :class="{ 
                                'has-success': !errors[0] && MessageText, 
                                'has-danger': errors[0] || !MessageText
                                }">
                            
                            <textarea
                                placeholder="Test message text"
                                class="form-control text-area"
                                
                                
                                v-model="MessageText"
                                @keyup="testTextAdded()">

                            </textarea>
                            </div>

                            <span class="validation-error">{{ errors[0] }}</span>
                            </ValidationProvider>

                    </form>
                </ValidationObserver>

                <h6>
                    {{ ( textIsGsm ? '7bit' : 'Unicode') }}: {{ MessageText.length }} characters

                </h6>                
                <h6>
                    {{ getMessageCount() }} messages count
                </h6>
                
            </card>
        </div>
    </div>
</template>

<script>
import {
    TagsInput
} from 'src/components/index';
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import {Select, Option} from 'element-ui'

extend("required", required);
export default {
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        TagsInput
    },
    props:['tokenSelectData'],
    data() {
        return {
            dynamicTags: [],
            MessageText: "",
            textIsGsm: true,
            accountigToken: null
        }
    },
    methods:{
        selectToken(){
            const [token] = this.accountigToken.split(/\-/)
            const tokenn = this.tokenSelectData.filter(tokenn => tokenn.http_api_jwt_short_token == token).pop()
            this.$emit('selectToken', tokenn);
        },
        getMessageCount(){
            let count = ( this.textIsGsm ? Math.ceil(Number(this.MessageText.length/160)) : Math.ceil(Number(this.MessageText.length/70) ))
            this.$emit('messageCount', count);
            this.$emit('textIsGsm', this.textIsGsm);
            return count
        },
        addedSenderId(value){
            this.$emit('senderIds', value);
        },
        parseText(text){
            const regexp = new RegExp("^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$");
            this.textIsGsm = regexp.test(this.MessageText);


        },
        //testTextAdded(value){
        testTextAdded(){
            this.parseText()
            this.$emit('testTextAdded', this.MessageText);
        },
        submit() {
            alert("Form has been submitted!");
        }
    }
}
</script>

<style scoped>
.text-area{
    border: 1px solid #2b3553 !important;
    min-height: 200px;
    border-radius: 0.4285rem;
    padding:10px;
}
.has-success:after{
    font-family: 'nucleo';
    content: "\ea1b";
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 13px;
    color: #00f2c3;
    font-size: 11px;
}

</style>