<template>
  <card >
    <h4 class="card-title">Campaign Account</h4>
    <hr>
    <base-button @click="toggleModal()" type="info">
        <mdicon name="key-plus" size="22" /> &nbsp;&nbsp; Add campaign token
    </base-button>

    <el-table :data="tableData">
      <el-table-column 
            :min-width="50"
            prop="http_api_jwt_short_token"
            label="Token">
      </el-table-column>
      <el-table-column 
          :min-width="100"
          prop="http_api_jwt_tp"
          label="Throughput">
          <template slot-scope="scope">
            {{scope.row.http_api_jwt_tp}}/Sec
          </template>
      </el-table-column>
      <el-table-column 
            :min-width="100"
            prop="http_api_jwt_mode"
            label="Mode">
      </el-table-column>
      <el-table-column 
            :min-width="100"
            prop="http_api_jwt_type"
            label="Type">
      </el-table-column>
      
      <el-table-column 
            :min-width="100"
            label="Status">
            <template slot-scope="scope">
              <i v-if="scope.row.http_api_jwt_status" class="fa fa-check-circle text-success"></i>
              <i v-else class="fa fa-times-circle text-danger"></i>
            </template>
      </el-table-column>

      <el-table-column 
            :min-width="100"
            prop="http_api_jwt_description"
            label="Description">
      </el-table-column>
      
      <el-table-column
        label="Created">
          <template slot-scope="scope">
            {{ (new Date(scope.row.http_api_jwt_updated_at)).toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', }) }}
          </template>
      </el-table-column>
    </el-table>
    <AddCampTokenModal ref="addTokenModal" @create="create"/>
  </card>
</template>

<script>
import {Table, TableColumn} from 'element-ui'
import AddCampTokenModal from './CampComponents/Modal.vue'
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    AddCampTokenModal
  },
  //props: ['campKey'],
  props: ['tableData'],
  data(){
    return {}
  },
  methods: {
    toggleModal() { this.$refs['addTokenModal'].showModal = true },
    async create(key) {

      this.submitInProccess = !this.submitInProccess;
      const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/routing/token/campaignn`, key)
      if(res && res.status == 200){
        const { data } = res
        const {
          token_account_uuid: http_api_jwt_uuid,
          token_short:  http_api_jwt_short_token,
          token_status: http_api_jwt_status,
          token_tp: http_api_jwt_tp,
          token_type: http_api_jwt_type,
          updatedAt: http_api_jwt_updated_at,
          token_description:http_api_jwt_description
        } = data

        this.$emit('created', {
          http_api_jwt_uuid,
          http_api_jwt_short_token,
          http_api_jwt_status,
          http_api_jwt_tp,
          http_api_jwt_type,
          http_api_jwt_updated_at,
          http_api_jwt_description
        })
      }
    },
  }

}
</script>

<style>

</style>
