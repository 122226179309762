<template>

  <card>
    <div class="my-padding">
      <h4 class="card-title">HTTP API Credentials</h4>
      <hr>
      <base-button @click="modals.modalAddKey = true" type="info">
        <mdicon name="key-plus" size="22" /> &nbsp;&nbsp; Add access key  
      </base-button>

      <el-table :data="tableData">


        <el-table-column type="index">
        </el-table-column>

        <el-table-column 
          :min-width="200"
          label="Key">
          <template slot-scope="scope">
              <base-input
              class="border-0"

              toggleIcon
              addonRightIcon="eye"
              addonRightIconToggle="eye-off"
              type="password"
              label=""
              placeholder=""
              v-model="scope.row.http_api_jwt_token"
              value:scope.row.http_api_jwt_token
            >

            </base-input>
          </template>
        </el-table-column>
        
        <el-table-column 
          :min-width="200"
          label="Accounting token">
          <template slot-scope="scope">
              <base-input
              disabled
              textLight
              class="border-0"
              type="text"
              label=""
              placeholder=""
              v-model="scope.row.http_api_jwt_short_token"
              value:scope.row.http_api_jwt_short_token
            >

            </base-input>
          </template>
        </el-table-column>
        <el-table-column 
          prop="http_api_jwt_tp"
          label="Throughput">
          <template slot-scope="scope">
            {{scope.row.http_api_jwt_tp}}/Sec
          </template>
        </el-table-column>
        <el-table-column 
          prop="http_api_jwt_status"
          label="Status">
          <template slot-scope="scope">
            <i v-if="scope.row.http_api_jwt_status" class="fa fa-check-circle text-success"></i>
            <i v-else class="fa fa-times-circle text-danger"></i>
          </template>
        </el-table-column>
        <el-table-column 
          :min-width="50"
          prop="http_api_jwt_mode"
          label="Mode">
        </el-table-column>
        <el-table-column 
          :min-width="50"
          prop="http_api_jwt_type"
          label="Type">
        </el-table-column>
        <el-table-column sortable
          prop="createdAt"
          label="Created">
        </el-table-column>
      <!--
        <el-table-column 
          prop="api_keys_key_description"
          label="Description">
        </el-table-column>
          -->
        <el-table-column :min-width="200" label="Description">
          
          <template slot-scope="scope">
            
              <base-input
              :disabled="( scope.row.http_api_jwt_type || 'N/A' ).toUpperCase() == 'CAMPAIGN'"
              :editiable="true"
              class="border-0"
              @editing-input-start="editRow(scope.$index)"
              @editing-input-end="saveRow(scope.$index)"
              toggleIcon
              addonRightIcon="floppy"
              addonRightIconToggle="square-edit-outline"
              :textLight="true"
              
              label=""
              placeholder=""
              v-model="scope.row.http_api_jwt_description"
              value:scope.row.http_api_jwt_description>
            </base-input>
            
          </template>
        </el-table-column>
        
      <!--
        <el-table-column
          label="Active">
          <template slot-scope="props">
            <base-switch 
              v-model="props.row.api_keys_key_status" 
              @input="updateKey(props.row)">
              <i class="fa fa-check" slot="on"></i>
              <i class="fa fa-exclamation" style="color:red;" slot="off"></i>
            </base-switch>
          </template>
        </el-table-column>
        -->
      </el-table>

      <modal :show.sync="modals.modalAddKey"
              body-classes="p-0"
              modal-content-classes="no-background"
              modal-classes="modal-lg">
          <card 
              id="http-form"
              type="secondary"
              header-classes="bg-black pb-5"
              body-classes="px-lg-5 py-lg-5 "
              class="border-0 mb-0">
              
                  <h4 class="card-title">Add access key</h4>
                  <p class="card-text text-light">
                    An access key can take a few seconds to come online.
                    You will recive an email notification when your key is active.
                    <br><br>
                    The difference between a live key and a test key is that a live key actually sends a message, while a test key doesn't. 
                    <br>
                    However, when you send a message using a test key, you will get a fake delivery report back.
                  </p>
                <br>
                <br>
                  <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(createApiKey)">
                    <div class="row">
                      <div class="col-lg-12">
                      
                      <ValidationProvider
                      rules="required|name:5"
                      v-slot="{ passed, failed, errors }">
                          <base-input 
                            v-model="newKey.http_api_jwt_description" 
                            alternative
                            class="mb-3"
                            placeholder="Api key description">
                          </base-input>
                          <span class="validation-error">{{ errors[0] }}</span>
                      </ValidationProvider>

                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">

                        <ValidationProvider
                          rules="required"
                          v-slot="{ passed, failed, errors }">

                            <el-select 
                              style="width:100%"
                              filterable
                              class="select-primary"
                              v-model="newKey.http_api_jwt_mode" 
                              value-key="abbreviation"
                              label="Mode"
                              placeholder="Mode">
                              <el-option
                                v-for="mode in modes"
                                :key="mode"
                                :label="mode"
                                :value="mode">
                              </el-option>
                            </el-select>
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-lg-12">

                    <ValidationProvider
                      rules="required"
                      v-slot="{ passed, failed, errors }">

                        <el-select 
                          style="width:100%"
                          filterable
                          class="select-primary"
                          v-model="newKey.http_api_jwt_type" 
                          value-key="abbreviation"
                          label="Type"
                          placeholder="Type">
                          <el-option
                            v-for="type in types"
                            :key="type"
                            :label="type"
                            :value="type">
                          </el-option>
                        </el-select>
                        <span class="validation-error">{{ errors[0] }}</span>
                    </ValidationProvider>

                      </div>
                    </div>

                      <div class="text-center">
                          <base-button :loading="submitInProccess" native-type="submit" type="primary" class="btn-fill ">
                            Create API key
                          </base-button>
                      </div>
                      
                  </form>
                  </ValidationObserver>
              
          </card>
      </modal>
    </div>
  </card>
  
</template>

<script>

import {Table, TableColumn, Select, Option} from 'element-ui'
import { BaseSwitch } from '@/components';
import {Modal} from 'src/components'

import { extend } from "vee-validate";
import { required,min } from "vee-validate/dist/rules";


extend("name",{
  ...min,
  message: 'Description must be at leat 5 charachters long'
})

extend("required", {
  ...required,
  message: 'This field is required'
});

export default {
  components: {
    Modal,
    BaseSwitch,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option
  },
  props: {
    existingApiKeys: {}
  },
  watch: {
    existingApiKeys: function(ov, nv){
      //console.log(this.existingApiKeys)
      this.tableData = this.existingApiKeys      
    }
  },
  data() {
      return {
        
        submitInProccess: false,
        modes: [
          'Live',
          'Test'
        ],
        types: [
          'OTP',
          'MARKETING'
        ],
        modals:{
          modalAddKey: false
        },
        newKey: {
          http_api_jwt_token: null,
          http_api_jwt_description: null,
          http_api_jwt_status: null,
          http_api_jwt_short_token: null,
          http_api_jwt_mode: null,
          http_api_jwt_type: null
        },
        tableData: [
          /*{
            http_api_jwt_description: 'Live api key',
            api_keys_key_mode: 'live',
            api_keys_key: 'xxccddfkeykey123',
            api_keys_key_status: true
          }*/
        ]
      }
  },
  methods: { 
    editRow(){
    },
    saveRow(index){
      this.tableData[index].http_api_jwt_description
      this.updateKey(this.tableData[index])
      /*
      console.log('save row: ' + index)
      console.log(this.tableData[index].billing_tokens_description)
      */
    },
    updateKey(row){
      //console.log(row)
      //this.$toast("I'm a toast!");
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      this.$http.post(api_endpoint + '/api/update-http-key', row)
      .then( (response) => {
        this.$toast.success("Access key updated");
      })
      .catch( (error) => {
        //row.api_keys_key_status = !row.api_keys_key_status;
      });
    },
    async createApiKey()  {
      
      this.submitInProccess = !this.submitInProccess;      
      //const res = await this.$http.post(api_endpoint + '/api/add-http-key', this.newKey)
      const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/apis/keys/http/create`, this.newKey)
      this.submitInProccess = !this.submitInProccess;      
      if(res && res.status == 200){
        this.modals.modalAddKey = false;
        this.tableData.push(res.data)
        this.$toast.success(`A new key request was successfully created`);
      }

      //const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      //this.$http.post(api_endpoint + '/api/add-http-key', this.newKey)
      //.then( (response) => {
      //  this.submitInProccess = !this.submitInProccess;
      //  this.modals.modalAddKey = false;
      //  this.tableData.push(response.data)
      //  this.$toast.success(`A new key request was successfully created`);
      //})
      //.catch( (error) => {
      //  this.submitInProccess = !this.submitInProccess;
      //
      //});
      
    }
  }
}
</script>

<style>
.no-background{
  background: none;
}
.my-padding{
  padding-left:30px;
  padding-right:30px;
}
hr{
  
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.el-table th.el-table__cell{
  background:none !important;
  border:none !important;
}

.el-table td.el-table__cell{
  background:none !important;
  border-bottom: rgba(255, 255, 255, 0.1) !important;
}


#http-form .el-input__inner {
    color: white !important
}

</style>