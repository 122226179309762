<template>
  <card class="card-white filter-card" style="border:1px solid #1d8cf8">
    <ValidationObserver  v-slot="{ handleSubmit, valid }">
        <form id="filter-row" @submit.prevent="handleSubmit(apply)">

            <h6 slot="header" class="card-title">Filter Settings</h6>
            
            <div class="row">
                <div class="col-lg-4" style="padding-right:0px !important;">
                    <div class="form-group has-label left-input">
                        <ValidationProvider rules="required" v-slot="{ passed, failed, errors }">
                            <el-select 
                                size="sm"
                                @change="setAction()"
                                filterable
                                v-model="settings.fieldName" 
                                label="Filter By"
                                placeholder="Filter By">
                                <el-option
                                    v-for="item in fieldOptions"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                                </el-option>
                            </el-select>
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>        
                </div>
                
                <div class="col-lg-4" style="padding-left:0px !important;padding-right:0px !important;">
                    <div class="form-group has-label center-input">
                        <ValidationProvider rules="required" v-slot="{ passed, failed, errors }">
                            <el-select 
                                v-if="settings.fieldName != 'status' && settings.fieldName != 'group' && !noSort"
                                filterable
                                v-model="settings.fieldAction" 
                                label="Filter Action"
                                placeholder="Filter Action">
                                <el-option
                                    
                                    v-for="item in filedActions"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                                </el-option>
                            </el-select>

                            <base-input 
                                v-else
                                :disabled="true"
                                v-model="settings.fieldAction"
                                type="primary"
                                placeholder="Filter Action">
                            </base-input>

                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>        
                </div>
                <div class="col-lg-4" 
                    v-if="settings.fieldAction == 'order by' && settings.fieldName != 'group' " 
                    style="padding-left:0px !important;">
                    <div class="form-group has-label right-input">
                        <ValidationProvider rules="required" v-slot="{ passed, failed, errors }">
                            <el-select 
                                filterable
                                v-model="settings.fieldValue" 
                                label="Order Type"
                                placeholder="Order Type">
                                <el-option
                                    v-for="item in OrderByTypes"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                                </el-option>
                            </el-select>
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>        
                </div>
                <div 
                    v-else-if="settings.fieldAction != 'is not empty' && settings.fieldAction != 'is empty'"
                    class="col-lg-4" style="padding-left:0px !important;" >

                    <div 
                        v-if="settings.fieldName != 'status'"
                        class="form-group has-label right-input">
                        <ValidationProvider rules="required" v-slot="{ passed, failed, errors }">
                            <base-input 
                                
                                v-model="settings.fieldValue"
                                type="primary"
                                placeholder="Filter Value">
                            </base-input>
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="form-group has-label right-input" v-else>
                        <ValidationProvider rules="required" v-slot="{ passed, failed, errors }">
                            <el-select 
                                size="sm"
                                @change="setDlrStatus()"
                                filterable
                                v-model="settings.fieldValue" 
                                label="Filter By"
                                placeholder="Delivery status">
                                <el-option
                                    v-for="item in statusList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                        

                </div>
            </div>
            
            <div class="row " slot="footer">
                <div class="col-lg-12 text-right card-footer">
                    <base-button size="sm" type="danger" @click="cacnel">
                        <i class="fa fa-times"></i> Cancel
                    </base-button>
                    <base-button size="sm" type="info" native-type="submit">
                        <i class="fa fa-check"></i> Apply
                    </base-button>
                </div>
            </div>
        
        </form>

    </ValidationObserver>
  </card>
</template>

<script>
import { Select, Option } from 'element-ui'
export default {
    components:{
        [Select.name]: Select,
        [Option.name]: Option
    },
    props:{
        cfg: {
            type: Object
        },
        index: {
            type: Number
        },
        noSort: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            statusList: [
                {
                    value: 1,
                    label: 'Delivered'
                },
                {
                    value: 2,
                    label: 'Failed'
                },
                {
                    value: 3,
                    label: 'Queued'
                },
                {
                    value: 6,
                    label: 'Processing'
                },
                {
                    value: 16,
                    label: 'Rejected'
                },
                {
                    value: 32,
                    label: 'Network Filure'
                },
                {
                    value: 34,
                    label: 'Other'
                }
            ],
            settings: {
                fieldName: null,
                fieldAction: null,
                fieldValue: null,
                applied: false,    
                index: this.index
            },
            filedActions: [
                'is empty',
                'is not empty',
                'not equals to',
                'equals to',
                'order by'
            ],
            OrderByTypes: [
                'decending',
                'acending'
            ],
            fieldOptions: [
                'group',
                'custom1',
                'custom2',
                'custom3',
                'custom4',
                'custom5',
                'status',
                'sent'
            ]
        }
    },
    methods: {
        setDlrStatus(){},
        setAction(){
            if(this.settings.fieldName == 'group' || this.settings.fieldName == 'status')
                this.settings.fieldAction = 'equals to'
            
            if(this.noSort)
                this.settings.fieldAction = 'equals to'
        },
        apply(){
            this.settings.applied = true
            this.$emit('settings', this.settings)
        },
        cacnel(){
            this.$emit('cancel', this.settings)
        }
    }
}
</script>

<style>

    #filter-row .el-input__inner {
        background: white !important;
    }

    .left-input input {
        border-top-right-radius: 0% !important;
        border-bottom-right-radius: 0% !important;
        border-right: none !important;
        color:#1e1e2f !important;
    }

    .center-input input {
        border-radius: 0% !important;
        color:#1e1e2f !important;
    }

    .right-input input {
        border-top-left-radius: 0% !important;
        border-bottom-left-radius: 0% !important;
        border-left: none !important;
        color:#1e1e2f !important;
    }

    .filter-card .card-body{
        padding-top: 15px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        margin-top: 0px !important;
    }
    .filter-card .card-header{
        padding-bottom: 0px !important;
    }
    .filter-card .validation-error {
        color:#1e1e2f !important;
    }
    .filter-card .card-footer {
        margin-top: 0px !important;
        padding-top: 0px !important;
    }

    .filter-card .card-footer .btn-sm {
        font-size: 10px !important;
    }

    .filter-card .el-input__inner {
        height: calc(2rem + 1px)
    }
    .filter-card .el-input__icon {
        line-height: 30px !important;
    }

    .filter-card .form-control {
        height: calc(2rem + 1px)
    }

    .filter-card {
        margin-bottom: 0px !important;
    }
</style>
