<template>
  <div class="row">
    <div class="col-lg-12">
        <div class="row justify-content-center">
            <h3 class="info-text">My Payments</h3>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <card type="primary" class="pending-payments" :class="['',{ 'border border-success': intentId == 'current' }]" >
                    <template slot="header">
                        <base-radio 
                            name="current" 
                            v-model="intentId">
                            Current payment
                        </base-radio>     
                        
                        
                    </template>          
                    <div class="row justify-content-center">
                        <h3 class="font-weight-light">{{ wizardModel.amount }}<small>&euro;</small></h3>
                    </div>

                </card>
            </div>
        </div>
        <div class="row" v-if="items.length">
            <h4 class="text-info font-weight-bold p-4">{{items.length}} Pending Incomplete Payments</h4>
        </div>
        <div class="row" v-for="(item, index) in items" :key="index">
            <div class="col-lg-12">
                <card :class="{ 'border border-success': index == intentId }" class="pending-payments">
                    <template slot="header">  
                        <base-radio 
                            class="d-inline-block"
                            :name="index.toString()" 
                            v-model="intentId">
                            {{item.id}}
                        </base-radio>         
                        
                        <h5 class="title d-inline-block">
                            
                        </h5>
                        <div class="d-inline-block pull-right">
                            
                            &nbsp;&nbsp;
                            <base-button 
                                type="danger" 
                                class="pt-0 pb-0 pl-1 pr-1 d-inline-block" 
                                size="sm" 
                                simple
                                @click="cacnelPayment(item, index);" 
                            >
                                <mdicon name="delete" size="18"></mdicon>
                            </base-button>
                        </div>
                    
                    </template>

                    <div class="row justify-content-center">
                        <h3 class="font-weight-light">{{ item.amount }}<small>&euro;</small></h3>
                    </div>
                    
                    <template slot="footer">
                        <div class="row">
                            <div class="col-lg-6 ">
                                <h6 class="title pull-right text-warning">{{item.status.split('\_').join(" ")}}</h6>
                            </div>
                            <div class="col-lg-6 ">
                                <span class="pull-left">{{item.created | formatDate}}</span>
                                
                            </div>
                        </div>
                    </template>
                    
                </card>
                
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { BaseRadio } from 'src/components/index';
export default {
    components:{
        BaseRadio
    },
    props:{
        wizardModel: {
            type: Object
        }
    },
    data(){
        return {
            items: [],
            loading: false,
            intent: null,
            selectedInx: null,
            intentId: 'current',
            pending: {},
        }
    },
    filters: {
        formatDate(value){
            if (!value) return '';
            const date = new Date(value * 1000); // Convert from seconds to milliseconds
            return new Intl.DateTimeFormat('en-GB', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }).format(date);
        }
    },
    methods:{
        async cacnelPayment(intent, index){
            this.loading = !this.loading
            const res = await this.$http.delete(`${process.env.VUE_APP_API_ENDPOINT}/billing/stripe/payment/intent/delete/${intent.id}` )
            this.loading = !this.loading
            if(res && res.status == 200){                
                const { status } = res.data
                if(status == 'canceled') 
                    this.items.splice(index, 1)
            }
        },
        async validate(){
            
            let intent = {}
            if(/[0-9]{1,}/.test(this.intentId)){
                const { id, client_secret } = this.items[this.intentId]
                intent = {
                    id, client_secret
                }
            }else{
                intent = {
                    client_secret: null,
                    id: null
                }
            }

            this.$emit("on-validated", true, { intent });
            return true
        },
        async init(){
            this.items = []
            this.$emit('loading', true)
            const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/billing/stripe/payment/intent/list`, this.wizardModel)
            this.$emit('loading', false)
            if(res && res.status == 200){
                this.items = res.data
            }
        }
    }
}
</script>

<style>
.pending-payments .card-header {
    padding: 10px !important;
}
.pending-payments .card-body {
    padding: 0px !important;
}
.pending-payments .card-footer{
    padding: 0px !important;
}


</style>