<template>
    <div class="card flex-row">
        <div class="m-0 d-inline-block pl-3 pt-2">
            <base-radio 
                :name="stripeCardId" 
                :value="selectedcardId"
                @input="(value) => $emit('input', value)">
            </base-radio>
        </div>
        <div class="card-img-left pt-3 pl-3 pr-3">
            <component 
            v-if="cardInfo.brand && cardInfo.brand != 'unknown'" 
            :is="cardInfo.brand"
            width="45px" 
            height="45px" 
            />
            <mdicon v-else name="credit-card" size="45" class="tim-icons"/>
        </div>
        
        <div class="card-body">
            <h5 class="card-title">
                <div class="d-inline-block">
                    <span class="radio-button"></span>
                    <span class="radio-button"></span>
                    <span class="radio-button"></span>
                    <span class="radio-button"></span>
                    &nbsp;
                </div>
                <div class="d-none d-lg-inline-block">
                    <span class="radio-button"></span>
                    <span class="radio-button"></span>
                    <span class="radio-button"></span>
                    <span class="radio-button"></span>
                    &nbsp;
                    <span class="radio-button"></span>
                    <span class="radio-button"></span>
                    <span class="radio-button"></span>
                    <span class="radio-button"></span>
                    &nbsp;
                    <span class="radio-button"></span>
                    <span class="radio-button"></span>
                    <span class="radio-button"></span>
                    <span class="radio-button"></span>
                    &nbsp;
                </div>
                <span class="text-white">{{ cardInfo.last4 }}</span><br>
            </h5>
            <p class="card-text">
                <span class="text-white">{{ cardInfo.exp_month }}/{{ cardInfo.exp_year }} &nbsp;&nbsp;</span>
            </p>
        </div>
        <div class="card-img-right pt-3">
            <base-button danger simple style="border:none;" @click="revomePaymentMethod" :loading="loading">
                <i class="tim-icons icon-trash-simple text-warning"></i>
            </base-button>
        </div>
    </div>
</template>

<script>



import { BaseCheckbox, BaseRadio } from 'src/components/index';
import Visa from './brandSvgs/Visa.vue';
import Mastercard from './brandSvgs/MasterCard.vue';
import UnionPay from './brandSvgs/Unionpay.vue';
import JCB from './brandSvgs/Jcb.vue';
import Discover from './brandSvgs/Discover.vue';
import DinersClub from './brandSvgs/Diners.vue';
import AmericanExpress from './brandSvgs/American.vue';
import Amex from './brandSvgs/American.vue';

export default {
    components:{
        BaseCheckbox,
        BaseRadio,
        Visa,
        Mastercard,
        UnionPay,
        JCB,
        Discover,
        DinersClub,
        AmericanExpress,
        Amex
    },
    props:{
        index:{
            type: Number,
        },
        cardInfo:{
            type: Object
        },
        stripeCardId:{
            type: String
        },
        selectedcardId:{
            type: String
        },
        setExistingCard:{
            type: Function
        }
    },
    data() {
        return {
            card: null,
            loading: false
        }
    },
    mounted(){

    },
    methods: {
        selected(stripeCardId){
            this.card = stripeCardId
            this.$emit('input', stripeCardId)
            //this.setExistingCard({...this.cardInfo, ...{id: this.stripeCardId}})
        },
        async revomePaymentMethod(){
            try{
                this.loading = !this.loading
                const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/billing/stripe/payment/method/detach`, { method: this.stripeCardId })
                this.loading = !this.loading
                if(res && res.status == 200){
                    this.$emit('detach', this.index)
                }
            }catch(e){
                console.log(e.message)
            }
        }
    }
}
</script>

<style>
    .radio-button {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 2px solid #cccccc;
        background: #cccccc;
        margin-right: 2px;  
    }

    .item-boder {
        box-shadow: 0px 1px 1px 0 #8965e0;
    }

</style>