<template>
  
  <div class="w-100">
    <div class="row">
      <div class="col-lg-12">
        <div class="btn-group">
          <base-button v-if="!newCard" size="sm" type="info" simple @click="newCard = !newCard"> Add new card </base-button>
          <base-button v-if="!newCard" size="sm" type="info" simple @click="newCard = !newCard"> Add new card </base-button>
        </div>
      </div>
      <div class="col-lg-4">
        <ExistingCard v-for="(item, index) in savedCards" :key="index" :index="index"
          :cardInfo="item.card"
          :stripeCardId="item.id"
          :selectedcardId="selectedcardId"
          :setExistingCard="setExistingCard"
          v-model="selectedcardId"
        />
      </div>
      <div class="col-lg-12">
        <RealCreditCard 
          v-if="selectedcardId"
          :last4="existingCard.last4"
          :month="existingCard.exp_month"
          :year="existingCard.exp_year"
          :brand="existingCard.brand"
          :name="`${existingCard.country} ${existingCard.brand}`"
        />
        <NewCard v-if="newCard"/>
      </div>
      
      
    </div>
  </div>
  
</template>

<script>
import { TabPane, Tabs, Collapse, CollapseItem } from 'src/components';

//import CheckOutAmounts from '../SummeryCheckOutAmounts.vue';

import ExistingCard from './ExistingCard.vue'
import RealCreditCard from './RealCreditCard.vue'
import NewCard from './NewCard.vue'

//import VueRecaptcha from 'vue-recaptcha';
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
//import { StripeCheckout } from '@vue-stripe/vue-stripe';
extend("required", required);
extend('cardHolder', {
  validate(cardHolder) {
    const regex = /^[A-Za-zÀ-ÖØ-ÿ-' ]{1,50}$/;
    return regex.test(cardHolder);
  },
  message: 'Please enter a valid credit card holder name',
});

export default {
  components: {
    TabPane, 
    Tabs, 
    //SavedCards,
    NewCard,
    ExistingCard,
    RealCreditCard,
    //CheckOutAmounts
    //VueRecaptcha,
    //StripeCheckout,
  },
  computed: {
    //stripeElements () {
    //  return this.$stripe.elements();
    //},
  },
  props: ['billingDetails'],  
  data () {
    return {
      newCard: false,
      selectedcardId: null,
      savedCards: [],
      elements: null,
      brand:null,
      name: null,
      //siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      cardHolder: null,
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      stripeError: null,
      cardElement: null,
      existingCard: false,
      existingCardId: false,
      paymentElement: null,
    }
  },
  mounted () {
  },
  beforeDestroy () {
    //this.cardNumber.destroy();
    //this.cardExpiry.destroy();
    //this.cardCvc.destroy();

  },
  methods: {
    paneChanged(){},
    setBrand(brand){ this.brand = brand },
    setExistingCard(card){
      console.log(card)
      if(card) {
        //this.existingCard = card
        //this.existingCardId = card.id
      }
    },
    async init(){
      try{
        if(this.paymentElement) await this.paymentElement.destroy()

        const { billing_stripe_customer_id } = this.billingDetails
        if(billing_stripe_customer_id){
          this.$emit("submitInProccess", true);
          const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/billing/stripe/payment/method/read`, this.billingDetails)
          if(res && res.status == 200){
            const cards = res.data.length ? res.data.sort((a,b) => b.created - a.created) : []
            this.savedCards = cards
          }
          this.$emit("submitInProccess", false);
        }
        
        //console.log(paymentMethods)
        //await this.setupPtripeElements()
      }catch(e){
        console.log(e.message)
      }
      //console.log(this.billingDetails)
      //this.$refs.recaptcha.reset();
    },
    async validate() {
      
      //this.$emit("submitInProccess", true);
      // validate
      try{
        
        
        //const confirmPaymentData = {
        //  //TODO: ADD METADA UUID!!
        //  elements: this.elements,
        //  payment_method: this.existingCard.id,
        //  confirmParams: {
        //    return_url: 'http://localhost/billing/funds', // URL to redirect after payment confirmation
        //  },
        //}
        
        const { error: confirmError, paymentIntent: confirmedIntent } = await this.$stripe.confirmPayment({
          clientSecret: this.billingDetails.clientSecret,
          confirmParams: {
            return_url: 'http://localhost/billing/funds', // URL to redirect after payment confirmation
            payment_method: this.existingCardId
          },
        })

          
          
        //this.$emit("submitInProccess", false);
        //console.log(confirmError, confirmedIntent)
        //if(confirmError) throw confirmError
        //

        //const {token, error} = await this.$stripe.createToken(this.cardNumber, {
        //  name: this.billingDetails.billing_entity_name || "",
        //  address_line1: this.billingDetails.billing_entity_address_one || "",
        //  address_line2: this.billingDetails.billing_entity_address_two || "",
        //  address_city: this.billingDetails.billing_entity_city || "",
        //  address_state: this.billingDetails.billing_entity_country_name || "",
        //  address_zip: this.billingDetails.billing_entity_postal_code || "",
        //  address_country: this.billingDetails.billing_entity_country_code || ""
        //})
//
        //if(error){
        //  // TODO: alert
        //  this.stripeError = error
        //  this.$emit("submitInProccess", false);
        //  return 
        //}
//
        //this.$emit("submitInProccess", false);
        //this.$emit("on-validated", true, { ...token, ...{ cardHolder: this.cardHolder} });
        //return true

      }catch(e){
        this.$toast.error(e.message)
        this.$emit("submitInProccess", false);
      }
      
      
    }
  }
  
}
</script>

<style>
</style>