<template>
  <card>
    <template slot="header">
        <div class="row">
            <div class="col-lg-10">
                <h4><b>{{ billing.billing_entity_name }}</b></h4>
            </div>
            <div class="col-lg-2" v-if="isEdit">
                <div class="row justify-content-end" >
                    <div class="col-lg-2">
                        <base-button simple size="sm" class="pl-2 pr-2 float-right"  type="primary" @click="edit">
                            <mdicon name="file-document-edit-outline" size="18"></mdicon>
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
    </template>
    
    <div v-if="billing.billing_entity_country_code">
        <div class="row">
        <div class="col-lg-12">
            <country-flag :country='billing.billing_entity_country_code' size='normal'/>
            <h4 class="d-inline-block ml-2">{{ billing.billing_entity_country_name }}</h4>
        </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <p>
                    {{ billing.billing_entity_address_one }}&nbsp;{{ billing.billing_entity_address_two }}&nbsp;{{ billing.billing_entity_city }}&nbsp; {{ billing.billing_entity_postal_code }}<br>
                    <br>           
                </p>
                <h5 v-if="billing.billing_entity_registration_number"><b><u>Registration Number</u></b> - {{ billing.billing_entity_registration_number }}</h5> 
                <h5 v-if="billing.billing_entity_tax_number"><b><u>Tax Number</u></b> - {{ billing.billing_entity_tax_number }}</h5> 
                <div v-if="billing.billing_entity_vat_number">
                    <h5 class="d-inline-block"><b><u>VAT</u></b> - {{ billing.billing_entity_vat_number }}</h5>  &nbsp;&nbsp;
                    <div v-if="billing.billing_entity_valid_eu_vat" class="text-success d-inline-block">
                        <b><u><mdicon name="text-box-check-outline"></mdicon> &nbsp;&nbsp; EU Vat number verified</u></b>
                    </div>
                    
                </div>
            
            </div>
        </div>
    </div>
    <div v-else>
        <h5>
            Billing information is missing
        </h5>
    </div>
    
    
  </card>
</template>

<script>
import CountryFlag from 'vue-country-flag'

export default {
    components:{
        CountryFlag
    },
    props:{
        billing: {
            type: Object
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        edit(){
            this.$emit('edit', true)
        }
    }
}
</script>

<style>

</style>