<template>
  <div class="w-100">
  <div class="d-flex justify-content-center w-100">
    <div class="contacts-wrapper">
      <div class="row">
        <div class="col-lg-12">
          <card>
            <base-button type="success" :disabled="submitInProccess" class="btn btn-simple" @click="toggle()">
              <i class="fa fa-plus"></i> &nbsp; Upload Contacts
            </base-button>
            &nbsp;
             <router-link 
              :disabled="submitInProccess"
              class="btn btn-primary btn-simple" 
              to="/campaigns/new" tag="button">  
                    <i class="fa fa-plus" aria-hidden="true"></i> &nbsp; New Campaign
              </router-link>
          </card>
        </div>
      </div>
     
      <div class="row">  
        <div class="col-lg-12">
          <ContactGroups 
            :submitInProccess="submitInProccess"
            :pane="pane"
            :groups="groupss"
            @getList="getList"
            :getGroups="contactsAdded"
            :deleteGroup="deleteGroup"
          />
        </div>
      </div>
      
      <tabs type="primary" square :clickHandler="paneChanged">
        
        <tab-pane label="Groups" :disabled="submitInProccess">
          
          <div class="row">  
              <div class="col-lg-12">
                <ContactGroupMembers
                  :currentGroup="groupName"
                  :groupMembersArray="groupMembersArray"
                  :totalRecords="groupMembersCount"
                  :pageCount="pageCount"
                  :perPage="perPage"
                  :page="page"
                  :sort="sort"
                  @pagination="pagination"
                  @sortme="sortme"
                  @download="download"
                  @filterColumn="filterColumn"
                  :submitInProccess="submitInProccess"
                />
              </div>
              
              
          </div>
        </tab-pane>
        <tab-pane label="Countries" :disabled="submitInProccess">
          <div class="row">  
            <div class="col-lg-12">
              <ContactsByCountry 
                :submitInProccess="submitInProccess"
                :groupMembersArray="groupMembersArray"
                :totalRecords="groupMembersCount"
                :pageCount="pageCount"
                @expandedSubmitInProcess="expandedSubmitInProcess"
                :perPage="perPage"
              />
            </div>
          </div>
        </tab-pane>
      </tabs>
    </div>
    <UploadContactsModalVue 
        @uploadedContacts="contactsAdded"
        continueButtonText="continue"
        ref="contactsModal"/>
  </div>
  </div>
</template>
<script>
//import OverView from './ContactManagment/OverView.vue';
import ContactGroups from './ContactManagment/ContactGroups.vue';
import ContactGroupMembers from './ContactManagment/ContactGroupMembers.vue';
import ContactsByCountry from './ContactManagment/ContactsByCountry.vue';
import UploadContactsModalVue from './ContactManagment/UploadContactsModal.vue'
import { TabPane, Tabs } from 'src/components';



const api_endpoint = process.env.VUE_APP_API_ENDPOINT
export default {
  components: {
    ContactGroups,
    ContactGroupMembers,
    ContactsByCountry,
    UploadContactsModalVue,
    TabPane, 
    Tabs
    //OverView
  },
  mounted() {
    this.getGroups('groups')
  },
  data(){
    return {
      pane: 'Groups',
      groupTotal: null,
      groupName: null,
      groupss: [],
      submitInProccess: false,
      groupMembersArray: [],
      groupMembersCount: 0,
      page: 1,
      perPage: 10,
      pageCount: 0,
      sort: {
        by: 'contact_add_date',
        direction: 'desc'
      },
      filteFields:{
        contact_country_name: null,
        contact_msisdn: null,
        contact_operator_name: null
      }
      //view:"overview"
    }
  },
  methods:{
    async getList(row){
      this.page = 1
      this.groupName = row.groups_name
      this.pageCount = 1
      this.groupMembersCount = 0
      await this.loadData(row)
    },
    expandedSubmitInProcess(value){
      this.submitInProccess = value
    },
    async deleteGroup(row, index){      
      
      this.submitInProccess = !this.submitInProccess
      const res = await this.$http.delete(`${api_endpoint}/contacts/delete/${row.groups_group_id}`)
      this.submitInProccess = !this.submitInProccess
      if(res && res.status == 200){
        //this.groupss = res.data
        //this.groupss.splice(index, 1)
        this.groupss[index]['groups_status'] = 0
        
      }
    },
    filterColumn(data){
      this.page = 1
      this.filteFields = data
      this.loadData()
    },
    download(){
      this.submitInProccess = !this.submitInProccess
      this.$http.post(api_endpoint + '/contacts/read/groups',{
        page: this.page,
        perPage: this.perPage,
        sort: this.sort,
        filteFields: this.filteFields,
        download: true,
        contactListName: this.groupName
      },{
        responseType: 'blob'
      })
      .then( (response) => {
        this.submitInProccess = !this.submitInProccess
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'contacts.csv');
        document.body.appendChild(link);
        link.click()
      })
      .catch( (error) => {
        
      });
    },
    sortme(sort){
      this.page = 1
      this.sort = {...sort}
      this.loadData()
    },    
    pagination(page){
      this.page = page
      this.loadData()
    },
    contactsAdded(group){
      
      const existing = this.groupss.findIndex(existing => existing.groups_name == group.groups_name)
      
      if(existing > -1){
        //let temp = this.groups[existing]
        //temp.refresh = true
        //this.groupss.splice(existing, 1)
        //this.groupss.push(temp)
      }else{
        //this.groupss[0].groups_size += group.totalRowsAdded
        this.groupss.push({
          groups_name: group.groups_name,
          groups_size: group.totalRowsAdded
        })
      }
      
    },
    groups(data){
      this.groupMembersArray = data.rows
      this.groupMembersCount = data.count
      this.pageCount = Number((this.groupMembersCount / this.perPage).toFixed(0)) + 1
      this.page = this.page > this.pageCount ? 1 : this.page
    },
    countries(data){
      this.groupMembersArray = data
      this.groupMembersCount = data.length
      this.pageCount = Number((this.groupMembersCount / this.perPage).toFixed(0)) + 1

    },
    async loadData(row){
      
      if(this.submitInProccess) return 
      this.submitInProccess = !this.submitInProccess

      const pageData = {
        page: this.page,
        perPage: this.perPage,
        sort: this.sort,
        filteFields: this.filteFields,
        contactListName: this.groupName
      }
      
      
      const res = await this.$http.post(`${api_endpoint}/contacts/read/${this.pane.toLowerCase()}`, pageData)

      this.submitInProccess = !this.submitInProccess

      if(res && res.status == 200 && res.data) {
        if(row) {
          this.groupName = row.groups_name
          this.groupTotal = row.total
        }
        this[this.pane.toLowerCase()](res.data)
      }
        
      
    },
    async getGroups(type){
      
      if(this.submitInProccess) return 
      this.groupss = []
      this.submitInProccess = !this.submitInProccess

      //const res = await this.$http.get(api_endpoint + '/contacts/groups')
      const res = await this.$http.get(`${api_endpoint}/contacts/${type}`)
      if(res && res.status == 200){
        this.groupss = res.data
        
        this.submitInProccess = !this.submitInProccess
      }

    },
    toggle(){
      return this.$refs['contactsModal'].toggle();
    },
    async paneChanged(pane){
      this.pane = pane
      this.reset()
      await this.getGroups(pane.toLowerCase())
    },
    reset(){
      this.groupMembersArray = []
      this.groupMembersCount = 0
      this.pageCount = 0
      this.page = 1

    }
  },
  
};
</script>
<style>
  .contacts-wrapper {
    width:100%;
    max-width:1800px;
  }

</style>

