<template>

        <card>
            <ValidationObserver ref="observer" v-slot="{ valid }">
            <form @submit.prevent="submit">
                <div class="row">
                    <div class="col-lg-1" style="padding-top:22px;">
                        <base-button 
                            class="filter-button-analytics" 
                            type="primary" 
                            simple
                            :loading="loading"
                            native-type="submit">
                            <i class="fa fa-search"></i> Go!
                        </base-button>
                    </div>
                    <div class="col-lg-3">
                        <ValidationProvider
                            rules="required"    
                            v-slot="{ passed, failed, errors }">

                            <base-input label="Date range">
                            
                                <el-date-picker
                                    type="daterange"
                                    range-separator="To"
                                    date-format="yyyy-mm-dd"
                                    start-placeholder="Start date"
                                    end-placeholder="End date"
                                    align="right"
                                    v-model="timeRange"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            
                            
                                <span class="validation-error">{{ errors[0] }}</span>
                            
                            </base-input>
                        </ValidationProvider>
                    </div>
                    <div class="col-lg-6" style="padding:0px">
                        <div class="row" >
                            <div class="col-lg-2" style="padding:22px 0px 0px 5px;">
                                <base-button class="filter-button-analytics" simple type="primary" @click="$refs.filtersModal.toggle()">
                                    <i class="fa fa-filter"></i> Filters
                                </base-button>
                            </div>
                            <div class="col-lg-10" style="padding:0px 0px 0px 5px;">
                                <base-input v-if="form.filters.length" label="Custom contacts filters">
                                    <ContactFiltered v-for="(item, index) in form.filters" :key="index" @close="removeFilter(item)" :setting="item"/>
                                </base-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2" style="padding-top:22px;">
                        <router-link 
                        class="btn btn-primary btn-simple" 
                        to="/campaigns/new" tag="button">  
                            <i class="fa fa-plus" aria-hidden="true"></i> &nbsp; New Campaign
                        </router-link>
                    </div>
                    
                    
                </div>
            </form>
            </ValidationObserver>
            
            
            
            <filtersModal ref="filtersModal" @filters="fileterd"/>
            
        </card>

</template>

<script>

import {DatePicker} from 'element-ui'
import { extend } from "vee-validate";
import { required,numeric,min,max,email } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: 'This field is required'
});

var endDate = new Date();
var startDate = new Date(endDate.getTime()-7*24*60*60*1000);

//import ContactFilters from '../../New/Components/Filter.vue'
import ContactFiltered from '../../New/Components/Filtered.vue'
import filtersModal from '../Componenets/FiltersModal.vue'

export default {
    components:{
        [DatePicker.name]: DatePicker,
        //ContactFilters,
        ContactFiltered,
        filtersModal
    },
    props:{
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            timeRange:[
                startDate,
                endDate
            ],
            form: {
                filters: [],
            },
            pickerOptions: {
                shortcuts: [
                    {
                        text: 'Last 7 days',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: 'Last 31 days',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 31);
                            picker.$emit('pick', [start, end]);
                        },
                    }
                ]
            },
        }
    },
    methods: {
        fileterd(data){
            this.form.filters = data
        },
        submit(){
            let StartDateFromSelect = new Date(this.timeRange[0]);
            let EndDateFromSelect = new Date(this.timeRange[1]);  
            var from  = StartDateFromSelect.toLocaleString()
            var to  = EndDateFromSelect.toLocaleString()

            //console.log(this.form.dateRange)
            this.$emit('filter', {...this.form, ...{
                dateRange: [from, to]
            } })
        },
        removeFilter(setting){
            this.pending = false
            if(this.form.filters[setting.index]) {
                this.form.filters.splice(setting.index, 1)
            }else{
                this.form.filters.splice(0, 1)
            }
        }
    }
}
</script>

<style>
.el-range-editor .el-range-input {
    color:white !important;
    background: none;
}
.filter-button-analytics{
    padding: 8px 25px;
}

</style>