<template>
    <div class="row justify-content-center">
       <card>
            <template slot="header">
                <div class="row w-100 justify-content-center">
                    <h3 class="info-text">Payment Details</h3>
                </div>
            </template>  
            
            <div class="row w-100 justify-content-center">
                <div class="col-lg-8" >
                    
                        <table class="table no-border-table">
                            <tbody>
                                <tr>
                                    <th scope="row" class="text-white">Payment Method</th>
                                    <td><u>Credit Card</u></td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-white">Payment Reference</th>
                                    <td><u>{{ summeryData.intent.id.split(/\_/)[1] }}</u></td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-white">Payment Customer Id</th>
                                    <td><u>{{ summeryData.billing_stripe_customer_id.split(/\_/)[1] }}</u></td>
                                </tr>
                            </tbody>
                        </table>
                    
                    
                    <!--
                    <CheckOutAmounts 
                        :amount="parseInt(summeryData.amount)" 
                        :vatpct="parseFloat(summeryData.billing_entity_eu_vat)"
                    />
                    -->
                    <base-button simple type="muted"  @click="cancel">
                        Cancel
                    </base-button>
                </div>

            </div>

            <!--
            <div class="col-lg-4 col-sm-12">
                <RealCreditCard
                    v-if="summeryData.card && paymentStatus == null" 
                    :last4="summeryData.card.last4"
                    :month="summeryData.card.exp_month"
                    :year="summeryData.card.exp_year"
                    :brand="summeryData.card.brand.replace(/ /g,'')"
                    :name="summeryData.cardHolder"
                />
            </div>
            -->
        </card>
    </div>

</template>

<script>
import RealCreditCard from '../../Stripe/RealCreditCard.vue';
import CheckOutAmounts from './CheckOutAmounts.vue';
import paymentSuccess from './CardPaymentSuccess.vue'
import paymentFailed from './CardPaymentFailed.vue'
export default {
  components: {
    RealCreditCard,
    CheckOutAmounts,
    paymentSuccess,
    paymentFailed
  },
  props: [
    'summeryData', 
    'paymentStatus',
    'creditCardPaymentId',
    'creditCardPaymentAmount',
    'cancel'
    ],
  methods: {
    validate() {
        //DOWNLOAD INVOICE HERE
        if(this.paymentStatus == null)
            return this.$emit("on-validated", true, {});
        
        //download invoice here
        
        //
        //return true
    }
  }
}
</script>

<style>
.no-border-table, .no-border-table th, .no-border-table td {
  border: none !important;
}
</style>