<template>
  <div class="w-100">
    <tabs type="primary" square :clickHandler="paneChanged">
      
      <tab-pane label="details" active>
        <div slot="label">
          User Details
        </div>
        <div class="row">
          <div class="col-lg-12">
            <EditProfileContactDetailsForm ref="details"/>  
          </div> 
        </div>  
      </tab-pane>
      <tab-pane label="account">
        <div slot="label">
          Account Details
        </div>
        <div class="row">
          <div class="col-lg-12">
            <EditUserSettingsAccountForm ref="account"/>
          </div>   
      </div>
      </tab-pane>
      <tab-pane label="billing">
        <div slot="label">
          Billing Details
        </div>
        <div class="row">
          <div class="col-lg-12">
            <EditUserSettingsBillingForm ref="billing"/>
          </div>
        </div>
      </tab-pane>
      <tab-pane label="users">
        <div slot="label">
          User Access
        </div>
        <div class="row">     
          <div class="col-lg-12">
            <Users ref="users"/>
          </div>
        </div>
      </tab-pane>
      <tab-pane label="authenticaion" >
        <div slot="label">
          Login & Credentials
        </div>
        <div class="row">     
          <div class="col-lg-12">
            <EditProfileChangePasswordForm ref="authenticaion" />
          </div>
        </div>
      </tab-pane>

     </tabs>
  </div>

</template>
<script>
import { TabPane, Tabs, Badge } from 'src/components';
import Users from '../Pages/UsersManagement.vue'
import EditProfileContactDetailsForm from './components/EditProfileContactDetailsForm.vue';
import EditProfileChangePasswordForm from './components/EditProfileChangePasswordForm.vue';
import EditUserSettingsBillingForm from './components/EditUserSettingsBillingForm.vue';
import EditUserSettingsAccountForm from './components/EditUserSettingsAccountForm.vue';
import countriesData from 'country-json/src/country-by-abbreviation.json';
import countriesByContinents from '../../json/country-continent.json';

export default {
  components: {
    TabPane,
    Tabs,
    Users,
    EditProfileContactDetailsForm,
    EditProfileChangePasswordForm,
    EditUserSettingsBillingForm,
    EditUserSettingsAccountForm
  },
  data() {
    return {
      pane: 'details',
      apiProfileObject: {
        user_first_name: null,
        user_last_name: null,
        user_phone_number: null,
        user_skype_id: null,
        user_email: null,
        user_role: null,
        user_uuid: null
      },
      apiAccountObject: {
        client_accounts_uuid: null,
        client_accounts_name: null,
      },
      apiBillingObject: {
        billing_entity_name: null,
        billing_registration_number: null,
        billing_vat_number: null,
        billing_tax_number: null,
        billing_email: null,
        billing_phone: null,
        billing_country: null,
        billing_country_iso: null,
        billing_city: null,
        billing_adress_one: null,
        billing_adress_two: null,
        billing_entity_wave_customer_id:null,
        billing_entity_valid_eu_vat:null

      },
      countries: countriesData,
      countriesContinents: countriesByContinents
    }
  },
  async mounted(){ 
    await this.fetch()
  },
  methods:{
    async paneChanged(pane){
      this.pane = pane
      await this.fetch()
    },
    async fetch(){
      await this.$refs[this.pane].fetch()
    }
    
  }
};
</script>
<style></style>
