<template>
    <card>
        <template slot="header">
            <h3 class="text-success"><mdicon name="check-circle-outline" size="38" class="tim-icons"/>&nbsp;&nbsp;&nbsp;Payment Success</h3>
        </template>
        <div id="card-success-wrapper">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <h4 class="text-success"><u>Your payment is processing.</u></h4>
                    <p>
                        
                        Your balance will be updated and an invoice will be available once completed.
                    </p>
                    <br>
                    <p class="text-white">
                        Have questions?
                        <base-button 
                            class="border-0 d-inline-block"
                            native-type="submit" 
                            type="primary"
                            simple
                            @click="toggleLiveAgent">
                            Get in touch
                        </base-button>
                    </p>
                    <hr/>
                    <div class="row">
                        <div class="col-lg-8">
                            <h6>Billing Information</h6>
                            <Details :billing="modalData" :isEdit="false"/>
                        </div>
                        <div class="col-lg-4">
                            
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <template slot="footer">
            <div class="row justify-content-center">
                <p class="text-success">Thank you for your payment</p>
            </div>
        </template>
    </card>
</template>

<script>
import Details from '../../Billing/Details.vue';
export default {
    components:{
        Details
    },
    props:[
        'creditCardPaymentId',
        'creditCardPaymentAmount',
        'modalData',
        'toggleLiveAgent'
    ],
    data(){
        return {
        }
    },
    methods:{
    }
}
</script>

<style>

</style>