<template>
    <span>
        {{handler()}}
    </span>
</template>

<script>
const numericDayToName = require('../../New/json/numericDayToName.json')
const numericMonthToName = require('../../New/json/numericMonthToName.json')
const monthByName = require('../../New/json/monthByName.json')

export default {
    props: ['row', 'type'],
    data() {
        return {
            valueString: "",
            numericMonthToName: numericMonthToName,
            daysByName: numericDayToName,
            monthByName: monthByName,
            cron: null
        }
    },
    methods: {
        specific(cron){
            if(cron.cron_list && this[cron.cron_list]){
                return this[cron.cron_list][cron.cron_value].label
            }

            return cron.cron_value
        },
        every(){
            return '--every--'
        },
        range(cron){
            
            if(cron.cron_list && this[cron.cron_list]){
                return `${this[cron.cron_list][cron.cron_start].label} - ${this[cron.cron_list][cron.cron_end].label}`
            }else{
                return `${cron.cron_start} - ${cron.cron_end}`
            }
        },
        month(){
            return this[this.cron.cron_tempo](this.cron)
        },
        week(){
            return this[this.cron.cron_tempo](this.cron)
        },
        repeat(){
            return this[this.row.campaign_recurring_type]()
        },
        schedule(){
          
            if(this.type == "day")
                return this.parseDateShort(this.row.campaign_date, this.type)
            if(this.type == "month")
                return this.parseDateShort(this.row.campaign_date, this.type)
            if(this.type == "hour"){
                if(this.row.campaign_hr) return this.row.campaign_hr.toString().padStart(2,0)
                else this.every()
            }

            if(this.type == "minute"){
                if(this.row.campaign_mn) return this.row.campaign_mn.toString().padStart(2,0)
                else this.every()
            }
                
        },
        handler() {
            if(this.type == "day") this.cron = this.row.crons[0]
            else if(this.type == "hour") this.cron = this.row.crons[1]
            else if(this.type == "minute") this.cron = this.row.crons[2]
            else if(this.type == "month") this.cron = this.row.crons[3]
            return this[this.row.campaign_type]()
        },
        parseDateShort(dateString, specific){
            if(!dateString) return
            const date = new Date(dateString)      
            const day = date.getDate()
            const month = date.getMonth() + 1
            const year = date.getYear() + 1900
            if(specific == 'day') return day
            if(specific == 'month') return this.numericMonthToName[month].label
            return `${year}-${month.toString().padStart(2,0)}-${day.toString().padStart(2,0)}`
        },
    }

}
</script>

<style>

</style>