<template>
  <div class="w-100">
    <div class="row">
      <div class="col-lg-12">
        <FilterView ref="filterVue" @filter="filtered" :loading="loading"/>
      </div>
    </div>
    <tabs type="primary" square :clickHandler="paneChanged">
      <tab-pane label="Dashboard">
        
        
        <div class="row">
          <div class="col-lg-12">
            <Chart :data="chartData" :groupBy="filter.group" :loading="loading"/>
          </div>
        </div>
        
        <div class="row">
          <div class="col-lg-12">
            
            <Tiles 
              :loading="loading" 
              :cities="cities"
              :platforms="platforms"
              :countries="countries"
              :total="total"
              :countryCodes="countryCodes"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <World :data="rows"/>
          </div>
        </div>
        
      </tab-pane>
      <tab-pane label="Statistics">
        <div class="row">
          <div class="col-lg-12">
            <SummeryTiles :totalClicks="total" :totalSent="totalSent" :totalCost="totalCost"/>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <Table 
              :rows="rows" 
              @groupBy="groupBy" 
              :loading="loading" 
              :total="rows.length" 
              :countryCodes="countryCodes"
              @sort="sortStatsTbl"
            />
          </div>
        </div>
      </tab-pane>
      <tab-pane label="Clicks">
        <HistoryTable 
          :perPage="historyPerPage"
          :rows="historyRows" 
          :length="historyLength" 
          :getHistoryCsvRows="getHistoryCsvRows"
          @pagination="pagination" 
          @changePerPage="changePerPage"
          
        />
      </tab-pane>
    </tabs>
  </div>
</template>

<script>

import SummeryTiles from './Componenets/SummeryTiles.vue'
import HistoryTable from './Componenets/HistoryTable.vue'
import Tiles from './Componenets/Tiles.vue'
import Chart from './Componenets/Chart.vue'
import FilterView from './Componenets/Filter.vue'
import Table from './Componenets/Tablee.vue'
import World from './Componenets/WorldMap.vue'
import countryByAbr from '../../../../json/iso-3166-2-country.json';
import { TabPane, Tabs } from 'src/components';

export default {
  components: {
    TabPane,
    Tabs,
    FilterView,
    Chart,
    Tiles,
    SummeryTiles,
    Table,
    HistoryTable,
    World
  },

  

  data(){
    return {
      countryCodes: countryByAbr,
      loading: false,
      chartData: [],
      historyRows: [],
      historyPage: 1,
      historyPerPage: 10,
      historyLength: 1,
      rows: [],
      countries: {},
      cities: {},
      platforms: {},
      total: 0,
      totalSent: 0,
      totalCost: 0,
      currentPane: 'dashboard',
      tiles: {
        cities: {
          labels: [],
        }
      },
      filter: {
        group: 'date'
      }
    }
  },
  created(){

  },
  methods: {
    sortStatsTbl({ column, prop, order }){
      
      let rows = [...this.rows]
      rows = rows.sort((a,b)=>{
        const sortValueA = a[prop];
        const sortValueB = b[prop];

        if (order === 'ascending') {
          return sortValueA > sortValueB ? 1 : -1;
        } else if (order === 'descending') {
          return sortValueA < sortValueB ? 1 : -1;
        } else {
          return 0;
        }
      })
      
      this.rows = rows
      
    },
    async getHistoryCsvRows(){
      
      const filter = {
        group: this.filter.group,
        dateRange: this.filter.dateRange,
        filters: this.filter.filters
      }
      
      this.loading = !this.loading
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      const res = await this.$http.post(`${api_endpoint}/campaigns/analytics/csv`, filter)
      this.loading = !this.loading
      if(res && res.status == 200){
        return res.data
      }
    },
    async changePerPage(perPage){
      this.historyPerPage = perPage
      //await this.loadHistory()
    },
    async pagination(page, sorted){
      this.historyPage = page
      //this.filter = {...this.filter, ...{ page: this.historyPage }}
      await this.loadHistory(sorted)
    },
    paneChanged(pane){
      this.currentPane = pane.toLowerCase()
      this.cities = {}
      this.platforms = {}
      this.countries = {}
      this.total = 0
      this.totalSent = 0
      this.totalCost = 0
      this.chartData = []
      this.rows = []
      //this.$refs['filterVue'].submit()
      //console.log(this.filter)
      //this.loadData()
    },
    async filtered(filter){      
      
      this.filter = { ...this.filter, ...filter }
      if(this.currentPane == 'clicks'){
        await this.loadHistory()
      } else {
        await this.loadData()
      }
    },
    async loadHistory(sorted){
      
      let sorting = {}
      if(sorted) sorting = { sort_column: sorted.prop, sort_order: sorted.order }
      this.loading = !this.loading
      this.filter = {...this.filter, ...{ page: this.historyPage, perPage: this.historyPerPage, ...sorting }}

      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      const res = await this.$http.post(`${api_endpoint}/campaigns/analytics/history`, this.filter)
      this.loading = !this.loading
      if(res && res.status == 200){
        this.historyRows = res.data.results
        this.historyLength = res.data.length
      }

    },
    async groupBy(groupBy){
      this.filter.group = groupBy
      await this.loadData()
    },
    reduceBy(data, field){

      const reduced = data.reduce((hash, item)=>{  
        
        if(!item[field]) return hash

        const sent = Number(item.sent) || 0
        const clicks = Number(item.clicks) || 0

        if(hash[item[field]]){
          hash[item[field]].sent += sent
          hash[item[field]].clicks += clicks
        }else{
          hash[item[field]] = {
            country: item.ccode,
            sent: sent,
            clicks: clicks
          }
        }

        return hash

      },{} )
      
      
      
      return reduced
    },
    sumObj(data,field){
      const reduced = data.reduce((total, item)=> total += Number(item[field]), 0)
      return reduced
    },
    reset(){
        this.rows = []
        this.chartData = []
        this.historyRows = []
        this.total = 0
        this.totalSent = 0
        this.totalCost = 0
        this.countries = {}
        
    },
    async loadData(){
      this.reset()
      const query = {...this.filter, ...{ groupBy: this.groupBy }}

      this.loading = !this.loading
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      //const res = await this.$http.post(`${api_endpoint}/campaigns/analytics/read`, query)
      const res = await this.$http.post(`${api_endpoint}/campaigns/analytics/${this.currentPane}`, query)
      this.loading = !this.loading

      if(res && res.status == 200){

        this.rows = res.data
        this.chartData = this.reduceBy(res.data, 'date')
        this.total = this.sumObj(res.data, 'clicks')
        this.totalSent = this.sumObj(res.data, 'sent')
        this.totalCost = this.sumObj(res.data, 'cost')

        this.countries = this.reduceBy(res.data, 'ccode')
        this.mapData = this.countries

        this.cities = this.reduceBy(res.data, 'city')
        
        

        this.platforms = {
          'Android': { clicks: this.sumObj(res.data, 'Android') },
          'iPhone': { clicks: this.sumObj(res.data, 'iPhone') },
          'Other': { clicks:  this.sumObj(res.data, 'otherPlatform') }
        }
      
        
      }
      
    }
  }
}
</script>

<style>

</style>
