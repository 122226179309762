<template>
    <card>
        <template slot="header">
            

            <div class="row">
                <div class="col-lg-10">
                    <h4 class="card-title">Statistics</h4>
                    <h5 class="card-category">Breakdown By Date, Campaign and contact Group</h5>
                </div>
                <div class="col-lg-2" id="rows-to-show">
                    <base-input
                    label="Rows to Show">
                        <el-select
                        @change="rowsPerPage()"
                        v-model="perPage"
                        class="select-primary"
                        name="Rows to show">
                            <el-option :label="10" :value="10" :key="10"></el-option>
                            <el-option :label="20" :value="20" :key="20"></el-option>
                            <el-option :label="30" :value="30" :key="30"></el-option>
                        </el-select>
                    </base-input>
                </div>
            </div>
            
        </template>
        <div class="d-flex justify-content-center">
            <base-pagination 
                type="info"
                :pageCount="Math.ceil(rows.length / perPage)" 
                :perPage="perPage"
                :total="rows.length"
                :value="value"
                :pagesToDisplay="pagesToDisplay"
                @input="pageChange">
            </base-pagination>
        </div>
        <div class="d-flex justify-content-left">
            <base-input
                style="width:50%"
                textLight
                v-model="tblSearch"
                label="Search Query"
                :error="/.+/.test(tblSearch) ? '' : 'Please type something to search'"
                :class="[{ 'has-success': /.+/.test(tblSearch) }, { 'has-danger': !/.+/.test(tblSearch) }]">
                
            </base-input>
        </div>
        
        <div id="camp-stats-tbl">
            <el-table
                @sort-change="onSortChange"
                @filter-change="onFilterChange"
                :data="parsedRows"
                style="width: 100%;margin-bottom: 20px;min-height:600px">
                <el-table-column :width="50">
                    <template slot-scope="scope">
                        <country-flag :country="scope.row.ccode" size='normal'/>
                    </template>
                </el-table-column>
                <el-table-column
                    :width="200"
                    prop="cname"
                    label="Country"
                    column-key="date"
                    :filters="filters.country"
                    :filter-method="filterMethod"
                    sortable="custom">
                    <template slot-scope="scope">
                        {{scope.row.cname}}
                    </template>
                </el-table-column>
                <el-table-column
                    :width="120"
                    prop="date"
                    label="Date"
                    sortable="custom"
                    column-key="date"
                    :filters="filters.dates"
                    :filter-method="filterMethod"
                >
                    <template slot-scope="scope">
                        {{scope.row.date}}
                    </template>
                </el-table-column>
                <el-table-column
                    :width="120"
                    prop="campaign"
                    label="Campaign ID"
                    :filters="filters.campaign"
                    :filter-method="filterMethod"
                    sortable="custom">
                    <template slot-scope="scope">
                        {{scope.row.campaign}}
                    </template>
                </el-table-column>
                <el-table-column
                    :width="240"
                    prop="groupName"
                    column-key="groupName"
                    :filters="filters.groupName"
                    :filter-method="filterMethod"
                    label="Contacts Group"
                    sortable="custom">
                    <template slot-scope="scope">
                        {{scope.row.groupName}}
                    </template>
                </el-table-column>
                <el-table-column
                    :width="120"
                    prop="sent"
                    label="Sent"
                    sortable="custom">
                    <template slot-scope="scope">
                        {{scope.row.sent}}
                    </template>
                </el-table-column>
                <el-table-column
                    :width="120"
                    prop="cost"
                    label="Spent"
                    sortable="custom">
                    <template slot-scope="scope">
                        {{scope.row.cost}}
                    </template>
                </el-table-column>
                <el-table-column
                    :width="120"
                    prop="clicks"
                    label="Clicks"
                    sortable="custom">
                    <template slot-scope="scope">
                        {{scope.row.clicks}}
                    </template>
                </el-table-column>
                <el-table-column
                    :width="220"
                    label="Click through rate (%)">
                    <template slot-scope="scope">
                        {{ getCtr(scope.row) }}%
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </card>
</template>

<script>
import {Table, TableColumn, Button, Pagination, Select, Option, Tag} from 'element-ui'
import { BasePagination } from 'src/components'
import CountryFlag from 'vue-country-flag'

export default {
    components: {
        CountryFlag,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Button.name]: Button,
        [Pagination.name]: Pagination,
        [Select.name]: Select,
        [Option.name]: Option,
        [Tag.name]: Tag,
        BasePagination,
    },
    props:{
        countryCodes:{ type: Object, default: () => {} },
        total: { type: Number, default: 0 },
        loading: { type: Boolean, default: false },
        rows: { type: Array, default: () => [] }
    },
    data() {
        return {
            parsedRows: [],
            value: 1,
            perPage: 10,
            pagesToDisplay: 10,
            tblSearch: "",
            sorted: {},
            filters: {
                dates: [],
                country: [],
                groupName: [],
                campaign: []
            }
        }
    },
    watch: {
        'rows': {
            handler: function(nv){

                this.setFilters([...nv])
                this.paginate([...nv])
                
            },
            deep: true
        },
        'tblSearch': {
            handler: function(nv) {
                this.search()
                //const terms = nv.split(' ').filter( item => item )
                //let results = this.rows
                //for(const term of terms){
                //    results = results.filter( row =>  Object.values(row).filter(col => col && col.toString().trim().toLowerCase().includes(term) ).length )
                //}
                //this.parsedRows = results
                
            },
            deep: true
        }
    },
    methods:{
        filterMethod(value, row, column){
            const property = column['property'];
            return row[property] === value;
        },
        setFilters(rows){
            
            this.filters.dates = Object.values(rows.reduce((acc, curr) => {
                acc[curr.date] = {
                    text: curr.date,
                    value: curr.date
                }

                return acc
            }, {}))

            this.filters.country = Object.values(rows.reduce((acc, curr) => {
                acc[curr.cname] = {
                    text: curr.cname,
                    value: curr.cname
                }

                return acc
            }, {}))

            this.filters.campaign = Object.values(rows.reduce((acc, curr) => {
                acc[curr.campaign] = {
                    text: curr.campaign,
                    value: curr.campaign
                }

                return acc
            }, {}))

            this.filters.groupName = Object.values(rows.reduce((acc, curr) => {
                acc[curr.groupName] = {
                    text: curr.groupName,
                    value: curr.groupName
                }

                return acc
            }, {}))

        },
        search(){
            const terms = this.tblSearch.split(' ').filter( item => item )
            let results = this.rows
            for(const term of terms){
                results = results.filter( row =>  Object.values(row).filter(col => col && col.toString().trim().toLowerCase().includes(term.toLowerCase()) ).length )
            }
            this.parsedRows = results
        },
        rowsPerPage(){
            this.paginate([...this.rows])
        },
        paginate(rows){
            
            if(this.sorted.order){
                const sorted = [...rows].sort((a,b)=>{
                    if(a[this.sorted.prop] > b[this.sorted.prop]) return -1
                    if(a[this.sorted.prop] < b[this.sorted.prop]) return 1
                    return 0
                })
            
                this.parsedRows = sorted.splice( ( this.value - 1 ) * this.perPage, this.perPage )
            }else{
                this.parsedRows = rows.splice( ( this.value - 1 ) * this.perPage, this.perPage )
            }
            
        },
        pageChange(page){
            this.value = page
            this.paginate([...this.rows])
        },
        getCtr(rowData){
            const ratio = rowData.sent > 0 && rowData.clicks  ? (rowData.clicks / rowData.sent) * 100 : 100
            return ratio.toFixed(2)
        },
        onSortChange({ column, prop, order }){
            if (!prop || !order) return;
            this.$emit('sort', { column, prop, order })
        },
        onFilterChange(){},
    }
}
</script>

<style>
 #camp-stats-tbl table{
    width: 100% !important;
 }

 #rows-to-show .el-input__inner{
    color: white !important;
 }
</style>