<template>
    <card>
        <template slot="header">
            <div class="row">
                <div class="col-lg-10">
                    <h4 class="card-title">History</h4>
                    <h5 class="card-category">Of clicks in period</h5>
                </div>
            </div>
        </template>
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-center">
                    
                    <base-pagination 
                        type="info"
                        :pageCount="Math.ceil(length / this.perPage)" 
                        :perPage="perPage"
                        :total="rows.length"
                        :value="value"
                        :pagesToDisplay="pagesToDisplay"
                        @input="pageChange">
                    </base-pagination>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h4>Found {{length}} records</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-10">
                <CsvDownload :rows="rows" :getRows="getCsvRows" :headers="csvHeaders" :fileName="csvFileName"/>
            </div>
            <div class="col-2">
                <div id="clicks-rows-to-show">
                    <base-input
                        label="Rows to Show">
                        <el-select
                            @change="rowsToShow"
                            v-model="perPageLocal"
                            class="select-primary"
                            name="Rows to show">
                            <el-option class="select-primary" label="10" :value="10" :key="10"></el-option>
                            <el-option class="select-primary" label="50" :value="50" :key="50"></el-option>
                            <el-option class="select-primary" label="100" :value="100" :key="100"></el-option>
                            <el-option class="select-primary" label="250" :value="250" :key="250"></el-option>
                        </el-select>
                    </base-input>
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-12">
                <el-table
                    @sort-change="onSortChange"
                    @filter-change="onFilterChange"
                    :data="paginatedRows"
                    style="width: 100%; margin-bottom: 20px;min-height:500px;">
                        <!--
                        <el-table-column sortable :width="70" prop="id" label="Id"/>
                        -->
                        <el-table-column 
                            sortable 
                            :width="70" 
                            prop="runner_client_campaign_id" 
                            label="Id"
                            :filters="filters.campaign"
                            :filter-method="filterMethod"
                        />
                        <el-table-column sortable prop="runner_click_id" label="Click (Message) Id"/>
                        <el-table-column 
                            sortable 
                            prop="runner_date" 
                            label="Date"
                            :filters="filters.dates"
                            :filter-method="filterMethod"
                            />
                        <el-table-column 
                            sortable 
                            prop="runner_country_name" 
                            label="Country"
                            :filters="filters.country"
                            :filter-method="filterMethod"
                        />
                        <el-table-column 
                            sortable 
                            prop="runner_city" 
                            label="City"
                            :filters="filters.city"
                            :filter-method="filterMethod">
                            <template slot-scope="scope">
                                {{scope.row.runner_city ? scope.row.runner_city : 'N/A'}}
                            </template>
                        </el-table-column>
                        <el-table-column 
                            sortable 
                            prop="runner_platform" 
                            label="Device"
                            :filters="filters.device"
                            :filter-method="filterMethod"
                        />
                        <el-table-column sortable prop="runner_sender" label="Sender"/>
                        <el-table-column sortable :width="120" prop="runner_msisdn" label="Receiver"/>
                        <el-table-column 
                            sortable 
                            prop="runner_group" 
                            label="Contact Group"
                            :filters="filters.groupName"
                            :filter-method="filterMethod"
                        />
                        <!--
                        <el-table-column sortable prop="runner_custom1" label="Contact Custom1"/>
                        <el-table-column sortable prop="runner_custom2" label="Contact Custom2"/>
                        <el-table-column sortable prop="runner_custom3" label="Contact Custom3"/>
                        <el-table-column sortable prop="runner_custom4" label="Contact Custom4"/>
                        <el-table-column sortable prop="runner_custom5" label="Contact Custom5"/>
                        -->
                </el-table>
            </div>
        </div>
    </card>
</template>

<script>
import {Table, TableColumn, Select, Option} from 'element-ui'
import { BasePagination } from '@/components';
import CsvDownload from '../../../Common/CsvDownload.vue'
export default {
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        BasePagination,
        CsvDownload
    },
    props:{
        length: {
            type: Number,
            default: 0
        },
        rows: {
            type: Array,
            default: () => []
        },
        perPage: {
            type: Number,
            default: 10
        },
        getHistoryCsvRows: {
            type: Function
        }
    },
    watch: {
        'rows': {
            handler: function(nv){
                this.setFilters()
                this.paginate()
            },
            deep: true
        },
    },
    data() {
        return {
            filters: {
                dates: [],
                country: [],
                groupName: [],
                campaign: [],
                device: [],
                city: [],
            },
            perPageLocal: 10,
            csvRows: [],
            paginatedRows: [],
            value: 1,
            //perPage: 10,
            pagesToDisplay: 10,
            csvFileName: 'clicks',
            sorted: {},
            csvHeaders: [
                'Campagin id', 'Click id', 'Date', 'Timestamp' , 
                'Country', 'Platform', 'Mobile Operator', 'Mcc', 'Mnc',
                'Sender', 'Receiver', 'Group', 
                'Custom1', 'Custom2', 'Custom3', 'Custom4', 'Custom5'
            ]
        }
    },
    methods: {
        filterMethod(value, row, column){
            const property = column['property'];
            return row[property] === value;
        },
        setFilters(){
            
             this.filters.dates = Object.values(this.rows.reduce((acc, curr) => {
                acc[curr.runner_date] = {
                    text: curr.runner_date,
                    value: curr.runner_date
                }

                return acc
            }, {}))

            this.filters.country = Object.values(this.rows.reduce((acc, curr) => {
                acc[curr.runner_country_name] = {
                    text: curr.runner_country_name,
                    value: curr.runner_country_name
                }

                return acc
            }, {}))

            this.filters.campaign = Object.values(this.rows.reduce((acc, curr) => {
                acc[curr.runner_client_campaign_id] = {
                    text: curr.runner_client_campaign_id,
                    value: curr.runner_client_campaign_id
                }

                return acc
            }, {}))

            this.filters.groupName = Object.values(this.rows.reduce((acc, curr) => {
                acc[curr.runner_group] = {
                    text: curr.runner_group,
                    value: curr.runner_group
                }

                return acc
            }, {}))

            this.filters.device = Object.values(this.rows.reduce((acc, curr) => {
                acc[curr.runner_platform] = {
                    text: curr.runner_platform,
                    value: curr.runner_platform
                }

                return acc
            }, {}))

            this.filters.city = Object.values(this.rows.reduce((acc, curr) => {
                acc[curr.runner_city] = {
                    text: curr.runner_city || 'N/A',
                    value: curr.runner_city
                }

                return acc
            }, {}))
        },
        getCsvRows(){
            return this.getHistoryCsvRows()
        },
        rowsToShow(perPage){
            this.$emit('changePerPage', perPage)
            this.pageChange(1)
        },
        paginate(){
            this.paginatedRows = [...this.rows]
            //this.csvRows = [...this.rows].map(row => {
            //    return {
            //        redirect_campaign_id: row.redirect_campaign_id,
            //        redirect_click_id: row.redirect_click_id,
            //        redirect_date: row.redirect_date,
            //        redirect_timestamp: row.createdAt,
            //        redirect_country_name: row.redirect_country_name,
            //        redirect_city: row.redirect_city,
            //        redirect_platform: row.redirect_platform,
            //        redirect_sender: row.redirect_sender,
            //        redirect_receiver: row.redirect_receiver,
            //        redirect_group: row.redirect_group,
            //        redirect_custom1: row.redirect_custom1,
            //        redirect_custom2: row.redirect_custom2,
            //        redirect_custom3: row.redirect_custom3,
            //        redirect_custom4: row.redirect_custom4,
            //        redirect_custom5: row.redirect_custom5
            //    }
            //})
            
            //this.paginatedRows = [...this.rows].slice( ( this.value - 1 )  * this.perPage, this.value * this.perPage)
        },
        onSortChange(sorted){
            if(sorted.order){
                this.sorted = sorted
                this.pageChange(this.value)
            }
            //this.paginate([...this.rows])
        },
        onFilterChange(filter){},
        pageChange(page){
            this.value = page
            this.$emit('pagination', page, this.sorted)
        }
    }
}
</script>

<style>

#clicks-rows-to-show .el-input__inner {
    color: white !important;
}
</style>