<template>
    
    <div class="stages-wrapper">
        <div class="row h-100 ml-0 mr-0">
            <div class="col-lg-6 d-none d-sm-none d-md-none d-lg-block" style="border-right:1px solid">
                <div class="h-100 d-flex align-items-center justify-content-end p-4">
                    <div class="d-block">
                        <h3>Tell us about your organization</h3>
                        <p class="text-info">We'll use this info to customize your experience</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="h-100 right-column">
                    <div class="h-100 d-flex align-items-center justify-content-start p-4">
                        <div class="d-block w-100">
                            <div v-if="loading" class="local-spinner">
                                <i class="fa fa-spinner fa-spin"></i>
                            </div>

                            <div v-else>
                                <h3>Business Details</h3>
                                <br/>
                                <Form @submit="submit"/>
                                
                                <router-link 
                                    class="btn btn-muted btn-simple" 
                                    to="/about/action" tag="button">  
                                    Skip for now
                                </router-link>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

             
        </div>
    </div>
    
</template>

<script>
import Form from './Organization/Form.vue'
export default {
    components:{
        Form
    },
    data() {
        return {
            loading: false
        }
    },
    mounted(){
    }, 
    methods: {
        async submit(){
            this.$router.push('/about/action')
        }
    }
}
</script>

<style>
    
    .right-column {
        max-width:430px;
    } 

    .local-spinner {
        color: white;
        font-size: 32px;
    }

    @media (max-width: 992px) {
        .stages-wrapper .right-column{
            margin-left: auto;
            margin-right: auto;
        }
    }
    
</style>