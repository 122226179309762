<template>
  <div style="min-height: 200px">
    <div class="row">
      <div class="col-lg-12">
          <h3 style="margin:0"><u>Setup your web site link</u></h3>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        
        <collapse :multiple-active="false" >
          <collapse-item 
            id="external"
            @collapse-click="collapseClick"
            title="Setup your media link (web page/external tracking platform)">
            <External ref="external"/>
          </collapse-item>
          <collapse-item 
            id="internal"
            @collapse-click="collapseClick"
            title="Choose a template">
            <Internal ref="internal" />
          </collapse-item>
        </collapse>

      </div>
    </div>
<!--
    <Modal ref="modal" :type="type" @done="done" />
-->
  </div>
</template>

<script>
import { 
  Collapse, 
  CollapseItem 
} from 'src/components';
import External from '../Tracking/External.vue'
import Internal from '../Tracking/Internal.vue'
//import Modal from '../Tracking/Modal.vue'

export default {
  components:{
    Collapse,
    CollapseItem,
    External,
    Internal,
    //Modal
  },
  data() {
    return  {
      type: null,
      show: false,
      data: null
    }
  },
  methods: {
    done(form){
      this.data = form
    },
    toggle(type){
      this.type = type
      this.$refs['modal'].toggle()
    },
    collapseClick(id){
      this.type=id
    },
    async validate() {
      if(!this.type) return 
      const data = await this.$refs[this.type].validate()
      if(data){
        this.$emit('on-validated', true, { tracking: data } );
        return true
      }
      
    }
  }
}
</script>

<style>

</style>