<template>
    <card type="chart" style="padding:10px">
        <template slot="header">
            <h3 class="card-title">{{tabName}} SMS Campaigns</h3>
            <p class="card-category">list of {{tabName}} campaigns</p>
        </template>
        
        <hr style="background: #ffffff36">
        
        <i v-if="loading" class="fa fa-spinner fa-spin" style="color:white"></i>
        <div v-else>
            <el-table
                id="campaigns-table"
                @sort-change="onSortChange"
                @filter-change="onFilterChange"
                :data="paginatedRows"
                style="width: 100%;margin-bottom: 20px;min-height:600px">
                <el-table-column
                    :width="25"
                    prop="campaign_id"
                    label="ID"
                    sortable>
                    <template slot-scope="scope">
                        {{scope.row.campaign_id}}
                    </template>
                </el-table-column>
                <el-table-column type="expand">
                    <template slot-scope="scope">

                        <MoreDetails 
                            :dataObject="
                                {
                                ...scope.row, 
                                ...{ rowIndex: scope.$index } 
                                } 
                                " 
                            :action="action" 
                            :loading="loading"
                        />
                        
                    </template>
                </el-table-column>
                <el-table-column
                    :width="200"
                    prop="campaign_name"
                    label="Name"
                    sortable>
                    <template slot-scope="scope">
                        <span v-if="scope.row.campaign_name">
                            {{scope.row.campaign_name}}
                        </span>
                        <span v-else>
                            -
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    sortable
                    :filters="[
                        { text: 'scheduled', value: 'schedule' },
                        { text: 'repeating', value: 'repeat' }
                    ]"
                    column-key="campaign_type"
                    prop="campaign_type"
                    label="Scheduled On"
                    width="150">            
                    <template slot-scope="scope">
                        <div v-if="scope.row.campaign_type == 'schedule'">
                            <el-tooltip
                                content="This campaign will run on a specific date"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                                <i class="fas fa-calendar-alt text-info"></i>  
                            </el-tooltip>
                            &nbsp; {{  parseDateShort(scope.row.campaign_date) }}
                        </div>
                        <div v-else>
                            <el-tooltip
                                content="This campaign is recurring"
                                effect="light"
                                :open-delay="300"
                                placement="top">
                                <i class="fa fa-redo text-info"></i>
                            </el-tooltip>
                            &nbsp; Reccuring {{ scope.row.campaign_recurring_type }}ly
                        </div>
                        
                        
                    </template>
                </el-table-column>
                <el-table-column
                    sortable
                    prop="campaign_tz"
                    column-key="campaign_tz"
                    label="Time Zone"
                    :filters="timeZones"
                    :width="100">
                    <template slot-scope="scope">
                        <i class="fa fa-globe" aria-hidden="true"></i> &nbsp; {{scope.row.campaign_tz}}
                    </template>
                </el-table-column>
                <el-table-column
                    :width="100"
                    prop="campaign_to_send"
                    label="Total Contacts"
                    sortable>
                </el-table-column>
                <el-table-column
                    sortable
                    prop="campaign_date"
                    label="Day"
                    width="100">
                        <template slot-scope="scope">
                            <Dater :row="scope.row" type='day'/>
                        </template>
                </el-table-column>
                <el-table-column
                    sortable
                    prop="campaign_date"
                    label="Month"
                    width="100">
                        <template slot-scope="scope">
                            <Dater :row="scope.row" type='month'/>
                        </template>
                </el-table-column>        
                <el-table-column
                    prop="campaign_hr"
                    label="Hour"
                    :width="80">
                    <template slot-scope="scope">
                        <Dater :row="scope.row" type='hour'/>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="campaign_mn"
                    label="Minute"
                    :width="70">
                    <template slot-scope="scope">
                        <!--
                        {{scope.row.campaign_mn && scope.row.campaign_mn.toString().padStart(2,0)}}
                        -->
                        <Dater :row="scope.row" type='minute'/>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="campaign_broken_notification"
                    label=""
                    :width="90">
                    <template slot-scope="scope">
                        <el-tooltip
                            v-if="scope.row.campaign_broken_notification"
                            :content="`Campaign is broken alert ${scope.row.campaign_broken_notification ? 'was sent' : 'was not sent'}`"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                            <i :class="scope.row.campaign_broken_notification ? 'text-warning' : 'text-info'" class="fa fa-bullhorn" aria-hidden="true"></i>
                        </el-tooltip>
                        &nbsp;
                        <el-tooltip
                            :content="`Accounting key is ${scope.row.campaign_api_key_active ? 'active' : 'not active' }`"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                            <i :class="scope.row.campaign_api_key_active ? 'text-success' : 'text-danger'" class="fa fa-key" aria-hidden="true"></i>
                        </el-tooltip>
                        &nbsp;
                        <el-tooltip
                            :content="`Account is ${scope.row.campaign_out_of_balance ? 'out of balance' : 'is topped up!' }`"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                            <i :class="scope.row.campaign_out_of_balance ? 'text-warning' : 'text-success'" class="fa fa-credit-card" aria-hidden="true"></i>
                        </el-tooltip>
                        &nbsp;
                        <el-tooltip
                            :content="`Campaign ${scope.row.campaign_has_contacts ? 'has contacts' : 'does not have contacts' }`"
                            effect="light"
                            :open-delay="300"
                            placement="top">
                            <i :class="scope.row.campaign_has_contacts ? 'text-success' : 'text-warning'" class="fa fa-users" aria-hidden="true"></i>
                        </el-tooltip>
                    </template>
                </el-table-column>
                
                <!--
                <el-table-column
                    :width="100"
                    prop="campaign_total_sent"
                    label="Total Sent">
                    <template slot-scope="scope">
                        {{scope.row.campaign_total_sent || 0}}
                    </template>
                </el-table-column>
                -->
            </el-table>

            <div class="d-flex justify-content-center">
                <base-pagination 
                    type="info"
                    :pageCount="pageCount" 
                    :perPage="perPage"
                    :total="total"
                    :value="currentPage"
                    :pagesToDisplay="pagesToDisplay"
                    @input="pageChange">
                    
                </base-pagination>
            </div>    
        </div>
    </card>
</template>

<script>
import {Table, TableColumn, Button, Pagination, Select, Option, Tag} from 'element-ui'
import { BasePagination } from 'src/components'
import MoreDetails from './MoreDetailss.vue'
import Dater from './Dater.vue'

const numericDayToName = require('../../New/json/numericDayToName.json')
const numericMonthToName = require('../../New/json/numericMonthToName.json')
const timeZones = require('../../../../../json/timeZones.json')

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Button.name]: Button,
        [Pagination.name]: Pagination,
        [Select.name]: Select,
        [Option.name]: Option,
        [Tag.name]: Tag,
        BasePagination,
        MoreDetails,
        Dater
    },
    props: {
        action:  { type: Function },
        tabName: { type: String },
        rows: { type: Array, default: () => { return [] } },
        loading: { type: Boolean, default: false },
        pageCount: { type: Number, default: 1 },
        perPage: { type: Number, default: 10 },
        total: { type: Number, default: 1 }
    },
    data() {
        return {
            paginatedRows: [],
            scheduledFilters: [],
            timeZones: timeZones,
            currentPage: 1,
            pagesToDisplay: 10,
            numericMonthToName: numericMonthToName,
            numericDayToName: numericDayToName,
            prevTab: 'Active',
            activeFilters: {}
        }
    },
    watch: {
        'rows': {
            handler: function(nv){
                const rows = [...nv]
                this.getRows(rows)
            },
            deep: true
        },
    },
    methods: {
        pageChange(page){
            this.currentPage = page
            this.getRows(this.rows)
        },
        onSortChange(){},
        getRows(rowss){
            let rows = [...rowss]
            for(const filterKey of Object.keys(this.activeFilters).filter(key => this.activeFilters[key].length)){
                rows = rows.filter( row => this.activeFilters[filterKey].includes(row[filterKey]) )
            }

            this.paginatedRows = rows.slice( ( this.currentPage - 1 )  * this.perPage, this.currentPage * this.perPage)
        },
        onFilterChange(filters){
            this.activeFilters = { ...filters }
            this.getRows(this.rows)
        },
        parseReccuringMonth(numericMonth){
            return this.numericMonthToName[`${numericMonth}`].label
        },
        parseReccuringDay(data){
            //console.log(data)
           return this.numericDayToName[data.campaign_day_of_week - 1 ].label
        },
        parseDateShort(dateString, specific){
            if(!dateString) return
            const date = new Date(dateString)      
            const day = date.getDate()
            const month = date.getMonth() + 1
            const year = date.getYear() + 1900
            if(specific == 'day') return day
            if(specific == 'month') return this.numericMonthToName[month].label
            return `${year}-${month.toString().padStart(2,0)}-${day.toString().padStart(2,0)}`
        },
    }
}
</script>

<style>
    #campaigns-table .el-table__expanded-cell {
        padding: 50px !important;
    }
</style>