<template>
     <div class="card flex-row">
        <div class="m-0 d-inline-block pl-3 pt-2">
            <base-radio 
                style="display:block !important"
                :disabled="disabled"
                name="newProforma" 
                :value="selected"
                @input="(value) => $emit('input', value)">
            </base-radio>
        </div>
        
        <div class="card-body">
            <h5 class="card-title p-3">
                <h6>Continue with new payment</h6>
            </h5>
        </div>
        <div class="card-img-right pt-3">
        </div>
    </div>
</template>

<script>
import { BaseRadio } from 'src/components/index'; 
export default {
    components:{
        BaseRadio
    },
    props: {
        selected: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        } 
    },
    data(){
        return {
            loading: false
        }
    },
    methods:{
        
    }
}
</script>

<style>

</style>