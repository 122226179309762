<template>
  
    <div class="row">
        <div class="col-lg-12">
            <card type="chart" style="padding:10px">
                <template slot="header">
                    <h5 slot="header" class="title">User access</h5>
                </template>
                
                <el-table :data="tableData">
                    <el-table-column
                    width="250"
                    label="EMAIL"
                    prop="user_email">
                    </el-table-column>
                    <el-table-column
                    label="FIRST NAME"
                    prop="user_first_name">
                    </el-table-column>
                    
                    <el-table-column
                    label="LAST NAME"
                    prop="user_last_name">
                    </el-table-column>
                    <!--
                    <el-table-column
                    label="ROLE"
                    width="150"
                    prop="user_role">
                    </el-table-column>
                    <el-table-column
                    label="PHONE NUMBER"
                    prop="user_phone_number">
                    </el-table-column>
                    
                    <el-table-column
                    label="SKYPE"
                    prop="user_skype_id">
                    </el-table-column>
                    <el-table-column
                    label="EMAIL CONFIRMED"
                    prop="user_confirm_registration_confirmed">
                        <template slot-scope="scope">
                            
                            <mdicon v-if="scope.row.user_confirm_registration_confirmed" style="font-color:white" 
                            name="account-check" size="32" />
                            <mdicon v-if="!scope.row.user_confirm_registration_confirmed" style="font-color:white" 
                            name="account-alert" size="32" />
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="TERMS CONFIRMED"
                    prop="user_confirm_terms">
                        <template slot-scope="scope">
                            <mdicon v-if="scope.row.user_confirm_terms" style="font-color:white" 
                            name="file-check-outline" size="32" />
                            <mdicon v-if="!scope.row.user_confirm_terms" style="font-color:white" 
                            name="file-alert-outline" size="32" />
                        </template>
                    </el-table-column>
                    -->
                    <el-table-column
                    label="NOTIFICATIONS"
                    prop="notifications_enabled">
                        <template slot-scope="scope">
                            <base-switch
                                @input="toggleGlobalNotifications(scope.row)"
                                v-model="scope.row.notifications_enabled"
                                type="primary"
                                on-text="ON"
                                off-text="OFF"
                                ></base-switch>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="OPTIONS"
                    align="right">            
                    <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="info"
                        @click="showUserModal(scope.row)">View</el-button>
                        
                    </template>
                    <modal id="user-more-details" :show="modals.showUserCardModal" >
                        <user-card
                        
                        :UserData="modalData"
                        @showUserCardModal="(modals.showUserCardModal = !modals.showUserCardModal)"
                        ></user-card>
                    </modal>
                </el-table-column>
                </el-table>
                <template slot="footer">
                    <modal :show="modals.showUserInvite">
                        <InviteUser
                        @showUserInvite="(modals.showUserInvite = !modals.showUserInvite)"
                        @showUserInviteUserAdded="showUserInviteUserAdded">
                        </InviteUser>
                    </modal>
                    <base-button simple @click="invite()" class="mt-3" native-type="submit" type="primary">
                        Invite
                    </base-button>
                </template>
            </card>
        </div>
        

    </div>
  
  
</template>

<script>
import {Table, TableColumn, Button} from 'element-ui'
import { BaseSwitch } from 'src/components/index';
import { Modal } from '@/components';
import  UserCard  from './UserManagement/UserCard.vue'
import  InviteUser  from './UserManagement/InviteUser.vue'

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Button.name]: Button,
        BaseSwitch,
        Modal,
        UserCard,
        InviteUser
    },
    methods: {
        async fetch(){
            this.submitInProccess = !this.submitInProccess
            const res = await this.$http.get(`${process.env.VUE_APP_API_ENDPOINT}/users/read/all`)
            this.submitInProccess = !this.submitInProccess
            if(res && res.status == 200){
        
                let notifications = res.data.notifications
                let accounts = res.data.accounts
        
                accounts.forEach(account=>{
                    if(notifications){
                        notifications.find(item=>{       
                            if(item.notifications_users_email == account.user_email){
                                let atLeastOneNotificationActive = 
                                    item.notifications_users_low_balance || item.notifications_users_price_changes ||
                                    item.notifications_users_product || item.notifications_users_maintenance
                                account.notifications_enabled = atLeastOneNotificationActive
                            }
                        })
                    }
                })
                this.tableData = accounts ? accounts : this.tableData

            }
        },
        showUserInviteUserAdded(userData){
            console.log(userData)
            this.tableData.push({ 
                "user_subscribe_by": "INVITE", 
                "user_email": userData, 
                "user_first_name": null, 
                "user_last_name": null, 
                "user_role": "sms-client", 
                "user_phone_number": null, 
                "user_skype_id": null, 
                "notifications_enabled": 1
            })
        },
        invite(){
            this.modals.showUserInvite = !this.modals.showUserInvite
        },
        async toggleGlobalNotifications(rowData){
            //console.log(rowData)
            let obj = {
                notifications_users_uuid: this.user.user_account_uuid,
                notifications_users_email: rowData.user_email,
                notifications_users_low_balance: rowData.notifications_enabled,
                notifications_users_price_changes: rowData.notifications_enabled,
                notifications_users_product: rowData.notifications_enabled,
                notifications_users_maintenance: rowData.notifications_enabled
            }
            const res = await this.$http.post(process.env.VUE_APP_API_ENDPOINT + '/notifications/api/upsert', [obj])
            if(res && res.status == 200){
                //this.$toast.success('done')
            }
            //Promise.all([
            //        this.$http.post(process.env.VUE_APP_API_ENDPOINT + '/notifications/upsert', [obj]),
            ////]).then(([res1])=>{
            //]).then(()=>{
            //    //console.log(res1.data)
            //})
        },
        showUserModal(rowData){
            this.modals.showUserCardModal = !this.modals.showUserCardModal
            this.modalData = rowData
        }
    },
    data(){
        return {
            submitInProccess: false,
            user: this.$store.getters.user,
            modalData: {
                user_first_name: null,
                user_last_name: null,
                user_role: null,
                user_subscribe_by: null,
                notifications_enabled: null,
                createdAt: null,
                updatedAt: null,
                user_phone_number: null,
                user_skype_id: null,
                user_confirm_terms: false
            },
            modals: { 
                showUserCardModal: false,
                showUserInvite: false
            },
            tableData: []
        }
    }
}
</script>

<style>
    #user-more-details .modal-body {
        padding: 0 !important;
    }
</style>