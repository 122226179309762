<template>
  <div>
    <div class="row justify-content-center">
      <h3 class="info-text">
        Amount To Add
      </h3>
    </div>
    <p class="text-white">
        Current Single Payment Limit: <u><b>{{wizardModel.billing_stripe_card_limit}}</b></u> €
        <base-button 
          class="border-0 d-inline-block"
          native-type="submit" 
          type="primary"
          simple
          @click="toggleLiveAgent">
          <u>contact sales to increase</u>
        </base-button>
    </p>
    
    
    <div class="row">
      <div class="col-lg-12">  
        <card type="primary" :class="['pending-payments',{ 'payment-border-success': intentId == 'current' }]" >
            <template slot="header">
              <base-radio 
                :disabled="items.length >= 5"
                class="d-inline-block"
                name="current" 
                v-model="intentId">
                New payment 
              </base-radio>             
              <hr>
            </template>    
            
            <div class="row justify-content-center">
              <div class="col-lg-6">
                <ValidationObserver ref="observer">
                  <form ref="set-amount-form">
                    <ValidationProvider
                      name="amount"
                      :rules="conditionalRequired"
                      v-slot="{ passed, failed, errors }"
                    >
                      <base-input
                        v-model="model.amount"
                        :disabled="items.length >= 5 ||  intentId != 'current'"
                        label="Amount"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed && intentId == 'current'}, { 'has-danger': failed }]">
                      </base-input>
                    </ValidationProvider>
                  </form>
                </ValidationObserver>
              </div>
            </div>
            <template slot="footer">
              <div class="row">
                  <div class="col-lg-6 ">
                    <h6 class="text-warning" v-if="items.length >= 5">Please cancel or complete one of the pending Incomplete Payments.</h6>
                    <h6 v-else class="title pull-right text-info">Continue with current payment</h6>
                  </div>
                  <div class="col-lg-6 ">
                      <span class="pull-left">
                        {{Math.floor(Date.now() / 1000) | formatDate}}
                      </span>
                  </div>
              </div>
              
            </template>
        </card>
      </div>
    </div>
    
    <div class="row justify-content-center" v-if="items.length">
      <h4 class="text-info font-weight-bold p-4">{{items.length}} Pending Incomplete Payments</h4>
    </div>
    <div class="row" v-for="(item, index) in items" :key="index">
        <div class="col-lg-12">
          <card :class="['pending-payments',{ 'payment-border-success': `${index}`==`${intentId}` }]">
              <template slot="header">  
                  <base-radio 
                      class="d-inline-block"
                      :name="index.toString()" 
                      v-model="intentId"
                      @input="setIntent">
                        {{item.id.split(/\_/)[1]}}
                  </base-radio>         
                  <div class="d-inline-block pull-right">
                      <base-button 
                          type="danger" 
                          class="pt-0 pb-0 pl-1 pr-1 d-inline-block" 
                          size="sm" 
                          simple
                          :loading="loading"
                          @click="cacnelPayment(item, index);">
                            <mdicon name="delete" size="18"></mdicon>
                      </base-button>
                  </div>
                  <hr>
              </template>
              <div class="row justify-content-center">
               
                  <h3 class="font-weight-light"><u>{{ item.amount / 100 }}<small>&euro;</small></u></h3>
              </div>
              <template slot="footer">
                  <div class="row">
                      <div class="col-lg-6 ">
                          <h6 class="title pull-right text-warning">{{item.status.split('\_').join(" ")}}</h6>
                      </div>
                      <div class="col-lg-6 ">
                          <span class="pull-left">{{item.created | formatDate}}</span>
                      </div>
                  </div>
              </template>
          </card>
          
      </div>
    </div>
  </div>
</template>
<script>
import { BaseRadio } from 'src/components/index';
import { extend, validate } from "vee-validate";
import { integer, required, between } from 'vee-validate/dist/rules'
import { EventBus } from '../../../plugins/eventBus';

extend('required', required);
extend('integer', integer)
extend('between', between)
extend('integer', {
  message:`Please use round number`
});

const euVatByCountry = require('../../../json/eu_vat_by_country.json')

export default {
  components: {
    BaseRadio
  },
  props: {
    wizardModel: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      items: [],
      intentId: 'current',
      completeIntentAmount: null,
      model: {
        amount: null
      }
    };
  },
  computed: {
    conditionalRequired() {
      return this.intentId == 'current' ? `required|integer|between:10,${this.wizardModel.billing_stripe_card_limit}` : '';
    },
  },
  filters: {
    formatDate(value){
        if (!value) return '';
        const date = new Date(value * 1000); // Convert from seconds to milliseconds
        return new Intl.DateTimeFormat('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
      }).format(date);
    }
  },
  methods: {
    toggleLiveAgent(){
      EventBus.$emit('show-agent');
    },
    setIntent(intentIdx){
      const { amount } = this.items[intentIdx]
      this.completeIntentAmount = amount / 100
    },
    async reset(){
    
      this.wizardModel.amount=null
      this.model = {
        amount: null
      }
    },
    toggleLoading(){
      this.loading = !this.loading
      this.$emit('loading', this.loading)
    },
    async cacnelPayment(intent, index){
      this.toggleLoading()
      const res = await this.$http.delete(`${process.env.VUE_APP_API_ENDPOINT}/billing/stripe/payment/intent/delete/${intent.id}` )
      this.toggleLoading()

      if(res && res.status == 200){                
          const { status } = res.data
          if(status == 'canceled'){
            this.items.splice(index, 1)
            this.intentId = 'current'
          }
              
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async createIntent(){
      try{

      
        //this.toggleLoading()

        const intentData = { 
          ...this.wizardModel, 
          ...this.model, 
          ...{ 
              currency: this.$store.getters.account.client_account_currency 
            } 
          }
        console.log(intentData)
        return {}

        const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/billing/stripe/payment/intent/create`, intentData)
        this.toggleLoading()

        if(res && res.status == 200){
          return res.data
        }
      }catch(e){
        this.toggleLoading()
      }
      
    },
    isEuTaxable () {
      const { billing_entity_country_code:billingCcode } = this.wizardModel
      return billingCcode in  euVatByCountry.rates
    },
    isTaxAble(){
      const { billing_entity_country_code:billingCcode, billing_entity_valid_eu_vat:isValidEuVat } = this.wizardModel
      return billingCcode == 'CY' || !isValidEuVat
    },
    async validate() { 

      try{

        const isValidForm = await this.$refs.observer.validate()
        if(!isValidForm) return false
        
        let intent, amount, vat_rate, tax
        
        if(this.intentId == 'current'){
          amount = this.model.amount
          //const { id, client_secret } = await this.createIntent()
          //intent = { id, client_secret }
          intent = {  }
        }else{
          //this.model.amount = null
          
          //amount = this.completeIntentAmount
          const { id, client_secret, metadata } = this.items[this.intentId]
          intent = { id, client_secret, metadata }
          if("amount" in  metadata) 
            amount = metadata.amount
        }

        const { billing_entity_country_code:billingCcode } = this.wizardModel

        if(this.isTaxAble() && this.isEuTaxable()) 
          vat_rate = euVatByCountry.rates[billingCcode].standard_rate

        if("metadata" in intent) {
          const {metadata} = intent
          if("tax" in metadata) tax = metadata.tax

        }else if(vat_rate) {
          tax = ( amount * ( 1 + ( vat_rate / 100 ) ) ) - amount
        }
          
        //console.log({ intent, amount, tax })
        
        this.$emit("on-validated", true, { intent, amount, tax });
        return true
        //return false
        
      }catch(e){
        this.$emit("on-validation-error", false, { error: e.message });
        return false
      }
      
    },
    async init(){
      
      
        this.items = []
      
        this.toggleLoading()
        const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/billing/stripe/payment/intent/list`, this.wizardModel)
        this.toggleLoading()
        if(res && res.status == 200){
            this.items = res.data
            if(res.data.length >= 5){
              this.intentId =  "0"
              this.setIntent(this.intentId)
            }
            
        }
      
      
    }
  }
};
</script>
<style>
.payment-border-success {
  border: 1px solid #2dce894d;
}
</style>
