<template>
  <div class="w-100">
    <div class="row d-flex justify-content-center">
      <div class="col-lg-12">
        <simple-wizard 
          ref="wizzard"
          :showFooter="true"
          :withTopNav="false"
          :loading="submitInProccess"
          :downloadLink="downLoadLink"
          @wizzard-complete="wizardComplete(wizardModel)"
          @download-client-created-invoice="downLoadInvoice()"
          @next-tab="setupTab"
          @prev-tab="setupTab"
          @reset-wizzard="reset"
          :finishButtonText="finishButtonText"
          :isReset="isReset"
          :resetText="resetText">
        
          <!--
          <template slot="header">
            <h3 class="card-title">Add funds to your account</h3>
            <h3 class="description">
              If you have any questions, please contact our support team <br> support@message-deck.com
            </h3>
          </template>
        -->
          <!--
          <wizard-tab :before-change="() => validateStep('card-success')">
            <template slot="label">
              <strong class="fa-stack-1x">1</strong>
              <p>Successful Payment</p>
            </template>
            <CardPaymentSuccess />
          </wizard-tab>
          
          <wizard-tab :before-change="() => validateStep('card-failed')">
            <template slot="label">
              <strong class="fa-stack-1x">1</strong>
              <p>Payment Failed</p>
            </template>
            <CardPaymentFailed />
          </wizard-tab>
          -->
          
          <!--

          <wizard-tab :before-change="() => validateStepAsync('step1')">
            <template slot="label">
              <strong class="fa-stack-1x">1</strong>
              <p>Payment method</p>
            </template>
            <choose-method
              ref="step1"
              @on-validation-error="onValidatatiopError"
              @on-validated="onStepValidated" 
            ></choose-method>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStepAsync('step2')">
            <template slot="label">
              <strong class="fa-stack-1x">2</strong>
              <p>Billing Details</p>
            </template>
            <billing-information
            @submitInProccess="toggleLoading"
            @on-validated="onStepValidated"
            ref="step2"/>
          </wizard-tab>
          -->
          <!--
          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <strong class="fa-stack-1x">1</strong>
              <p>Start Payment</p>
            </template>
            <PaymentWelcome 
              @on-validated="onStepValidated"
              ref="step1"
            />
          </wizard-tab>
          -->

          <wizard-tab :hideNext="true" :before-change="() => validateStepAsync('step0')">
            <template slot="label">
              <strong class="fa-stack-1x">1</strong>
              <p>Billing Details</p>
            </template>
            <billing-information
            :isLoading="submitInProccess"
            @loading="toggleLoading"
            @on-validated="onStepValidated"
            @on-validation-error="onValidatatiopError"
            ref="step0"/>
          </wizard-tab>

          <wizard-tab :hideNext="true" :before-change="() => validateStep('step1')">
            <template slot="label">
              <strong class="fa-stack-1x">2</strong>
              <p>Amount to add</p>
            </template>
            <choose-amount
              ref="step1"
              @loading="toggleLoading"
              :wizardModel="wizardModel"
              @on-validated="onStepValidated"
              @on-validation-error="onValidatatiopError"
            />
          </wizard-tab>
          
          <wizard-tab  :hideNext="true" :before-change="() => validateStepAsync('step2')">
            <template slot="label">
              <strong class="fa-stack-1x">3</strong>
              <p>Payment method</p>
            </template>
            <choose-method
              :paymentMethod="paymentMethod"
              @loading="toggleLoading"
              :wizardModel="wizardModel"
              ref="step2"
              @on-validation-error="onValidatatiopError"
              @on-validated="onStepValidated" 
            ></choose-method>
          </wizard-tab>
        </simple-wizard>

        <div v-if="isError" class="row justify-content-center">
        <div class="col-sm-10">
          <base-alert type="info">
            <strong>{{erroMessage}}</strong>
          </base-alert>
        </div>
        </div>
        <div v-if="paymentRef" class="row justify-content-center">
        <div class="col-sm-10">
          <base-alert type="info">
            <strong>A new invoice was created</strong><br>
            <mdicon name="information-outline" size="18" /> To download the invoice please click the download button. <br>
            <mdicon name="information-outline" size="18" /> Please add the following Payment reference:	{{paymentRef}} when you wire the amount.<br>
            <mdicon name="information-outline" size="18" /> As soon as we get transfer confirmation, your balance will be updated. This process can take 3-5 working days.<br>
          </base-alert>
        </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import PaymentWelcome from './PaymentWelcome.vue';
//import ChooseMethod from './ChooseMethod.vue';
import ChooseMethod from './ChooseMethodd.vue';
//import BankPayPal from './BankPayPal.vue';
//import CreditCard from './CreditCard.vue';
import CreditCard from './Stripe/CreditCard.vue';
import PendingPayments from './PendingPayments.vue'

//import BillingInformation from './BillingInformation.vue';
import BillingInformation from './Billing/Billing.vue';
import ChooseAmount from './ChooseAmount.vue';
import Summery from './Summery.vue';
import SummeryCard from './Summery/Card/Card.vue';


//import swal from 'sweetalert2';
import { SimpleWizard, WizardTab, BaseAlert } from 'src/components';

//var easyinvoice = require('easyinvoice');
const api_endpoint = process.env.VUE_APP_API_ENDPOINT

export default {
  components: {

    PendingPayments,
    PaymentWelcome,
    ChooseMethod,
    //BankPayPal,
    CreditCard,
    BillingInformation,
    ChooseAmount,
    Summery,
    SimpleWizard,
    WizardTab,
    BaseAlert,
    SummeryCard
  },
  props: {
    wizardComplete: {
      type: Function
    },
    paymentMethod: {
      type: String
    }
  },
  data() {
    return {
      googleToken: null,
      SiteKey: "6LccuikqAAAAAOcaE1Zkq2i9Lc75Sin7TdaXIG--",
      step2Description: "Billing notes",
      proforma: {},
      pdfData: null,
      title: 'sup',
      wizardModel: {},
      //summeryData: {},
      isError: false,
      erroMessage: null,
      submitInProccess: false,
      downLoadLink: "",
      paymentRef: "",
      paymentSucceded: false,
      paymentFail: false,
      finishButtonText: "Pay Securly",
      resultStatus: null,
      creditCardPaymentId: null,
      creditCardPaymentAmount: null,
      isReset: false,
      resetText: null,
      tabIdx: 0
    };
  },
  async mounted() {
    await this.tabInitReset(`step${this.tabIdx}`)
  },
  methods: {    
    getActiveTabIndex(){
      return this.$refs['wizzard'].getActiveTabIndex()
    },
    async wizzardNextTab(){
      await this.$refs.wizzard.nextTab()
    },
    async wizzardPreviousTab(){
      await this.$refs.wizzard.prevTab()
    },
    async tabInitReset(ref){
      try{
        if(this.$refs[ref]){
          const { init, reset } = this.$refs[ref]
          if(init) 
            await init()
          if(reset) 
            await reset()
        }
        return true  
      }catch(e){
        
        //this.toggleLoading(true)
      }
    },  
    async setupTab(tabIdx){
      await this.tabInitReset(`step${tabIdx}`)
      /*
      try{
        this.$emit('step', tabIdx)
        this.tabIdx = tabIdx
        let ref = `step${tabIdx + 1}`;
        if(this.$refs[ref]){
          
          const { init, reset } = this.$refs[ref]
          if(init) await init()
          if(reset) await reset()

        }
      }catch(e){
        console.log(e.message)
      }
      */
      //if(this.$refs[ref] && this.$refs[ref].init)
      //  await this.$refs[ref].init();
      //if(this.$refs[ref] && this.$refs[ref].reset)
      //  await this.$refs[ref].reset();
    },
    reset(){
      this.$refs['wizzard'].reset()
      this.wizardModel = {}
      
      this.isReset = false
      this.resetText= null
      this.resultStatus = null
      this.$emit('input', this.wizardModel)
    },
    toggleLoading(value){
      this.submitInProccess = value
      //this.$emit('loading', this.submitInProccess)
    },
    onValidatatiopError(isError, value){
      this.isError = !this.isError
      this.erroMessage = value
    },
    async validateStepAsync(ref) {
      
      return await this.$refs[ref].validate();
    },
    validateStep(ref) {
      
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      
      if(!validated) return
      this.isError = false
      this.wizardModel = { ...this.wizardModel, ...model };    
      this.$emit('input', this.wizardModel)
    },
    async confirmCardPayment(intentClientSecret){
      
      const { error: confirmError, paymentIntent: confirmedIntent } = await this.$stripe.confirmCardPayment(intentClientSecret);

      if(confirmError){
        console.log(confirmError)
        const res = await this.$http.post(`${api_endpoint}/billing/stripe/intent/error`, confirmError)  
        if(res && res.status == 200){
          this.$toast.error('Something went wrong, please try again')
          return
        }
      }

      const res = await this.$http.post(`${api_endpoint}/billing/stripe/intent/success`, confirmedIntent)
      if(res && res.status == 200){
        return confirmedIntent
      }

    },
    async card(){
      
      this.submitInProccess = !this.submitInProccess
      const res = await this.$http.post(`${api_endpoint}/billing/stripe/intent/create`, this.wizardModel)
      if(res && res.status == 200){
        const { paymentIntent } = res.data
        if (paymentIntent.status === 'requires_action' || paymentIntent.status === 'requires_source_action') {
            const confirmedIntent = await this.confirmCardPayment(paymentIntent.client_secret)
            if(confirmedIntent){
              this.paymentSucceded = true
              this.submitInProccess = !this.submitInProccess
              const { status, id, amount } = confirmedIntent
        
              this.resultStatus = status == "succeeded" ? true : false
              this.creditCardPaymentId = id
              this.creditCardPaymentAmount = (amount / 100).toFixed('0', 2)
              this.finishButtonText = "Download Invoice"
              //await this.$refs['wizaard'].nextTab()
              return 
              
            }

            this.resultStatus = false
            this.submitInProccess = !this.submitInProccess
        }

        this.submitInProccess = !this.submitInProccess
        const { status, id, amount } = paymentIntent
        this.creditCardPaymentId = id
        this.creditCardPaymentAmount = (amount / 100).toFixed('0', 2)
        
        this.resultStatus = status == "succeeded" ? true : false
        this.finishButtonText = "Download Invoice"
        

      }else{
        this.submitInProccess = !this.submitInProccess
        //this.finishButtonText = "Try Again"
        this.isReset = true
        this.resetText="Try Again"
        this.resultStatus = false
      }


    },
    async bank(){
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      this.wizardModel.billing_invoice_date = this.wizardModel.billing_invoice_due_date = yyyy + '-' + mm + '-' + dd;
      this.submitInProccess = !this.submitInProccess
      const res = await this.$http.post(api_endpoint + '/billing/invoice/proforma', this.wizardModel)
      this.submitInProccess = !this.submitInProccess;
      if(res && res.status == 200){
        this.downLoadLink = `data:application/pdf;base64,${res.data.encodedInvoice}`;
        this.paymentRef = res.data.proforma_invoice_number
        this.proforma = res.data
      }
    },
    wizardComplete1() {
      console.log('wizzard complete')
      //return this[this.wizardModel.method]()
      
      return null//this[this.wizardModel.method]()
      
      /**
       * TODO:
       * ADD ADDRESS DETAILS ETC TO BILLING INTENT FOR FRAUD PREVENTION!!!
       */
      return;
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      this.wizardModel.billing_invoice_date = this.wizardModel.billing_invoice_due_date = yyyy + '-' + mm + '-' + dd;
      //console.log(this.wizardModel);
      this.submitInProccess = !this.submitInProccess


      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      //this.$http.post(api_endpoint + '/billing/create-wave-invoice', this.wizardModel)
      //this.$http.post(api_endpoint + '/billing/create-proforma-invoice', this.wizardModel)
      this.$http.post(api_endpoint + '/billing/invoice/proforma', this.wizardModel)
      .then( async (resp) => {
        this.submitInProccess = !this.submitInProccess;

        this.downLoadLink = `data:application/pdf;base64,${resp.data.encodedInvoice}`;
        //this.paymentRef = resp.data.proforma_invoice_number
        this.paymentRef = resp.data.proforma_invoice_number
        this.proforma = resp.data
        /*
        var docUrl = document.createElement('a');
        docUrl.href=`data:application/pdf;base64,${resp.data}`
        docUrl.setAttribute('download', 'invoice.pdf');   
        document.body.appendChild(docUrl);
        docUrl.click();
        */
        /*
        this.paymentRef = this.paymentRef = this.wizardModel.billing_entity_name.replace(/\s/g, '').substr(0,7).toUpperCase() + '-' + resp.data.createdProformaInvoice[0].proforma_invoice_wave_invoice_number
        this.downLoadLink = resp.data.createdProformaInvoice[0].proforma_invoice_wave_download_link
        this.$toast.success("A new invoice was added, goto billing history to view");
        */
      })
      .catch( (error) => {
        this.submitInProccess = !this.submitInProccess;
      });
      


    },
    async downLoadInvoice(){
      const api_endpoint = process.env.VUE_APP_API_ENDPOINT
      
      const res = await this.$http.post(api_endpoint + '/billing/download-proforma-invoice', this.proforma)
      if(res && res.status == 200){
        var docUrl = document.createElement('a');
        docUrl.href=`data:application/pdf;base64,${res.data}`
        docUrl.setAttribute('download', this.proforma.payment_proforma_invoice_number + '.pdf');   
        document.body.appendChild(docUrl);
        docUrl.click();
      }
      /*
      var docUrl = document.createElement('a');
      docUrl.href = this.downLoadLink
      console.log(docUrl.href)
      docUrl.setAttribute('download', this.paymentRef + '.pdf');   
      document.body.appendChild(docUrl);
      docUrl.click();
      */
      //window.open(this.downLoadLink, '_blank');
    }
  }
};
</script>
