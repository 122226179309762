<template>
  <div id="worldMap"></div>
</template>
<script>
import 'd3';
import * as d3 from 'd3';
import 'topojson';
import DataMap from 'datamaps';
import { throttle } from 'src/util/throttle';


export default {
  //props: ['mapDataEx'],
  data() {
    return {
      color1: '#a598f7',
      color2: '#6249ff',
      highlightFillColor: '#6249ff',
      borderColor: '#3c3c3c',
      highlightBorderColor: '#d2ccf9',
      mapData: {},
      worldMap: null
    };
  },
  methods: {
    generateColors(length) {
      return d3
        .scaleLinear()
        .domain([0, length])
        .range([this.color1, this.color2]);
    },
    generateMapColors() {

      let mapDataValues = Object.values(this.mapData).map(item => item.sent || item.clicks);
      let maxVal = Math.max(...mapDataValues);
      let colors = this.generateColors(maxVal);
      let mapData = {};
      let fills = {
        defaultFill: '#e4e4e4'
      };
      for (let key in this.mapData) {
        
        let val = this.mapData[key];
        fills[key] = colors(val.sent || val.clicks);

        mapData[key] = {
          fillKey: key,
          value: val
        };

      }
      return {
        mapData,
        fills
      };
    },
    initVectorMap(mapDataEx) {
      

      this.mapData = mapDataEx

      
      const existingDiv = document.getElementById('worldMap');
      existingDiv.innerHTML = ''

      let { fills, mapData } = this.generateMapColors();
      this.worldMap = new DataMap({
        scope: 'world',
        element: document.getElementById('worldMap'),
        //element: newDiv,
        fills,
        data: mapData,
        responsive: true,
        geographyConfig: {
          borderColor: this.borderColor,
          popupTemplate: function (geography, data) {
            if(data){
              return `
                <div class="card card-white text-info p-3" >
                  <h4 class="text-muted"><u>${geography.properties.name}</u></h4>
                  <h5 class="text-muted">Sent:  ${data.value.sent}</h5>
                  <h5 class="text-muted">Clicks: ${data.value.clicks}</h5>
                  <h5 class="text-muted">CTR(%): ${ ( ( data.value.clicks / (data.value.sent || 1) ) * 100 ).toFixed(2) }</h5>
                  <h5 class="text-muted">Spent: ${data.value.spent}</h5>
                </div>
              `;
            }else{
              return `
                <div class="card card-white text-info p-3" >
                <h4 class="text-muted"><u>${geography.properties.name}</u></h4>
                <h5 class="text-muted">No Data</h5>
                </div>
              `
            }
            
          },
          borderWidth: 0.5,
          borderOpacity: 0.8,
          highlightFillColor: this.highlightFillColor,
          highlightBorderColor: this.highlightBorderColor,
          highlightBorderWidth: 0.5,
          highlightBorderOpacity: 0.8
        }

      });
    
    


      let resizeFunc = this.worldMap.resize.bind(this.worldMap);
      window.addEventListener(
        'resize',
        () => {
          throttle(resizeFunc, 40);
        },
        false
      );

    }
  },
  async mounted() {
    //this.initVectorMap();
  }
};
</script>
<style>

</style>
