<template>

    <div>
        <div class="d-flex flex-row">
            <div class="p-2">
                <base-button size="sm" type="danger" @click="resetSearch()">
                    <mdicon name="refresh" size="18"/>
                </base-button>
            </div>
             <div class="p-2">
                <base-button size="sm" type="default" >
                    <download-csv :data="csvData">
                        <mdicon name="file-export-outline" size="18"/>
                    </download-csv>                
                </base-button>
            </div>
        </div>
        <br>
        <div class="d-flex justify-content-center">
            <h5>To view your pricing, please select an accounting token</h5>
        </div>
        <div class="d-flex justify-content-center">
            

            <div class="row" id="pricing-wrraper">
                <div class="col">
                    <base-input
                        type="text"
                        placeholder="Country"
                        v-model="search.routing_country_name">
                    </base-input>
                </div>
                <div class="col">
                    <base-input
                        type="text"
                        placeholder="Country code"
                        v-model="search.routing_country">
                    </base-input>
                </div>
                <div class="col">
                     <ValidationProvider rules="required" v-slot="{ passed, failed, errors }">
                        
                        <el-select 
                        @change="getPricingData"
                        filterable
                        v-model="token" 
                        value-key="k"
                        label="Token"
                        placeholder="Token">
                            <el-option
                                v-for="(item, index) in keys"
                                :key="index"
                                :label="`${item.http_api_jwt_short_token} - ${item.http_api_jwt_type ? item.http_api_jwt_type : 'API'}s`"
                                :value="index">
                            </el-option>
                        </el-select>
                        <span v-if="!token" class="validation-error">Select token</span>  
                    </ValidationProvider>
                </div>
                <div class="col">
                    <base-input
                        type="text"
                        placeholder="MCC"
                        v-model="search.routing_mcc">
                    </base-input>
                </div>
                <div class="col">
                    <base-input
                        type="text"
                        placeholder="MNC"
                        v-model="search.routing_mnc">
                    </base-input>
                </div>
                <div class="col">
                    <base-input
                        type="text"
                        placeholder="Operator"
                        v-model="search.routing_operator_name">
                    </base-input>
                </div>
                <div class="col">
                    <base-input
                        type="text"
                        placeholder="Price"
                        v-model="search.routing_route_sell">
                    </base-input>
                </div>
                <div class="col">
                    <base-input
                        type="text"
                        placeholder="Currency"
                        v-model="search.routing_sell_currency">
                    </base-input>
                </div>
                <div class="col">
                    <base-input
                        type="text"
                        placeholder="Date"
                        v-model="search.updatedAt">
                    </base-input>
                </div>
            </div>
        </div>
        
        <div class="d-flex justify-content-center">    
            <div v-if="this.search.routing_account">
                <h4>
                    Token <u>{{this.search.routing_account.http_api_jwt_short_token}}</u>
                    <span v-if="this.search.routing_account.http_api_jwt_type.toUpperCase() == 'CAMPAIGN'">
                        is assigned to your campaigns pricing
                    </span>
                    <span v-else>
                        is assigned to your API pricing
                    </span>
                </h4>
            </div>
        </div>

        <div class="d-flex justify-content-center">    
            <base-pagination 
                v-if="(tableData.length)"
                :page-count="pageCount" 
                v-model="defaultPagination">
            </base-pagination>
        </div>

       <el-table
            empty-text="Select a token to view pricing"
            ref="elTable"
            :data="FilteredtData"
            style="width: 100%">
            <el-table-column
                prop="routing_country_name"
                label="Country">
            </el-table-column>
            <el-table-column
                prop="routing_country"
                label="Country Code">
            </el-table-column>
            <el-table-column
                prop="routing_account"
                label="Account">
            </el-table-column>
            <el-table-column
                prop="routing_mcc"
                label="Mcc">
            </el-table-column>
            <el-table-column
                prop="routing_mnc"
                label="Nnc">
            </el-table-column>
            <el-table-column
                prop="routing_operator_name"
                label="Operator">
            </el-table-column>
            <el-table-column
                prop="routing_route_sell"
                label="Price">
            </el-table-column>
            <el-table-column
                prop="routing_sell_currency"
                label="Currency">
            </el-table-column>
            <el-table-column
                prop="routing_created_at"
                label="Date">
                <template slot-scope="scope">
                    {{ scope.row.routing_created_at | parseDate }}
                </template>
            </el-table-column>
            
        </el-table>
        
        
    </div>

</template>

<script>

import {Table, TableColumn, Button, Pagination, Select, Option} from 'element-ui'
import {BasePagination} from 'src/components'
import elTableExport from "el-table-export";
import BaseButton from '../../../components/BaseButton.vue';
import DownloadCsv from 'vue-json-csv'

export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Button.name]: Button,
        [Pagination.name]: Pagination,
        [Select.name]: Select,
        [Option.name]: Option,
        BasePagination,
        elTableExport,
        BaseButton,
        DownloadCsv
    },
    filters:{
        'parseDate': function(cellValue){
            const date = new Date(cellValue);
            return date.toLocaleDateString('en-GB', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });
        }
    },
    computed: {
        // watch the entire as a new object
        // added this because this is the pnly way to get new vs old value
        // in watch when nested properties are watched
        'computedSearch': function() {
            return Object.assign({}, this.search);
        }
    },
    props:['keys'],
    watch: {
        'defaultPagination': function() {
            
            if(this.searchData.length){
                this.FilteredtData = this.searchData.slice(
                    (this.defaultPagination-1)*10, 
                    (this.defaultPagination)*10
                )
            }else{

            }
            
            this.FilteredtData = this.tableData.slice(
                (this.defaultPagination-1)*10, 
                (this.defaultPagination)*10
            )
            
        },
        'computedSearch' : {
            handler(newVal, oldVal){

                this.searchData = this.tableData.filter((data)=>{ 

                   if(
                       (!this.search.routing_country_name || (data.routing_country_name && data.routing_country_name.toLowerCase().includes(this.search.routing_country_name.toLowerCase())) ) &&
                       (!this.search.routing_country ||  data.routing_country.toLowerCase().includes(this.search.routing_country.toLowerCase())) &&
                       //(!this.search.client_pricing_api_account_token ||  data.client_pricing_api_account_token.toLowerCase().includes(this.search.client_pricing_api_account_token.toLowerCase())) &&
                       (!this.search.routing_mcc ||  data.routing_mcc.toLowerCase().includes(this.search.routing_mcc.toLowerCase())) &&
                       (!this.search.routing_mnc ||  data.routing_mnc.toLowerCase().includes(this.search.routing_mnc.toLowerCase())) &&
                       (!this.search.routing_operator_name ||  data.routing_operator_name.toLowerCase().includes(this.search.routing_operator_name.toLowerCase())) &&
                       (!this.search.routing_route_sell ||  data.routing_route_sell.toLowerCase().includes(this.search.routing_route_sell.toLowerCase())) &&
                       (!this.search.routing_sell_currency ||  data.routing_sell_currency.toLowerCase().includes(this.search.routing_sell_currency.toLowerCase()))
                   ){
                       return data
                   }
                    
                })
              
                
                
                this.pageCount = Math.ceil(this.searchData.length/10)
                this.defaultPagination = 1
              
                this.FilteredtData = this.searchData.slice(
                        (this.defaultPagination-1)*10, 
                        (this.defaultPagination)*10
                    )
              
                
                
            },
            deep: true
            //,immediate: true

        }
        
    },
    data() {
      return {
        //selectToken: null,
        search: {
            routing_country_name:null,
            routing_country:null,
            routing_account:null,
            routing_mcc:null,
            routing_mnc:null,
            routing_operator_name:null,
            routing_route_sell:null,
            routing_sell_currency:null
        },
        tableData: [],
        csvData: [],
        FilteredtData: [],
        searchData: [], 
        pageSize: 10,
        pageCount: 0,
        defaultPagination:1,
        submitInProccess: false,
        token: null
      }
    },
    created() {
        //this.getPricingData()
    },
    methods: {
        resetSearch(){
            this.search = {
            client_pricing_country_name:null,
            client_pricing_country_code:null,
            client_pricing_api_account_token:null,
            client_pricing_mcc:null,
            client_pricing_mnc:null,
            client_pricing_operator_name:null,
            client_pricing_price:null,
            client_pricing_currency:null
          }
        },
        getData(){

            /*
            tableData.filter(
                data => !search || (
                        data.client_pricing_mnc.includes(search) ||
                        data.client_pricing_country_name.includes(search)
                        )
            )
            */
        },
        parseCsv(){
            
            this.csvData = this.tableData.filter(row => row.routing_country).map(row => {
                return {
                    Country: row.routing_country_name,
                    'Country Code': row.routing_country,
                    'Operator': row.routing_operator_name,
                    'Mcc': row.routing_mcc,
                    'Mnc': row.routing_mnc,
                    'Price': row.routing_route_sell
                }
            })
             
        },
        async getPricingData(){
            
            

            this.submitInProccess = true
            const api_endpoint = process.env.VUE_APP_API_ENDPOINT
            this.search.routing_account = this.keys[this.token]
            
            
            const { http_api_jwt_short_token:token, http_api_jwt_type: type } = this.keys[this.token]

            const res = await this.$http.get(`${api_endpoint}/routing/token/client`, { params: {
                token,
                type
                //routing_account_uuid: 'uuid',
                //routing_account: this.search.routing_account.http_api_jwt_short_token,
            }})

            
            this.pageCount = Math.ceil(res.data.length/10)
            this.tableData = res.data
            this.parseCsv()
            this.FilteredtData = this.tableData
            
            this.FilteredtData = this.tableData.slice(
                (this.defaultPagination-1)*10, 
                (this.defaultPagination)*10
            )
            

            this.total = res.data.length
            //this.$toast.success("Loaded");
            this.submitInProccess = false

        },
        handleCurrentChange(val){
            this.page = val;
        },
        handleExport(){
            
            elTableExport(this.$refs.elTable, {
                fileName: "pricing",
                type: "csv",
                withBOM: false,
            }).then((result) => {
                //console.info("successfully");
                this.$toast.success('Exported')
            }).catch((err) => {
                //console.info("Error: " , err);
                this.$toast.error('Cant export ')
            });
        }
    }
}
</script>

<style>

.el-input__inner{
    background: #27293d !important;
}

.el-table th.el-table__cell.is-leaf{
border:none;
}
.el-table th.el-table__cell{
  background:none;
 
}

#pricing-wrraper .el-input__inner {
  color: #fafffe !important;
}
.validation-error{
  color:bisque !important;
  font-size:12px
}
</style>