<template>
    <div class="w-100">
        <div class="row">
            <div class="col-lg-12">
                <h3>US 10DLC Campaigns</h3>
                <h6>Your US  10DLC campaigns.</h6>
                
            </div>
        </div>
        <br>
        <br>
        <div class="row">
            <!--
            <div class="col-lg-4">
                <card>
                    <template slot="header">
                        <h3 class="card-title">Get started</h3>
                    </template>
                        <p>
                            Contact our support team to get started with 10DLC in north America.<br/>
                        </p>
                    <template slot="footer">
                        Get up and running quickly
                    </template>
                </card>
            </div>
            -->
            <div class="col-lg-12">
                <card>
                    <template slot="header">
                        
                    </template>
                    <collapse :multiple-active="false">
                        <collapse-item title="What is 10DLC?">
                            <p>
                                10DLC is a carrier sanctioned channel for transporting Application-to-Person (A2P) messaging. <br>
                                It uses a dedicated 10-digit telephone number allocated within the North American Numbering Plan (NANP). <br>
                                10DLC is a cost-effective messaging solution designed to help businesses increase customer satisfaction and engagement.
                            </p>
                        </collapse-item>
                        <collapse-item title="What is trust score and how does it affect my campaign?">
                            <p>
                                A trust score is required for brand verification purposes.<br>
                                Combined with your use case and volumes of traffic, it helps carriers determine throughput per second (TPS) per 10DLC campaign.<br>
                                You get a trust score through the vetting process.
                            </p>
                        </collapse-item>
                        <collapse-item title="How can I get started with 10DLC?">
                            <div class="row">
                                <div class="col-lg-4">
                                    <card>
                                        <template slot="header">
                                            <h6 class="card-category">Step 1</h6>
                                            <h5 class="card-title">Get a number</h5>
                                        </template>
                                        <template slot="raw-content">
                                            <p>
                                                We have a big selection of 10DLC numbers, you can choose which one you want to get and activate it directly in the portal.
                                            </p>
                                        </template>                                        
                                    </card>
                                </div>
                                <div class="col-lg-4">
                                    <card>
                                        <template slot="header">
                                            <h6 class="card-category">Step 2</h6>
                                            <h5 class="card-title">Register a brand</h5>
                                        </template>
                                        <template slot="raw-content">
                                            <p>
                                                Create a new bundle of supporting documents so we can register your brand. A brand registration is a requirement to use 10DLC and it makes you seem more trustworthy, and reach your users without fail.
                                            </p>
                                        </template>                                        
                                    </card>
                                </div>
                                <div class="col-lg-4">
                                    <card>
                                        <template slot="header">
                                            <h6 class="card-category">Step 3</h6>
                                            <h5 class="card-title">Configure the campaign</h5>
                                        </template>
                                        <template slot="raw-content">
                                            <p>
                                                After you have your number and brand registered you need to register a campaign to send out to your customers.
                                            </p>
                                        </template>                                        
                                    </card>
                                </div>
                            </div>
                            
                            
                        </collapse-item>
                    </collapse>
                </card>
            </div>    
        </div>
        <br>
        <div class="row">
            <div class="col-lg-12">
                <h3>Whats next?</h3>
            </div>    
        </div>
        <div class="row">
            <div class="col-lg-12">
                <card>
                    <p>
                        Contact our team to get you start quickly with 10DLC campaigns in north America. <br>

                    </p>
                    <a href="https://join.skype.com/invite/EoeFWrJVYPzw">
                        <base-button tag="a" type="primary">
                            <i class="fa fa-comment" aria-hidden="true"></i>
                            Contact Us On Skype
                        </base-button>
                    </a>
                    
                    <a href="mailto:support@message-deck.com">
                    <base-button tag="a" type="primary">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                        Contact Support
                    </base-button>
                    </a>
                    

                </card>
            </div>
        </div>
        
  </div>
</template>

<script>
import { 
  Collapse, 
  CollapseItem 
} from 'src/components';
export default {
    components:{
        Collapse,
        CollapseItem,
    },
}
</script>

<style>

</style>