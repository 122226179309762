<template>
    <div class="card p-3">
        <ul class="list-group list-group-flush">
            

            <h5 class="text-white" v-if="cards.length"><b><u>PREVIOUSLY USED CARDS</u></b></h5>

            <li v-for="(item, index) in cards" :key="index"  
                class="list-group-item w-100 mb-3 p-0"
                style="border-bottom: 1px solid #ffffff1a">

                <ExistingCard  
                    :stripeCardId="item.id"
                    v-model="selectedCard"
                    :selectedcardId="selectedCard"
                    :key="index" 
                    :index="index"
                    :cardInfo="item.card"
                    
                    @detach="detach"
                />
                
            </li>

            <h5 class="text-white"><b><u>ADD A NEW CARD</u></b></h5>
            <li  class="list-group-item w-100 mb-3 p-0"
                style="border-bottom:1px solid #ffffff1a">
                <div class="card flex-row">
                    <NewCard 
                        ref="newCard"
                        v-model="selectedCard"
                        :intent="intent" 
                        :selected="selectedCard"
                        :addNewMethod="addNewMethod"
                    />
                </div>
            </li>

        </ul>
    </div>
</template>

<script>
//import SavedCards from '../../Stripe/SavedCards.vue'
import ExistingCard from '../../Stripe/ExistingCard.vue'
import NewCard from '../../Stripe/NewCard.vue'
import Pending from './Pending.vue';

export default {
    components: {
        Pending,
        NewCard,
        ExistingCard
        //SavedCards
    },
    props:{
        wizardModel: {
            type: Object
        },
        cards: {
            type: Array
        },
        existingCard: {
            type: Object
        }
    },
    data(){
        return {
            selectedCard: 'newCard',
            newCardElements: null,
            current: 'existing', //init the list first if empty switch to new
            selectedcardId: null,
            newCard: false,
            savedCards: [],
            card: null,
            clientSecret: null,
            clientSecret: null,
            loading: false,
            elementss: {},
            intent: {}
        }
    },
    methods:{
        setCard(card){
            const { id } = card
            if(id){
                this.selectedCard = id
                this.selectedcardId = id
            } 
        },
        detach(cardIdx){
            this.cards.splice(cardIdx, 1)
        },
        async init(){
            const { intent } = this.wizardModel
            this.intent = intent
            this.$refs['newCard'].init()
        },
        async addNewMethod(cardNumber){
            try{
                this.$emit('loading', true)
                const card = cardNumber
                const { paymentMethod: methodObject, error } = await this.$stripe.createPaymentMethod({  type: 'card', card }); //Only card number is needed
                this.$emit('loading', false)
                if(error) throw new Error(error.message)
                const {id} = methodObject
                return id
            }catch(e){
                this.$toast.error(e.message)
            }
        },  
        toggle(current){
            this.current = current
            //this.init()
        },
        async validate(){
            let card = this.selectedCard
            
            if(this.selectedCard == 'newCard'){
                card = await this.$refs['newCard'].addNewMethodd()
            }

            const methodObject = {id:card}
            if(card) return { methodObject }
            return false
        }
    }
}
</script>

<style>

</style>