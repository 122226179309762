<template>
    <div class="row">
        <div class="col-lg-12">
            <blockquote>
                <p class="blockquote blockquote-primary">
                    Check any of the options below to further customize your media link,
                    Any of the checked options will be dynamically applied before a message is sent:
                </p>
            </blockquote>
        </div>
        
        <div class="row">
            <div class="col-lg-12">
                <div style="padding:0px 0px 0px 15px">
                    <div class="row">
                        <div class="col-lg-12">
                            <el-tooltip 
                                content="Filter non mobile devices, applied only when shorten Url is active" 
                                effect="light" :open-delay="300" placement="top">
                                <base-checkbox 
                                    :disabled="!shortUrl"
                                    style="display:inline-block"
                                    v-model="isMobileOnly">
                                    Allow mobile devices only &nbsp;
                                        <i class="fa fa-eye-slash text-info" aria-hidden="true"></i> 
                                </base-checkbox>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <el-tooltip 
                                content="Filter out apps that crawl your link, applied only when shorten Url is active, not all apps are filtered" 
                                effect="light" :open-delay="300" placement="top">
                                <base-checkbox 
                                    :disabled="!shortUrl"
                                    style="display:inline-block"
                                    v-model="filterCrawlers">
                                    Hide my link from unwanted apps &nbsp;
                                        <i class="fa fa-eye-slash text-info" aria-hidden="true"></i> 
                                </base-checkbox>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <el-tooltip 
                                content="Append mobile network mccmnc to your media link, additional cost of 0.002EUR/SMS." 
                                effect="light" :open-delay="300" placement="top">
                                <base-checkbox 
                                    style="display:inline-block"
                                    v-model="appendOperator">
                                    Append mobile network to my media link, 0.002EUR/SMS &nbsp;
                                        <i class="fa fa-mobile text-info" aria-hidden="true"></i> 
                                </base-checkbox>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <el-tooltip 
                                content="Append the price of the message to your media link" 
                                effect="light" :open-delay="300" placement="top">
                                <base-checkbox 
                                    style="display:inline-block"
                                    v-model="appendPrice">
                                    Append Message Price &nbsp;
                                        <span class="text-info">&euro;</span>
                                </base-checkbox>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                           <el-tooltip 
                                content="Append IP city and country ISO 2 letter code to your media link, applied only when shorten Url is active, 0.002EUR/SMS. (city not always availble)" 
                                effect="light" :open-delay="300" placement="top">
                                <base-checkbox 
                                    style="display:inline-block"
                                    v-model="appendGeoData">
                                    Append Geograpichal Information, 0.002EUR/SMS. &nbsp;
                                        <i class="fa fa-globe text-info" aria-hidden="true"></i> 
                                </base-checkbox>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BaseTable } from '@/components';
export default {
    components:{
        BaseTable
    },
    props:{
        done: {
            type: Function,
            default: () => {}
        },
        shortUrl: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            filterCrawlers: false,
            appendOperator: false,
            appendPrice: false,
            appendGeoData: false,
            isMobileOnly: false,
        }
    },
    methods: {
        click(){
            this.done({
                filterCrawlers: this.filterCrawlers,
                appendOperator: this.appendOperator,
                appendPrice: this.appendPrice,
                appendGeoData: this.appendGeoData,
                isMobileOnly: this.isMobileOnly
            })
        }
    }
}
</script>

<style>

</style>