<template>
  <div>
    
    <card type="chart">
      <template slot="header">
          <div class="row">
            <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
              <h3 class="card-title">Message search</h3>
              <h4 class="card-category">Search and troubleshoot specific messages</h4>
              
            </div>
            <div class="col-sm-6 d-flex d-sm-block">
              <div
                class="btn-group btn-group-toggle"
                :class="isRTL ? 'float-left' : 'float-right'"
                data-toggle="buttons"
              >
              </div>
            </div>
          </div>
        </template>
        <div class="row">
          <div class="col">
            <div class="input-group">
              
              <!--
              <download-csv
                :labels="labelsCsv"
                name = "messages.csv"
                :data = "downLoadData"
                @export-started="getAllExportData">
              </download-csv>
              -->

              <base-button @click="getAllExportData" type="primary" class="ml-3">
                Download CSV
              </base-button>

              
            </div>
            
          </div>
          
        </div>
        <br>
        <div class="d-flex justify-content-center">
          <h5>To search with text filters, enter your query and press enter</h5>
        </div>
        <br>
        <div class="d-flex justify-content-center">
            <div class="row">
                <div class="col">
                    <base-input
                        type="text"
                        placeholder="Sender"
                        v-model="search.send_sms_sender"
                        @keyup.enter="filterColumn({
                          key: 'send_sms_sender',
                          value: search.send_sms_sender
                        })">
                    </base-input>
                </div>
                <div class="col">
                  <ValidationObserver v-slot="{  }">
                    <ValidationProvider
                    rules="digits_between:6,14"
                    v-slot="{ passed, failed, errors }">
                    <base-input
                        type="tel"
                        :class="{ 
                          'has-success': !errors[0] && search.send_sms_receiver, 
                          'has-danger': errors[0]
                        }"
                        placeholder="Recipient"
                        v-model="search.send_sms_receiver"
                        @keyup.enter="filterColumn({
                          error:  errors[0],
                          key: 'send_sms_receiver',
                          value: search.send_sms_receiver
                        })">
                    </base-input>
                    <span class="validation-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </ValidationObserver>
                </div>
                <div class="col">
                    <base-input
                        type="text"
                        placeholder="Your message id"
                        v-model="search.send_sms_mt_client_msg_id"
                        @keyup.enter="filterColumn({
                          key: 'send_sms_mt_client_msg_id',
                          value: search.send_sms_mt_client_msg_id
                        })">
                    </base-input>
                </div>
                <div class="col">
                    <base-input
                        type="text"
                        placeholder="Our message id"
                        v-model="search.send_sms_mt_our_msg_id"
                        @keyup.enter="filterColumn({
                          key: 'send_sms_mt_our_msg_id',
                          value: search.send_sms_mt_our_msg_id
                        })">
                    </base-input>
                </div>
                <div class="col">
                    <base-input
                        type="text"
                        placeholder="Status"
                        v-model="search.send_sms_current_dlr_mask_text"
                        @keyup.enter="filterColumn({
                          key: 'send_sms_current_dlr_mask_text',
                          value: search.send_sms_current_dlr_mask_text
                        })">
                    </base-input>
                </div>
                
            </div>
        </div>
        <div class="d-flex justify-content-center">
          <h5>
            Found {{totalRecords}} results
          </h5>
        </div>    
        <div class="d-flex justify-content-center">
          
            <base-pagination 
              type="info"
              :pageCount="pageCount" 
              :perPage="perPage"
              :total="totalRecords"
              :value="page"
              :pagesToDisplay=10
              @input="pagination"></base-pagination>
          </div>
          <div class="d-flex justify-content-center">

            <el-table
            :data="FilteredtData"
            @sort-change="sortChange">
            <el-table-column
                header-align="center"
                label="Account"
                prop="send_sms_account">
              </el-table-column>

              <el-table-column
                header-align="center"
                label="Time stamp"
                prop="createdAt"
                sortable="custom">
              </el-table-column>
              <el-table-column
                header-align="center"
                label="Sender ID"
                prop="send_sms_sender"
                sortable="custom">
              </el-table-column>
              <el-table-column
                header-align="center"
                label="Recipient"
                prop="send_sms_receiver"
                sortable="custom">
              </el-table-column>
              <el-table-column
                header-align="center"
                label="Country"
                prop="send_sms_mt_country_name">
              </el-table-column>
              <el-table-column
                header-align="center"
                label="Your id"
                prop="send_sms_mt_client_msg_id">
              </el-table-column>
              <el-table-column
                header-align="center"
                label="Our id"
                prop="send_sms_mt_our_msg_id">
              </el-table-column>
              <el-table-column
                header-align="center"
                label="Operator"
                prop="send_sms_mt_operator_name">
              </el-table-column>
              <el-table-column
                header-align="center"
                label="Status"
                prop="send_sms_current_dlr_mask_text"
                sortable="custom">
              </el-table-column>
              <el-table-column
                header-align="center">            
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="info"
                    @click="handleEdit(scope.$index, scope.row)">View</el-button>
                  
                </template>
              </el-table-column>
              
            </el-table>
            
          </div>

        
    </card>

    <modal :show.sync="modals.detailsModal"
               body-classes="p-0"
               modal-classes="modal-dialog-centered modal-md">
            <h2 slot="header" class="modal-title" id="modal-title-default">
              View message
            </h2>
            <div class="row" style="padding:25px">
            <div class="col-lg-12">
            <p>
                  <table>
                    <tr>
                      <td style="width:150px">Accounting token:</td><td>{{modalMessageData.send_sms_account}}</td>
                    </tr>
                    <tr>
                      <td style="width:150px">Message ID:</td><td>{{modalMessageData.send_sms_mt_client_msg_id || modalMessageData.send_sms_mt_our_msg_id}}</td>
                    </tr>
                    <tr>
                      <td>Country:</td><td>{{modalMessageData.send_sms_mt_country_name}}</td>
                    </tr>
                    <tr>
                      <td>Operator:</td><td>{{modalMessageData.send_sms_mt_operator_name}}</td>
                    </tr>
                    <tr>
                      <td>Sent via:</td><td>{{modalMessageData.send_sms_api_key_type}} protocol</td>
                    </tr>
                    <tr>
                      <td>API key mode:</td><td>{{modalMessageData.send_sms_mt_key_mode}}</td>
                    </tr>
                    <tr>
                      <td>Date sent:</td><td>{{modalMessageData.createdAt}}</td>
                    </tr>
                    <tr>
                      <td>Delivery report date:</td><td>{{modalMessageData.send_sms_mt_dlr_date}}</td>
                    </tr>
                    <tr>
                      <td>Sent from:</td><td>{{modalMessageData.send_sms_sender}}</td>
                    </tr>
                    <tr>
                      <td>Sent to:</td><td>{{modalMessageData.send_sms_receiver}}</td>
                    </tr>
                    <tr>
                      <td>Parts count:</td><td>{{modalMessageData.send_sms_mt_parts}}</td>
                    </tr>
                    <tr>
                      <td>Cost:</td><td>{{modalMessageData.send_sms_mt_sell}}</td>
                    </tr>
                    <tr>
                      <td>Status:</td><td>{{modalMessageData.send_sms_current_dlr_mask_text}}</td>
                    </tr>
                    <tr>
                      <td >Text:</td><td style="max-width:300px;word-wrap: break-word;">{{decodeURIComponent(modalMessageData.send_sms_mt_text)}}</td>
                    </tr>

                  </table>
                </p>
            </div>
            </div>
            

            <template slot="footer">
              <div class="row" style="padding:25px">              
                <base-button type="secondary" class="ml-auto" @click="modals.detailsModal = false">Close
                </base-button>
                </div>                
            </template>
    </modal>
      

  </div>
</template>

<script>
import {Modal} from 'src/components'
import {Table, TableColumn, Button} from 'element-ui'
import {BasePagination} from 'src/components'
import DownloadCsv from 'vue-json-csv'
import { extend } from "vee-validate";
import { required,min,max,numeric, digits } from "vee-validate/dist/rules";
extend("min", min);
extend("max", max);
extend("numeric", numeric);

extend('digits_between', {
  async validate(value, { min, max }) {
        return value.match(/^[0-9]{6,14}$/);
  },
  params: ['min', 'max'],
  message: 'The {_field_} must be between {min} and {max} digits'
});



export default {
  name: 'Results',
  components: {
    Modal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    BasePagination,
    DownloadCsv
  },
  props:['FilteredtData', 'totalRecords', 'page', 'perPage', 'pageCount', 'downLoadData'],
  computed: {
      isRTL() {
          return this.$rtl.isRTL;
        },
        // watch the entire as a new object
        // added this because this is the pnly way to get new vs old value
        // in watch when nested properties are watched
        'computedSearch': function() {
            return Object.assign({}, this.search);
        }
  },
  data() {
      return {
        labelsCsv:{
          send_sms_account: 'Accounting token',
          send_sms_api_key_type: 'Protocol',
          send_sms_sender: 'Sender',
          send_sms_receiver: 'Receiver',
          send_sms_requested_dlr_mask_code: 'Requested delivery status',
          send_sms_current_dlr_mask_code: 'Current status',
          send_sms_current_dlr_mask_text: 'Current status description',
          send_sms_mt_mcc: 'Mcc',
          send_sms_mt_mnc: 'Mnc',
          send_sms_mt_operator_name: 'Operator',
          send_sms_mt_country_code: 'Country code',
          send_sms_mt_country_name: 'Country name',
          send_sms_mt_our_msg_id: 'Our message id',
          send_sms_mt_client_msg_id: 'Your message id',
          send_sms_mt_text: 'Message text',
          send_sms_mt_key_mode: 'Key mode',
          send_sms_mt_dlr_date: 'Delivery report date',
          send_sms_mt_parts: 'Message parts',
          send_sms_mt_sell: 'Message price',
          createdAt: 'Timestamp'
        },
        GlobalData: [],
        LastSortDirection: null,
        DisabledPagination: true,
        localFilteredData: [],
        searchData: [], 
        dataLangthForPagination: 200,
        defaultPagination: 1,
        //tableData: [],
        search: {
          send_sms_sender: null,
          send_sms_receiver: null,
          send_sms_mt_our_msg_id: null,
          send_sms_mt_client_msg_id: null,
          send_sms_current_dlr_mask_text: null

        },
        modalMessageData:{

        },
        modals: {
          detailsModal: false
        }
      }
  },
  methods: {
    getAllExportData(){
      this.$emit('getAllDataForDownLoad', true)
    },
    resetFilters(){
      
      
      this.search= {
        send_sms_sender: null,
        send_sms_receiver: null,
        send_sms_mt_our_msg_id: null,
        send_sms_mt_client_msg_id: null,
        send_sms_current_dlr_mask_text: null

      }
    },
    filterColumn(data){
      if(data.error){
        
      }else{
        this.$emit('filterColumn', data);
      }
      
    },
    pagination(page){
      
      this.$emit('pagination', page);
    },
    //https://www.codetd.com/en/article/12795134
    sortChange(columnData){

      this.$emit('sortColumn', [
        {
          type: columnData.order == 'descending' ? 'desc' :  columnData.order == 'ascending' ? "asc" : null , 
          field: columnData.column.property
        }
      ]);
    //[{type: "asc", field: "send_sms_sender"}]
      
      
    },
    resetSearch(){
        this.search = {
          sms_time_stamp: null,
          sms_sender: null,
          sms_receiver: null,
          sms_country_name: null,
          sms_client_msg_id: null,
          sms_our_msg_id: null,
          sms_operator_name: null,
          sms_status: null,
          send_sms_account: null
        }
      },
    handlePagination(OldValue,NewValue){
      
    },
    handleEdit(index, row) {
      
      this.modalMessageData = row
      this.modals.detailsModal = true
    },
    handleDelete(index, row) {
      
    }
  },
}
</script>

<style>
.el-table th.el-table__cell.is-leaf{
border:none;
}
.el-table th.el-table__cell{
  background:none;
  
}
</style>
