<template>
  <div v-loading="submitInProccess">
    <card type="chart">
        <template slot="header">
          <div class="row">
            <div class="col-sm-6">
              <h3 class="card-title">Delify History</h3>
              <h4 class="card-category">View your Delify history</h4>
              
            </div>
            <div class="col-sm-6 d-flex d-sm-block">
              <div
                class="btn-group btn-group-toggle"
                
                data-toggle="buttons"
              >
              </div>
            </div>
          </div>
        </template>
        <div class="d-flex justify-content-center">
          <base-input label="Date range">
           
              <el-date-picker        
                  v-model="timeRange"
                  type="daterange"
                  :picker-options="pickerOptions"
                  range-separator="To"
                  start-placeholder="Start date"
                  end-placeholder="End date"
                  @change="changeDates"
                  
                  value-format="yyyy-MM-dd hh:mm:ss"
                  
                  align="right">
              </el-date-picker>
              
          </base-input>

          &nbsp;

          <base-input label="Refresh">
            <base-button  
              :loading="submitInProccess" 
              type="primary" style="padding:8px 30px; margin: 0" @click="refreshTbl">
              Refresh
            </base-button>
          </base-input>
          
          
        </div>
        <div class="d-flex justify-content-center">
          <el-table :data="searchByFields">
            <el-table-column
                    label=""
                    prop="test_sms_mt_operator_name">
                    <div slot-scope="props">
                      <base-input
                      v-model="search.test_sms_mt_operator_name"
                      @keyup="filterData(props)"
                      placeholder="Network"
                      ></base-input>
                    </div>
                </el-table-column>
                 <el-table-column
                    label=""
                    prop="test_sms_test_id">
                    <div slot-scope="props">
                      <base-input
                      v-model="search.test_sms_test_id"
                      @keyup="filterData(props)"
                      placeholder="Test ID"
                      ></base-input>
                    </div>
                </el-table-column>
                <el-table-column    
                    label=""
                    prop="test_sms_mt_country_name">
                    <div slot-scope="props">
                      <base-input
                      v-model="search.test_sms_mt_country_name"
                      @keyup="filterData(props)"
                      placeholder="Country"
                      ></base-input>
                    </div>
                </el-table-column>
                <el-table-column
                    label=""
                    prop="test_sms_mt_mccmnc">
                    <div slot-scope="props">
                      <base-input
                      v-model="search.test_sms_mt_mccmnc"
                      @keyup="filterData(props)"
                      placeholder="mccmnc"
                      ></base-input>
                    </div>
                </el-table-column>
                <el-table-column
                    label=""
                    prop="test_sms_receiver">
                    <div slot-scope="props">
                      <base-input
                      v-model="search.test_sms_receiver"
                      @keyup="filterData(props)"
                      placeholder="Test Number"
                      ></base-input>
                    </div>
                </el-table-column>
                <el-table-column
                    label=""
                    prop="test_sms_mt_text">
                    <div slot-scope="props">
                      <base-input
                      v-model="search.test_sms_mt_text"
                      @keyup="filterData(props)"
                      placeholder="Message text"
                      ></base-input>
                    </div>
                </el-table-column>
                <el-table-column>
                </el-table-column>
                <el-table-column
                    label=""
                    prop="test_sms_sender">
                    <div slot-scope="props">
                      <base-input
                      v-model="search.test_sms_sender"
                      @keyup="filterData(props)"
                      placeholder="Sent sender ID"
                      ></base-input>
                    </div>
                </el-table-column>
                <el-table-column
                    label=""
                    prop="test_sms_acctual_sender">
                    <div slot-scope="props">
                      <base-input
                      v-model="search.test_sms_acctual_sender"
                      @keyup="filterData(props)"
                      placeholder="Received Sender ID"
                      ></base-input>
                    </div>
                </el-table-column>
                <el-table-column
                    label=""
                    prop="test_sms_mt_last_status">
                    <div slot-scope="props">
                      <base-input
                      v-model="search.test_sms_mt_last_status"
                      @keyup="filterData(props)"
                      placeholder="Delivery status"
                      ></base-input>
                    </div>
                </el-table-column>

                <el-table-column
                    label=""
                    prop="test_sms_date_time">
                </el-table-column>
                
          </el-table>
        </div>
          
          <div class="d-flex justify-content-center">
            <el-table :data="tableData">
                <el-table-column
                    label="Netowrk"
                    prop="test_sms_network">
                    <div slot-scope="props">
                      
                      <country-flag :country='props.row.test_sms_mt_country_code' size='small'/> 
                      
                      {{ props.row.test_sms_mt_country_code }} - {{ props.row.test_sms_mt_operator_name }}
                    </div>
                </el-table-column>
                <el-table-column
                    label="Test ID"
                    prop="test_sms_test_id">
                </el-table-column>
                <el-table-column
                    label="Country"
                    prop="test_sms_mt_country_name">
                </el-table-column>
                <el-table-column
                    
                    label="MccMnc"
                    prop="test_sms_mt_mcc">
                    <div slot-scope="props">
                      {{ props.row.test_sms_mt_mcc + props.row.test_sms_mt_mnc }}
                    </div>
                    
                </el-table-column>
                <el-table-column
                    label="Test Number"
                    prop="test_sms_receiver">
                </el-table-column>
                <el-table-column
                    label="Message Text"
                    prop="test_sms_mt_text">
                    <div slot-scope="props">
                     
                      <div 
                      :class="{
                        'text-warning' : props.row.test_sms_mt_text_received != props.row.test_sms_mt_text,
                        'text-success' : props.row.test_sms_mt_text_received == props.row.test_sms_mt_text
                        }">
                      {{ ( props.row.test_sms_mt_text_received ? props.row.test_sms_mt_text_received.substring(props.row.test_sms_mt_text_received.indexOf(' ') + 1) : props.row.test_sms_mt_text ) }}
                      </div>
                     
                    </div>
                    
                </el-table-column>
                
                <el-table-column
                    label="SMS Parts"
                    prop="test_sms_mt_parts">
                </el-table-column>
                <el-table-column
                    label="Sender ID"
                    prop="test_sms_sender">
                    <div slot-scope="props" >
                     
                      <div 
                      :title="props.row.test_sms_sender !=  props.row.test_sms_sender_received ? 'Sender ID changed': ''"
                      :class="{
                          'text-warning' : props.row.test_sms_sender_received && ( props.row.test_sms_sender !=  props.row.test_sms_sender_received ),
                          'text-success' : props.row.test_sms_sender_received && props.row.test_sms_sender ==  props.row.test_sms_sender_received,
                        }">
                      {{  ( props.row.test_sms_sender_received ? props.row.test_sms_sender_received : props.row.test_sms_sender )  }}
                      </div>
                     
                    </div>
                </el-table-column>
                <!--
                <el-table-column
                    label="Test cost"
                    prop="test_sms_mt_sell">
                    <div slot-scope="props">
                      {{props.row.test_sms_mt_sell}}
                    </div>
                </el-table-column>
                -->
                <el-table-column
                    label="Last Status"
                    prop="test_sms_mt_last_status">
                    <div slot-scope="props">
                      <badge :type="props.row.test_sms_mt_last_status.match(/PROCESSING/i) ? 'info' :
                                    props.row.test_sms_mt_last_status.match(/POSITIVE/i) ? 'success' : 'warning'">
                            <span slot="badgeContent">
                                {{props.row.test_sms_mt_last_status}}
                            </span>                    
                        </badge>
                    </div>
                </el-table-column>
                <el-table-column         
                    label="Test Created"
                    prop="test_sms_mt_created_ts">
                    <div slot-scope="props">
                      {{props.row.test_sms_mt_created_ts | fixDate}}
                    </div>
                </el-table-column>
                <el-table-column         
                    label="Options"
                    prop="">
                    <div slot-scope="props">
                      
                    <base-button
                      v-loading="props.row.recently_added"
                      :disabled="/POSITIVE|NEGATIVE/i.test(props.row.test_sms_mt_last_status)"
                      size="sm"
                      type="info"
                      :loading="props.row.loading"
                      @click="refreshRow(props);"
                    >

                      <mdicon v-if="!props.row.loading" name="refresh" size="28" />   
                      
                    </base-button>
                  </div>
                </el-table-column>
            </el-table>
        </div>
    </card>
  </div>
</template>

<script>

import {
    Badge
} from 'src/components';
import {Table, TableColumn, DatePicker} from 'element-ui'
import CountryFlag from 'vue-country-flag'
/*
var endDate = new Date();
var startDate = new Date(endDate.getTime()-7*24*60*60*1000);
*/

export default {
    components: {
        [DatePicker.name]: DatePicker,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        CountryFlag,
        Badge
    },
    props: ['tableData', 'startDate', 'endDate', 'submitInProccess'],
    data(){
      return {
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
          shortcuts: [
            {
                text: 'Last 7 days',
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                },
            },
            {
                text: 'Last 31 days',
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 31);
                    picker.$emit('pick', [start, end]);
                },
            }
          ]
        },
        timeRange: [
        this.startDate,
        this.endDate
        ],
        filteredData:[],
        search:{
          test_sms_mt_operator_name: null,
          test_sms_mt_country_name: null,
          test_sms_mt_mccmnc: null,
          test_sms_receiver: null,
          test_sms_mt_text: null,
          test_sms_sender: null,
          test_sms_acctual_sender: null,
          test_sms_mt_last_status: null
        },
        searchByFields:[
          {
            test_sms_mt_operator_name: null,
            test_sms_mt_country_name: null,
            test_sms_mt_mccmnc: null,
            test_sms_receiver: null,
            test_sms_mt_text: null,
            test_sms_sender: null,
            test_sms_acctual_sender: null,
            test_sms_mt_last_status: null
          }
        ]
      }
    },
    mounted() {
      //this.searchData()
    },
    filters: {
      fixDate(timestamp){
        if(!timestamp) return timestamp
        return new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
            timeZone: 'Europe/Berlin' // Change to your preferred European timezone
        }).format(new Date(timestamp));
      }
    },
    methods:{
      refreshTbl(){
        this.$parent.loadDelifyHistory()
      },
      refreshRow(row){
        this.$emit('refreshResult', row)
      },
      changeDates(dates){
        
        let startDate = new Date(dates[0])
        startDate.setHours(0, 0, 0, 0)
        let endDate = new Date(dates[1])
        endDate.setHours(23, 59, 59, 999)
        
        this.$emit('pickedDate', [
          startDate.toLocaleString('en-US'), 
          endDate.toLocaleString('en-US')
        ])
        
      },
      filterData(row){
        //console.log(row)
        let column_name = row.column.property
        //console.log(column_name)
        /*
        
        this.searchData(
          this.tableData.filter(data => !this.search[column_name] || data[column_name].toLowerCase().includes(this.search[column_name].toLowerCase()) || data.status == 0)
        )
        */
       this.$emit('filterResultsTable', {
         filterBy: column_name,
         filterValue: this.search[column_name],
       })
      },
      searchData(newTableData){
        //this.filteredData = newTableData || this.tableData
      }
    },
}
</script>

<style>

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.el-range-editor .el-range-input {
    background: none;
}
.el-range-input{
    color:white !important
}
</style>