<template>
  <div>
    <h2>Additional information</h2>
    
    <p>
        As soon as we get bank transfer confirmation, your balance will be updated. 
        This process can take up to 3-5 days. <br>
        <br>
        A copy of the invoice will be sent to {{email}} and the payment will be available on the 
        <router-link to="/settings/account" tag="a">  
            Billing
        </router-link>
        
         page.
        <br>
        
    </p>
    <hr v-if="proforma.proforma_invoice_number">
    <card  v-if="proforma.proforma_invoice_number" style="background:none;border:none">
        <template slot="header">
            <h4>Payment Reference {{proforma.proforma_invoice_number}}</h4>
            <h4>
                <router-link to="/billing/history" tag="a">  
                View Invoices
                </router-link>
            </h4>
        </template>
    </card>
  </div>
</template>

<script>
export default {
    props: ['email','proforma']
}
</script>

<style>

</style>