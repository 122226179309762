<template>
  <div class="container">
    <div class="col-lg-6 col-md-6 ml-auto mr-auto">
      <card class="card card-register card-white">
        <template slot="header">
          <img class="card-img" style="max-height:250px" src="/img/card-primary.png" alt="Card image"/>
          <h4 class="card-title">2FA</h4>
        </template>
        <ValidationObserver v-slot="{ handleSubmit, valid }">
          <form @submit.prevent="handleSubmit(submit)">
              <ValidationProvider
              name="2FA code"
              rules="required|numeric|exactly6Digits"
              v-slot="{ passed, failed, errors }"
            >
            <base-input
            :class="{ 
                      'has-success': !errors[0] && code, 
                      'has-danger': errors[0] 
                  }"
              required
              v-model="code"
              type="text"
              label="2FA code">
            </base-input>
            <span  v-html="errors[0]"></span>
          </ValidationProvider>
          <base-button :loading="loading" :disabled="!valid || !recaptcha" native-type="submit" type="primary" round block size="lg">
            Submit 2FA Verification Code
          </base-button>
          
          <base-button :loading="loading || resent" @click=resend() type="primary" round block simple size="lg">
              <i class="fa fa-envelope"></i> Resend code
          </base-button>
          
          <base-button v-if="retry" @click="goretry" type="primary" round block simple size="lg">
              <i class="fa fa-recycle"></i> Try Again
          </base-button>
          <div class="d-flex justify-content-center mt-3">
          <!--
            <VueRecaptcha
                ref="recaptcha"
                :sitekey="siteKey"
                :load-recaptcha-script="true"
                @verify="onVerify"
                @error="onVerifyError"
                @expired="onExpired">
            </VueRecaptcha>
            -->
          </div>
          </form>
        </ValidationObserver>
        <template slot="footer">  
          
        </template>
      </card>
    </div>
  </div>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha';
import { extend } from "vee-validate";
import { required, numeric } from "vee-validate/dist/rules";
extend("required", required);
extend("numeric", numeric);
extend('exactly6Digits', {
  validate(value) {
    return value.length === 6;
  },
  message: 'The field must be exactly 6 digits.',
});

export default {
  components: {
    VueRecaptcha
  },
  data() {
    return {
      siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaTs: null,
      recaptchaDomain: null,
      recaptcha: true,
      loading: false,
      code: null,
      registered: null,
      retry: false,
      resent: false
    };
  },
  mounted(){
  },
  methods: {
    goretry(){
      this.$router.push('/login')
    },
    async submit() {
      try{
        this.retry = false
        
        const login = await this.$store.dispatch('login2FA', {
          code: this.code,
          //user_recaptcha: this.recaptcha,
          //user_recaptcha_host: this.recaptchaDomain

        })

        if(login){
          if(this.$route.query.recover){
            this.$toast.info("Please set a new password");
            this.$router.push({ path: '/login', query: { recover: true } })
          }else{
            this.$toast.success("Logged in succefully");
            const nextPage = await this.$store.dispatch('nextPage')
            this.$router.push(nextPage)
          }
          
        }

      }catch(e){
        this.retry = !this.retry
        if(e){
          console.log(e)
          //const message = e.message ? e.message : e
          this.$toast.error(e)
        }
        
      }
    },
    async resend(){
      if(this.resent){
        this.$toast.warning('A verification code was sent, please check your email')
        return
      }
      
      this.resent = !this.resent

      setTimeout(() => {
        this.resent = !this.resent
      }, 5000);

      try{
        const retrySent = await this.$store.dispatch('retry2FA', {})
        if(retrySent){
          this.$toast.info('Please check your inbox')
        }
      }catch(e){
        if(e){
          const {message} = e
          this.$toast.error(message)
        }
        this.$router.push('/login')
      }
    },
    onExpired(){
      this.recaptcha = false
    },
    async onVerify(token){

      const res = await this.$http.post(`${process.env.VUE_APP_BASE_DOMAIN_URI}/auth/google/recaptcha/verify`, {
          token: token
      })  
      
      if(res && res.status == 200){
        this.recaptcha = res.data.success
        this.recaptchaTs = res.data.challenge_ts
        this.recaptchaDomain = res.data.hostname

      }
    },
    onVerifyError(error){
        console.log(error)
    },
  }
};
</script>
<style>
</style>
