<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-7">
                    <h3 style="margin:0"><u>Campaign Name, Description  {{ keys.length > 1 ? 'and token' : '' }}</u></h3>
                    <small>Provide a name and a description for this campaign {{ keys.length > 1 ? 'and token.' : '.' }}</small>
                </div>
            </div>
            
            <br/>
            
            <div class="row">
                <div class="col-lg-4">
                    <ValidationObserver ref="observer" v-slot="{ valid }">
                        <form>
                            <div class="row">
                                <div class="col-lg-12">
                                    <ValidationProvider v-if="keys.length > 1" rules="required" v-slot="{ passed, failed, errors }">
                                        <el-select 
                                            class="w-100 token-select"
                                            v-model="token" 
                                            value-key="value"
                                            label="Token"
                                            placeholder="Token">
                                            <el-option
                                                v-for="item in keys"
                                                :key="item.http_api_jwt_short_token"
                                                :label="`${item.http_api_jwt_short_token} (${item.http_api_jwt_description || '-'})`"
                                                :value="item.http_api_jwt_short_token">
                                            </el-option>
                                        </el-select>
                                        <span class="validation-error">{{errors[0]}}</span>  
                                    </ValidationProvider>
                                    <ValidationProvider
                                        rules="required"
                                        v-slot="{ passed, failed, errors }">
                                        <base-input
                                            label="Name"
                                            v-model="campaignName"
                                            :class="{  'has-success': passed,  'has-danger': !passed }"
                                            type="text"
                                            placeholder="Name"
                                        />
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">                    
                    <label>Description</label>
                    <base-textarea
                        :class="{  
                        'borderRadius': true
                        }"
                        type="text"
                        label=""
                        placeholder="Description"
                        v-model="campaignDescription">
                    </base-textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import {Select, Option} from 'element-ui'
extend("required", {
  ...required,
  message: 'This field is required'
});
export default {
    components:{
        [Select.name]: Select,
        [Option.name]: Option,
    },
    data() {
        return {
            campaignName: "My name",
            campaignDescription: null,
            token: null
        }
    },
    props:{
        keys:{
            type: Array,
            default: () => []
        }
    },
    methods: {
        async validate() {
            
            const valid =  await this.$refs.observer.validate()
            
            if(valid){
                this.$emit('on-validated', true, { 
                    name: this.campaignName, 
                    campaignDescription: this.description, 
                    campaignToken: this.token });
                return true
            }else{
                this.$emit('on-validation-error', false, {});
                return false
            }
            
        }
    }
}
</script>

<style>
    .token-select .el-input.is-disabled .el-input__inner {
        background: #1d253b !important;
        color: rgb(125, 100, 100) !important;
    }

    .token-select .el-input__inner {
        background: #1d253b !important;
        color: white !important;
    }
</style>