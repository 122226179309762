<template> 
  <card>
    <h5 slot="header" class="title">2FA Verification</h5>
    <ValidationObserver v-slot="{ handleSubmit,valid }">
    <form  @submit.prevent="handleSubmit(update)">
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <span class="label d-block mb-1">
              Enable 2FA Verification
            </span>
            <base-switch :disabled="loading" v-model="userr.user_enable_2fa" @input="update" type="primary" on-text="yes" off-text="no" >
            </base-switch>
          </div>
        </div>  
      </div>    
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <base-radio :disabled="true" name="email" v-model="userr.user_2fa_type" @input="update">Verification via Email</base-radio>
          </div>
          <div class="form-group" >
            <base-radio :disabled="true" name="sms" v-model="userr.user_2fa_type" @input="update" >Verification via SMS</base-radio>
          </div>
        </div>  
      </div>    

    </form>
    </ValidationObserver>
  </card>
</template>
<script>
import { BaseCheckbox, BaseRadio, BaseSwitch } from 'src/components/index';
import Loading from 'src/components/LoadingPanel.vue';

export default {
  name: 'profile-form',
  components:{
    BaseSwitch,
    BaseCheckbox, 
    BaseRadio,
    Loading
  },
  props:{
    user: {
      type: Object,
      default: () => {}
    }
  },
  watch:{
    user: {
      handler(nv){
        this.userr = {...nv}
      },
      deep:true
    }
  },
  data() {
    return {
      loading: false,
      userr: {
        user_2fa_type: null,
        user_enable_2fa: null
      }
    };
  },
  methods: {
    //toggle(){
    //  console.log(this.userr.user_enable_2fa)
    //},
    //type(){
    //  console.log(this.userr.user_2fa_type)
    //  /*
    //  this.submitInProccess = !this.submitInProccess;
    //  const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/users/update-user-password`, {
    //    user_password: this.user.newPassword
    //  })
    //  this.submitInProccess = !this.submitInProccess;
    //  if(res && res.status == 200){
    //    this.$toast.success("Password updated");
    //    this.$store.dispatch('logout')
    //    this.$router.push('/login')
    //  } 
    //  */
    //},
    async update() {
      
      this.loading = !this.loading
      const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/users/update/one`, this.userr)
      this.loading = !this.loading
      if(res && res.status == 200){
        //this.$toast.success('done')
      }
    }

  }
};
</script>
<style>
.input-group-text{
  padding:0 15px 0 0 !important;
}

.validation-error{
  color:bisque !important;
  font-size:12px
}
.list-group-item{
  background:none;
  border:none;
}
</style>
