<template> 
  <div class="row">
    <div class="col-lg-6">
      <card>
        <h5 slot="header" class="title">Change password</h5>
        <ValidationObserver v-slot="{ handleSubmit,valid }">
        <Loading v-if="submitInProccess"/>
        <form v-else @submit.prevent="handleSubmit(update)">
          
          <div class="row" v-if="hasCurrentPassword">
            <div class="col-lg-12">
              <ValidationProvider 
                name="currentPassword"
                rules="required|passwordValidator"
              v-slot="{ errors }">
              <base-input
                :class="{ 
                    'has-success': !errors[0] && user.currentPassword, 
                    'has-danger': errors[0] 
                }"

                toggleIcon
                addonRightIcon="eye"
                addonRightIconToggle="eye-off"
                type="password"
                label="Current Password"
                placeholder="Enter Current Password"
                :error="errors[0]"
                v-model="user.currentPassword"
              >
              </base-input>
              </ValidationProvider>
            </div>  
          </div>  
          
          <div class="row">
            <div class="col-lg-12">
              <ValidationProvider 
                name="password"
                rules="required|passwordValidator"
              v-slot="{ errors }">
              <base-input
                :class="{ 
                    'has-success': !errors[0] && user.newPassword, 
                    'has-danger': errors[0] 
                }"

                toggleIcon
                addonRightIcon="eye"
                addonRightIconToggle="eye-off"
                type="password"
                label="New Password"
                placeholder="Enter New Password"
                :error="errors[0]"
                v-model="user.newPassword"
              >
              </base-input>
              </ValidationProvider>
            </div>  
          </div>          
          <div class="row">
            <div class="col-lg-12"
            >
              <ValidationProvider
                rules="required|confirmed:password"
                v-slot="{ errors }"
              >
              <base-input
                :class="{ 
                    'has-success': !errors[0] && user.verifyNewPassword, 
                    'has-danger': errors[0] 
                }"
                toggleIcon
                addonRightIcon="eye"
                addonRightIconToggle="eye-off"
                type="password"
                name="password"
                label="Verify New Password"
                placeholder="Verify New Password"
                :error="errors[0]"
                v-model="user.verifyNewPassword"
              >
              </base-input>
              
              </ValidationProvider>

            </div>  
          </div>
          <base-button :loading="submitInProccess" simple :disabled="!valid" native-type="submit" type="primary" class="btn-fill">
            Update password
          </base-button>
        </form>
        </ValidationObserver>
      </card>
    </div>
    <div class="col-lg-6">
      <TWOFALogin :user="user"/>
    </div>
  </div>
</template>
<script>
import Loading from 'src/components/LoadingPanel.vue';
import TWOFALogin from './2FALogin.vue';

import { extend } from "vee-validate";
import { required,numeric,digits,email,confirmed } from "vee-validate/dist/rules";
extend("email",email)
extend("digits",digits)
extend("numeric",numeric)
extend("confirmed",{
  ...confirmed,
  message: 'Passwords do not match'
})

extend("required", {
  ...required,
  message: 'This field is required'
});

extend("passwordValidator", {
  params: ["password"],
  validate: (value) => {    
    //return value.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{8,}$/);
    //(?=.*[A-Z]) look at entire string, evaluate true if Find a capital letter (assertion behaves like bollean and is ignored for captures)
    //(?=.*[a-z]) look at entire string, evaluate true if  Find a lower case letter
    //(?=.*\d) look at entire string, evaluate true if  Find a lower case letter
    // all three act like and operator. 
    // [A-Za-z0-9]{8,} count entire string is 8 chars long

    return value.match(/(?=.*[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/])(?=.*[A-Z])(?=.*\d)[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/A-Za-z0-9]{8,}/);
  },
  message:` Password must be at least 8 charachters long it must have: one number, one capital letter, one special charachter.`
});

export default {
  name: 'profile-form',
  components:{
    TWOFALogin,
    Loading
  },
  props: {
    //profileObject:{
    //  type: Object,
    //  description: 'User profile data'
    //}
  },
  mounted:function(){ 
    //this.user = this.profileObject;
  },
  computed: {
    
  },
  data() {
    return {
      submitInProccess: false,
      user: {
        currentPassword: null,
        newPassword: null
      },
      hasCurrentPassword: true
    };
  },
  methods: {
    async fetch(){
      this.submitInProccess = !this.submitInProccess;
      const res = await this.$http.get(`${process.env.VUE_APP_API_ENDPOINT}/users/read/one`)
      this.submitInProccess = !this.submitInProccess;
      if(res && res.status == 200){
        this.user = {...res.data}
        this.hasCurrentPassword = res.data.hasPassword
        
        
      }
    },
    async update(){
      try{
        this.submitInProccess = !this.submitInProccess;
        const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/users/update/password`, this.user)
        this.submitInProccess = !this.submitInProccess;
        if(res && res.status == 200){
          this.$toast.success("Password updated, please login");
          await this.$store.dispatch('logout')
          setTimeout(() => { this.$router.push('/login') }, 1000);

        }
      }catch(e){
        this.submitInProccess = !this.submitInProccess;
      }
      
      
    }

  }
};
</script>
<style>
.input-group-text{
  padding:0 15px 0 0 !important;
}

.validation-error{
  color:bisque !important;
  font-size:12px
}
.list-group-item{
  background:none;
  border:none;
}
</style>
