<template>
   
    
    <div class="stages-wrapper">
        <div class="row h-100 ml-0 mr-0">
            <div class="col-lg-6 d-none d-sm-none d-md-none d-lg-block" style="border-right:1px solid">
                <div class="h-100 d-flex align-items-center justify-content-end p-4">
                    <div class="d-block">
                        <h3>Tell us about you</h3>
                        <p class="text-info">We'll use this info to customize your experience</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="h-100 right-column">
                    <div class="h-100 d-flex align-items-center justify-content-start p-4">
                        <div class="d-block w-100">
                            <div v-if="loading" class="local-spinner">
                                <i class="fa fa-spinner fa-spin"></i>
                            </div>

                            <div v-else>
                                <h3>Your Details</h3>
                                <br/>
                                
                                <Form @submit="submit"/>
                                <base-button :loadin="loading" @click="skip" simple defult>
                                    Skip for now
                                </base-button>
                                <!--
                                <router-link 
                                    class="btn btn-muted btn-simple" 
                                    to="/about/action" tag="button">  
                                    Skip for now
                                </router-link>
                                -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

             
        </div>
    </div>
    

</template>

<script>
import Form from './Form.vue'

export default {
    components: {
        Form
    },
    beforeMount(){
   
    },
    mounted(){
        
    }, 
    methods:{
        async skip(){
            try{
                this.loading = !this.loading
                
                const res = await this.$http.post( `${process.env.VUE_APP_API_ENDPOINT}/users/update/one`,{
                    user_confirm_is_developer: true,
                    user_is_developer: false
                })

                this.loading = !this.loading

                if(res && res.status == 200){
                    const setType = await this.$store.dispatch('developer', {
                        user_confirm_is_developer: true,
                        user_is_developer: false
                    })

                    if(setType){
                        const nextPage = await this.$store.dispatch('nextPage')
                        this.$router.push(nextPage)
                    }
                }
                
            }catch(e){
                this.loading = !this.loading
            }
            
        },
        async submit(isCorporate){

            if(isCorporate){
                this.$router.push('/about/organization')
            }else{
                this.$router.push('/about/action')
            }
            
        }
    },
    data(){
        return {
            loading: false
        }
    }
}
</script>

<style>
    .right-column {
        max-width:430px;
    } 

    @media (max-width: 767px) {
        .w-sm-100 {
            width:  100% !important;
        }
    }

    @media (max-width: 992px) {
        .stages-wrapper .right-column{
            margin-left: auto;
            margin-right: auto;
        }
    }
    
</style>