<template>
 <card class="card-login card-white" showFooterLine>
    <template slot="header">
      <img class="card-img" style="max-height:250px" src="/img/card-primary.png" alt="Card image"/>
      <h4 class="card-title">Login</h4>
    </template>

    <base-button 
        @click=EmailLogin() 
        type="primary" 
        round 
        block 
        size="lg"
        class="mt-5">
        Login with Email
    </base-button>

    <base-button @click=GoogleLogin() type="primary" round block simple size="lg">
        <i class="fab fa-google"></i> Login with Google
    </base-button>
    <!--
    <base-button @click=FacebookLogin() type="primary" round block simple size="lg">
        <i class="fab fa-facebook"></i> Login with Facebook
    </base-button>
    -->
    <template slot="footer">
        Don't have an account? 
        <router-link class="nav-item"  to="/signup"> Signup</router-link>
        <h5 class="text-danger d-flex justify-content-center"  v-if="loginErrorText">
        {{loginErrorText}}
        </h5>

    </template>
</card>  
</template>

<script>
const handleMessage = (e, callback) => {
  if (typeof callback === 'function' && e.data.auth === 'passport') { callback(e.data); }
}
const auth_endpoint = process.env.VUE_APP_BASE_DOMAIN_URI

export default {
    components: {

    },
    data() {
        return {
            loginErrorText: "",
            auth_endpoint: auth_endpoint
        };
    },
    created: async function () {
        try{    
            await this.$store.dispatch('logout')
        }catch(e){
            const msg = e.message ? e.message : e
            this.$toast.error(msg)
        }

        window.addEventListener('message', this.handleOAuthMessageCb);
    },
    destroyed: function() {
        window.removeEventListener('message', this.handleOAuthMessageCb);
    },
    methods: {
        GoogleLogin(){
            
            window.open(auth_endpoint + "/auth/google/login", 
            'targetWindow', 
            `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=400px,height=500px`);
        },
        FacebookLogin(){
            window.open(auth_endpoint + "/auth/facebook/login", 'targetWindow',`toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=700px,height=900px`);
        },
        EmailLogin() {
            this.$emit('emailLogin', true);
        },
        handleOAuthMessageCb: function(e) {
            return handleMessage(e, async (data) => {          
                try{
                    let { user, token, info, account } = data
                    const nextPage = await this.$store.dispatch('loginFBGGL', {user, token, info, account})
                    this.$router.push(`${nextPage}`)
                }catch(e){
                    const msg = e.message ? e.message : e
                    this.$toast.error(msg)
                }
            
            });
        }
    },
};
</script>
<style></style>
