<template>
  
  
    <card class="card card-login  card-white">
    <template slot="header">
      <img class="card-img" style="max-height:250px" src="/img/card-primary.png" alt="Card image"/>
      <h4 class="card-title">Reset</h4>
    </template>
    
      <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
         <div class="row">
            <div class="col-lg-12">
              <ValidationProvider 
                name="confirm"
                rules="required|passwordValidator"

              v-slot="{ errors }">
              <base-input
                :class="{ 
                    'has-success': !errors[0] && new_password, 
                    'has-danger': errors[0] 
                }"

                toggleIcon
                addonRightIcon="eye"
                addonRightIconToggle="eye-off"
                type="password"
                label="Enter new password"
                placeholder="Enter new password"
                :error="errors[0]"
                v-model="new_password"
              >
              </base-input>
              
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
               <ValidationProvider
                 rules="required|password:@confirm"
                v-slot="{ errors }"
              >
              <base-input
                :class="{ 
                    'has-success': !errors[0] && verify_new_password, 
                    'has-danger': errors[0] 
                }"
                toggleIcon
                addonRightIcon="eye"
                addonRightIconToggle="eye-off"
                type="password"
                label="Verify new password"
                placeholder="Verify new password"
                :error="errors[0]"
                v-model="verify_new_password"
              >
              </base-input>
              </ValidationProvider>
            </div>
          </div>

        <div class="category form-category">* Required fields</div>
      <base-button :loading="loading" native-type="submit" type="primary" round block size="lg">
          Reset password
        </base-button>
      </form>
      </ValidationObserver>
      <template slot="footer">
        
        <div class="d-flex justify-content-left">
          <base-button @click="back" simple small round type="primary" class="border-0 pl-0">  
            <i class="tim-icons icon-minimal-left"></i> 
            &nbsp;&nbsp;&nbsp;  Login options
          </base-button>
        </div>
        
        
      </template>
    </card>
  
 
</template>
<script>

import { extend } from "vee-validate";
import { required, email, confirmed } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("email", email);
extend("required", required);
extend("confirmed", confirmed);

extend("required", {
  ...required,
  message: 'This field is required'
});

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Passwords do not match'
});

extend("passwordValidator", {
  params: ["password"],
  validate: (value) => {    
    //return value.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{8,}$/);
    //(?=.*[A-Z]) look at entire string, evaluate true if Find a capital letter (assertion behaves like bollean and is ignored for captures)
    //(?=.*[a-z]) look at entire string, evaluate true if  Find a lower case letter
    //(?=.*\d) look at entire string, evaluate true if  Find a lower case letter
    // all three act like and operator. 
    // [A-Za-z0-9]{8,} count entire string is 8 chars long

    return value.match(/(?=.*[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/])(?=.*[A-Z])(?=.*\d)[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/A-Za-z0-9]{8,}/);
  },
  message:` Password must be at least 8 charachters long it must have: one number, one capital letter, one special charachter.`
});

export default {
  data() {
    return {
      loading: false,
      new_password: "",
      verify_new_password: ""
    };
  },
  props:['token'],
  methods: {
    back: function () {
        this.$emit('emailLogin', false);
    },
    async submit() {
      
      try{
        this.loading = !this.loading
        const reset = await this.$store.dispatch('reset', {
          newPassword: this.new_password
        })

        this.loading = !this.loading
        if(reset){
          this.$toast.info(`Password reset complete, Please login`);
          this.back()
        }
      }catch(e){
        this.loading = !this.loading
        this.$toast.error(e)
        this.back()
      }
    },
    /*
    submitd() {
      const local_auth_base_url = process.env.VUE_APP_LOCAL_AUTH_ENDPOINT
      //this.$http.post('/auth/reset',{
      this.$http.post(local_auth_base_url + '/reset',{
        new_password: this.new_password,
        reset_token: this.token
      }).then((res)=>{

        this.$toast.success("Password was reset");

        this.$emit('emailLogin', {
          emailLogin: false,
          recoverPassword: false,
          showRecoverToken: false
        });

      }).catch((err)=>{

      })
      //alert("Form has been submitted!");
    }
    */
  }
};
</script>
<style>
.input-group-text{
  padding:0 15px 0 0 !important;
}


.validation-error-register{
  color:rgb(12, 9, 6) !important;
  font-size:12px
}

.list-group-item{
  background:none;
  border:none;
  color:rgb(12, 9, 6) !important;
  padding:0 !important;
}

</style>
