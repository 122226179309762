<template>
    <modal
        class="filter-model"
        style="padding:25px;"
        :show.sync="show" 
        size="xl" 
        type="notice"
        @close="close">

        <h4 slot="header" class="title title-up">
            Selecet contacts for this campaign <br>
            <small>
                Use filters to target specific contacts
            </small>
        </h4>
        
        <div class="row">
            <div class="col-lg-12">
                <h6 class="text-muted">
                    <u>Filters:</u>
                    &nbsp;
                    <el-tooltip content="Filter contacts using the values you have provided" effect="light" :open-delay="300" placement="top">
                        <i class="fa fa-question-circle text-info" aria-hidden="true"></i>
                    </el-tooltip>
                </h6>
                
            </div>
        </div>
        

        <base-button :disabled="pending" class="filter-button" size="sm" type="primary" @click="addFitler()">
            <i class="fa fa-filter"></i> Add filter
        </base-button>
        
        
        <div v-if="filters.length">
            <FilteredRecipients v-for="(item, index) in filters" :key="index" @close="removeFilter(item)" :setting="item"/>
        </div>
        <p v-else>
            <i class="fa fa-exclamation" aria-hidden="true"></i> &nbsp;  No filters have been selected
        </p>
        
        <span v-for="(item, index) in filters" :key="index">
            <FilterRecipients 
                @settings="handleSettings" 
                @cancel="removeFilter(item)"
                :index="index"
                :cfg="item" 
                v-if="!item.applied"
            />
        </span>
        <hr>
        <div class="row">
            <div class="col-lg-12">
                <base-button
                :loading="loading"
                @click="load()"
                native-type="submit" 
                type="primary" 
                style="width:100%"
                class="btn-fill">
                    Get contacts
                </base-button>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-lg-12">
                <h4 v-if="proccecing.length" class="text-primary">
                    <i class="fa fa-exclamation" aria-hidden="true"></i>&nbsp;&nbsp;
                    <u>Some Groups are still processing</u>   
                </h4>
                <div v-for="(item, index) in proccecing" :key="item.groups_name" class="text-primary"> 
                    <p style="font-size: 14px;">
                        <i class="fa fa-hourglass spin" style="font-size: 10px;"></i> &nbsp;&nbsp;
                        <span> {{ item.groups_name }} </span>contact group is still processing. <br>
                        
                    </p>
                </div>
                <p v-if="proccecing.length" class="text-primary">
                    <i class="fa fa-exclamation" aria-hidden="true"></i>&nbsp;&nbsp;
                    <u>To access any of the above groups please wait untill they are ready</u>
                </p>
                <br>
                <p class="text-info" v-if="contactCount == 0 ">
                    Click on Get contacts to see the total recipients available. 
                </p>
                <div v-else-if="loading">
                    <i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Loading...
                </div>
                <p v-else>
                    <i class="fa fa-exclamation" aria-hidden="true"></i> &nbsp;  No filters have been selected. <br>
                    <i class="fa fa-users" aria-hidden="true"></i> &nbsp; {{contactCount}} recipients. <br>
                    <base-checkbox v-model="sendToAll" >Send To All</base-checkbox>
                </p>
                <div v-if="!sendToAll && contactCount">

                    <ValidationProvider
                        ref="amountValidator"
                        :rules='`contactMax:{"contactCount":${contactCount}}`'
                        v-slot="{ passed, failed, errors }">

                        <el-tooltip 
                            content="Limit the amount you wish to send" 
                            effect="light" :open-delay="300" placement="top">
                            <base-input 
                                label="Amount To Send:"
                                :disabled="loading"
                                textMuted
                                type="number"
                                min="1"
                                :max="contactCount"
                                placeholder="Amount to send"
                                v-model="contacts">
                            </base-input>
                        </el-tooltip>
                        <span class="local-danger">{{ errors[0] }}</span>

                    </ValidationProvider>

                </div>                
            </div>
        </div>

        <template slot="footer">
            
            <base-button
            :disabled="!contacts"
            @click="done()"
            native-type="submit" 
            type="primary" 
            class="btn-fill">
                Confirm
            </base-button>
            
      </template>
    </modal>
</template>

<script>
import { Modal } from 'src/components';
import FilterRecipients from '../Components/Filter.vue'
import FilteredRecipients from '../Components/Filtered.vue'
import { extend } from "vee-validate";


extend("contactMax", {
  validate (value, { contactCount }) { 
    const obj = JSON.parse(contactCount)
    const valid = value > 1 && value <= obj.contactCount
    return valid
  },
  params: ['contactCount'],
  computesRequired: true,
  message: 'Please select a number greated then 1 and lower then the total.'
});

export default {
    components: {
        Modal,
        FilterRecipients,
        FilteredRecipients
    },
    watch: {
        'sendToAll': function (nv,ov) {
            if(nv) this.contacts = this.contactCount
            else this.contacts = null
        }
    },
    data() {
        return {
            show: false,
            loading: false,
            filters: [],
            contactCount: 0,
            sendToAll: false,
            contacts: null,
            pending: false,
            proccecing: []
        }
    },
    methods: {
        setContacts(){
            this.contacts = this.contactCount
        },
        async load(){
            this.loading = !this.loading
            const api_endpoint = process.env.VUE_APP_API_ENDPOINT
            const res = await this.$http.post(`${api_endpoint}/contacts/lists/total`, this.filters)
            if(res && res.status == 200){
                this.contactCount = res.data.count
                this.loading = !this.loading
                //return true
            }
                
        },
        close(){
            //this.contactCount =0
            //this.contacts= null
            //this.filters = []
            //this.sendToAll=false
            //
            
            this.loading = false
            this.filters = []
            this.contactCount = 0
            this.sendToAll = false
            this.contacts = null
            this.pending = false
        },
        async loadCurrentListStatus(){
            const res = await this.$http.get(`${process.env.VUE_APP_API_ENDPOINT}/contacts/lists/proccecing`)
            if(res && res.status == 200){
                this.proccecing = res.data
            }
        },
        toggle(){
            this.loadCurrentListStatus()
            this.show = !this.show
            this.close()
        },
        handleSettings(setting){
            this.pending = !this.pending
            this.filters[setting.index].fieldName  = setting.fieldName,
            this.filters[setting.index].fieldAction  = setting.fieldAction,
            this.filters[setting.index].fieldValue  = setting.fieldValue,
            this.filters[setting.index].applied = setting.applied
            this.filters[setting.index].index = setting.index
        },

        removeFilter(setting){
            this.pending = false
            if(this.filters[setting.index]) {
                this.filters.splice(setting.index, 1)
            }else{
                this.filters.splice(0, 1)
            }
        },

        addFitler(){
            this.pending = !this.pending
            const filterCfg = {
                fieldName: null,
                fieldAction: null,
                fieldValue: null,
                applied: false,
                index: this.filters.length
            }
            this.filters.push(filterCfg)
        },
        async done(){
            let valid = false
            
            if(this.sendToAll){
                valid = true
            } else {
                const validator = await this.$refs.amountValidator.validate() 
                valid = validator.valid
            } 

            if(!valid) return
            
            this.$emit('contacts', {
                filters: this.filters,
                count: this.contacts,
                total: this.contactCount
            })
            this.toggle()
            
        }
    }
}
</script>

<style>
    .spin {
    display: inline-block;
    animation: spin 2s linear infinite; /* Adjust the duration (2s) and speed (linear) as needed */
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }


    .filter-button{
        font-size: 12px !important;
    }
    .filter-model .modal-content {
        min-height: 600px;
    }
</style>