<template>
  <div class="w-100">
    <div class="row">
      <div class="col-lg-3">
        <card style="">
          <img slot="image" class="card-img-top card-image" 
          src="/img/rest-api.png" 
          alt="Card image cap"/>
          <h4 class="card-title">SMS HTTP API <small>Documentation</small></h4>
          <p class="card-text">
            Dig into our SMS APIs. <br>
            Learn how to send SMS messages and receive delivery reports.
          </p>
          <a target="blank" href="https://apidocs.message-deck.com/API/sendsms/" class="btn btn-primary">
            Go to docs
          </a>
        </card>
      </div>
      <div class="col-lg-3">
        <card style="">
          <img slot="image" 
          class="card-img-top card-image" 
          src="img/smpp-api.png" 
          
          alt="Card image cap"/>
          <h4 class="card-title">
            SMS SMPP API <small>Documentation</small>
            &nbsp;
            <badge type="success">
                <span slot="badgeContent">BETA</span>
            </badge>
          </h4>
          <p class="card-text">
            The SMPP (Short Message Peer-to-Peer) protocol is an open, industry standard protocol designed to provide a flexible data communications interface.
          </p>
          <a target="blank" href="https://apidocs.message-deck.com/API/smpp/" class="btn btn-primary" >
            Go to docs
          </a>
        </card>
        
      </div>
      <!--
      <div  class="col-lg-3">
        <card style="">
          <img 
          slot="image" class="card-img-top card-image" 
          src="/img/hlr-api.png" 
          alt="Card image cap"/>
          <h4 class="card-title">
            HLR Lookup API
            <badge type="info">
                <span slot="badgeContent">COMING SOON</span>
            </badge>
          </h4>
          
          <p class="card-text">
            HLR Lookup is a service that dynamically contacts a central database that contains details of each 
            mobile phone subscriber on the network.
          </p>
          <a target="blank" href="https://apidocs.message-deck.com/API/hlr/" class="btn btn-primary" disabled>
            Go to docs
          </a>
        </card>
        
      </div>
      <div  class="col-lg-3">
        <card style="">
          <img 
          slot="image" class="card-img-top card-image" 
          src="/img/delify-api.png" 
          alt="Card image cap"/>
          <h4 class="card-title">
            Delify API
            <badge type="info">
                <span slot="badgeContent">COMING SOON</span>
            </badge>
          </h4>
          
          <p class="card-text">
            Delify allows you to test your messages before sending them on thousands of real world SIM Cards connected to the network.
          </p>
          <a target="blank" href="https://apidocs.message-deck.com/API/hlr/" class="btn btn-primary" disabled>
            Go to docs
          </a>
        </card>
        
      </div>
      -->
    </div>
  </div>
  
</template>

<script>
import {Badge} from 'src/components'
export default {
  components:{
      Badge
  },
}
</script>

<style>



</style>