<template>
    <div>
        <base-table :data="data" thead-classes="text-primary">
            <template slot="columns" slot-scope="{ columns }">
                <th>Period</th>
                <th>Days in period</th>
                <th>Months in period</th>
                <th>Hour</th>
                <th>Minute</th>
            </template>
            <template slot-scope="{ row, index }">
                <td>{{row[0].name == 'dayInMonth' ? 'Monthly' : 'Weekly' }}</td>
                <td>{{ getNumericDayToName(row[0]) || 1 }}</td>
                <td>{{ getNumericMonthToName(row[1]) || 1 }}</td>
                <td>{{ parseHour(row[2]) }}</td>
                <td>{{ parseMinute(row[3]) }}</td>
            </template>
        </base-table>
        
    </div>
</template>

<script>

const numericDayToName = require('../json/numericDayToName.json')
const numericMonthToName = require('../json/numericMonthToName.json')
import { BaseTable } from '@/components';
/**
 * {
  "label": "Day Of Week",
  "short": "Day",
  "name": "dayOfWeek",
  "list": "daysByName",
  "range": true,
  "tempo": "specific",
  "value": 6,
  "ranges": {
    "start": null,
    "end": null
  }
}

 */
export default {
    components: {
        BaseTable
    },
    data() {
        return {
            table:[],
            cronsArray: [],
            period: 'Weekly'
        }
    },
    props:{
        data: {
            Type: Object,
            default: () => {return []}
        }
    },
    watch:{
        'data': { 
            handler: function (ov,nv){ 
            }, 
            deep: true 
        }
    },
    methods: {
        parseMinute(item){
            if(!item) return
            if(item.tempo == 'specific'){
                return `${item.value.toString().padStart(2, '0')}`
            }else if(item.tempo == 'every'){
                return `Every minute`
            }else{
                return `${item.ranges.start.toString().padStart(2, '0')} to ${item.ranges.end.toString().padStart(2, '0')}`
            }
        },
        parseHour(item){
            if(!item) return
            if(item.tempo == 'specific'){
                return `${item.value.toString().padStart(2, '0')}`
            }else if(item.tempo == 'every'){
                return `Every hour`
            }else{
                return `${item.ranges.start.toString().padStart(2, '0')} to ${item.ranges.end.toString().padStart(2, '0')}`
            }
        },
        getNumericMonthToName(item){
            if(!item) return
            if(item.tempo == 'specific'){
                return `${numericMonthToName[item.value].label}`
            }else if(item.tempo == 'every'){
                return `Every month`
            }else{
                return `${numericMonthToName[item.ranges.start].label} to ${numericMonthToName[item.ranges.end].label}`
            }
        },
        getNumericDayToName(item){
            if(!item) return
            if(item.tempo == 'specific'){
                if(numericDayToName[item.value]){
                    return `${numericDayToName[item.value].label}`
                }else{
                    return `${item.value}`
                }
                
            }else if(item.tempo == 'every'){
                return `Every day`
            }else{
                if(numericDayToName[item.ranges.start] && numericDayToName[item.ranges.end]){
                    return `${numericDayToName[item.ranges.start].label} to ${numericDayToName[item.ranges.end].label}`
                }else{
                    return `${item.ranges.start} to ${item.ranges.end}`
                }
                
            }
        }
    }
}
</script>

<style>

</style>