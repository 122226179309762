<template>
    <div>
        <ValidationObserver  v-slot="{ handleSubmit, valid }">
            <form @submit.prevent="handleSubmit(submit)">
                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <ValidationProvider
                            rules="required|entity_name:2"
                            v-slot="{ passed, failed, errors }">
                            <label>
                                Your first name<br>
                                <small class="text-muted">Of your new account</small>
                            </label>
                            <base-input
                                :class="{  'has-success': passed,  'has-danger': failed }"
                                type="text"
                                placeholder="Your name"
                                v-model="form.first_name"
                            >
                            </base-input>
                        <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <ValidationProvider
                            rules=""
                            v-slot="{ passed, failed, errors }">
                            <label>
                                Your last name<br>
                                <small class="text-muted">Of your new account</small>
                            </label>
                            <base-input
                                :class="{  'has-success': passed,  'has-danger': failed }"
                                type="text"
                                placeholder="Your last name"
                                v-model="form.last_name"
                            >
                            </base-input>
                        <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <ValidationProvider
                            rules="phone"
                            v-slot="{ passed, failed, errors }">
                            <label>
                                Your phone number (International Format)<br>
                                <small class="text-muted">For support, 2FA authentication and notifications</small>
                            </label>
                            <base-input
                                :class="{  'has-success': passed,  'has-danger': failed }"
                                type="text"
                                placeholder="Your phone number"
                                v-model="form.phone"
                            >
                            </base-input>
                        <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <ValidationProvider
                            rules=""
                            v-slot="{ passed, failed, errors }">
                            <label>
                                Your Skype ID <br>
                                <small class="text-muted">For direct support assistance</small>
                            </label>
                            <base-input
                                :class="{  'has-success': passed,  'has-danger': failed }"
                                type="text"
                                placeholder="Skype id"
                                v-model="form.skype_id"
                            >
                            </base-input>
                        <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>

                <hr>

                <div class="row mt-2">
                    <div class="col-lg-12 col-sm-12">
                        <ValidationProvider
                            rules="required"
                            v-slot="{ passed, failed, errors }">
                            <label class="d-block">
                                Account type <br>
                                <small class="text-muted">Personal/Business account</small>
                            </label>
                           
                            <base-checkbox  v-model="form.is_corporate" :checked="form.is_corporate" inline style="display:block">
                                Business account
                            </base-checkbox>
                            <base-checkbox @input="form.is_corporate = false" :checked="form.is_corporate == false" inline style="display:block">
                                Personal account
                            </base-checkbox>

                            <span v-if="failed" class="validation-error text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <hr>
                <div class="row mt-2">
                    <div class="col-lg-12 col-sm-12">
                        <ValidationProvider
                            rules="required"
                            v-slot="{ passed, failed, errors }">
                            <label class="d-block">
                                Are you a developer? <br>
                                <small class="text-muted">Are you a software developer</small>
                            </label>
                           
                            <base-checkbox v-model="form.is_developer" inline style="display:block">
                            Im a Software Developer
                            </base-checkbox>


                            <span v-if="failed" class="validation-error text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <hr>
                <base-button native-type="submit" type="primary" class="btn-fill">
                    Save and continue
                </base-button>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { extend } from "vee-validate";
import { required,numeric,min,max,email, phone } from "vee-validate/dist/rules";
import { Select, Option, Checkbox } from 'element-ui'

extend("entity_name",{
  ...min,
  message: 'Name is too short'
})
extend("required", {
  ...required,
  message: 'This field is required'
});

extend("phone", {
  validate: (value) => {    
    return value.match(/^\+?[0-9]{10,15}$/);
  },
  message:`Please enter a valid phone number.`
});



export default {
    components:{
        [Select.name]: Select,
        [Checkbox.name]: Checkbox,
        [Option.name]: Option
    },

    data() {
        return {
            loading: false,
            accountType: null,
            form: {
                first_name: null,
                last_name: null,
                phone: null,
                skype_id: null,
                is_corporate: null,
                is_developer: false
            }
           /*
            form: {
                "entity_name": "veloxbit",
                "entity_website": "https://veloxbit.com",
                "entity_country": {
                    "country": "Cyprus",
                    "abbreviation": "CY"
                },
                "entity_developer": "Marketing"
            }
            */
        }
    },
    methods: {
        toggleType(value){
            console.log(value)
        },
        async submit(){
            try{
                
                const { user_account_uuid } = this.$store.getters.user
                
                
                const updates = await Promise.all([
                    await this.$http.post( `${process.env.VUE_APP_API_ENDPOINT}/users/update/one`, {
                        user_first_name: this.form.first_name,
                        user_last_name: this.form.last_name,
                        user_phone_number: this.form.phone,
                        user_skype_id: this.form.skype_id,
                        user_is_developer: this.form.is_developer,
                        user_is_corporate: this.form.is_corporate,
                        user_confirm_is_developer: true
                    } ),
                    await this.$http.post( `${process.env.VUE_APP_API_ENDPOINT}/gateway/accounts/update`, {
                        client_account_type:  this.form.is_corporate ? 'buisness' : 'personal',
                        client_accounts_cliend_uuid: user_account_uuid
                    } )
                ])
                const userDetails = updates[0].data
                const userAccount = updates[1].data
                
                const details = await this.$store.dispatch('userDetails',{
                    userDetails: userDetails,
                    userAccount: userAccount
                })
                
                if(details){
                    const isCorporate = userAccount.client_account_type != 'personal'
                    this.$emit('submit', isCorporate)
                }
                
            }catch(e){
                this.$toast.error(e.message)
            }
            
        }
    }
}
</script>

<style>

</style>