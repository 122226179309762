<template>
    <div>    
        <el-table
            id="campaigns-table"
            @sort-change="onSortChange"
            @filter-change="onFilterChange"
            :data="tblRows"
            style="width: 100%;margin-bottom: 20px;min-height:600px">
            <el-table-column v-for="col in cols" :key="col.label"
                :prop="col.prop"
                :label="col.label"
                :filters="colFilters(col.prop)"
                :filter-method="filterHandler"
                sortable>
                <template slot-scope="scope">
                    {{ scope.row[col.prop] }}
                </template>
            </el-table-column>
            
            <el-table-column label="Comments">
                {{ comments }}
            </el-table-column>
            <el-table-column :label="downLoadButtonLabel">
                <template slot-scope="scope">

                    <base-button  v-for="option in options" :key="option.id"
                        :loading="loading"
                        @click="option.method(scope.row)"
                        style="display: inline-block;"
                        round type="default" size="sm">
                            <mdicon :name="option.icon" :size="option.iconSize" />
                    </base-button>

                </template>
            </el-table-column>
        </el-table>
        <div class="d-flex justify-content-center">
            <base-pagination 
                :page-count="pageCount" 
                v-model="currentPage">
            </base-pagination>
        </div>
    </div>
</template>

<script>
import {BasePagination} from 'src/components'
import { Table, TableColumn, Button, Pagination, Select, Option, Tag } from 'element-ui'
export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Button.name]: Button,
        [Pagination.name]: Pagination,
        [Select.name]: Select,
        [Option.name]: Option,
        [Tag.name]: Tag,
        BasePagination
    },
    props: {
        comments:{
            type: String,
            default: ""
        },
        loading:{
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default: () => []
        },
        rows:{
            type: Array,
            default: () => []
        },
        cols:{
            type: Array,
            default: () => []
        },
        onSortChange: {
            type: Function,
            default: () =>{}
        },
        onFilterChange: {
            type: Function,
            default: () =>{}
        },
        downLoadButtonLabel: {
            type: String,
            default: ""
        }
    },
    data(){
        return {
            pageSize: 10,
            currentPage: 1,
            filterData: null,
            filterRows: []
        }
    },
    watch:{
        filterData: {
            handler: (nv)=> {
                console.log(nv)
            },
            deep: true
        }
    },
    computed: {
        'pageCount': function(){
            if(this.rows.length){
                return Math.ceil( this.rows.length / this.pageSize)
            }
            return 1
        },
        'tblRows': function(){
            
            //if(this.filterData){
            //    //return this.rows.slice( ( this.currentPage - 1 ) * this.pageSize, this.currentPage * this.pageSize )
            //    return this.rows.filter(row => row['proforma_invoice_wave_invoice_number'] == this.filterData || row['proforma_invoice_number'] == this.filterData)
            //}
            if(this.filterRows.length){
                console.log(this.filterRows)
                const rows = [...this.filterRows]
                console.log(rows)
                return rows
            }

            return this.rows.slice( ( this.currentPage - 1 ) * this.pageSize, this.currentPage * this.pageSize )
            
        },
    },
    methods: {
        colFilters(column){
            switch (column) {
                case 'proforma_invoice_wave_invoice_number':
                    return this.rows.map(invoice => { return { text: invoice['proforma_invoice_wave_invoice_number'], value: invoice['proforma_invoice_wave_invoice_number'] } })
                case 'proforma_invoice_number':
                    return this.rows.map(invoice => { return { text: invoice['proforma_invoice_number'], value: invoice['proforma_invoice_number'] } })
                default:
                    return []
            }            
        },
        filterHandler(value, row, column){
           const property = column['property']
           return row[property] == value
           
        }
    }
}
</script>

<style>

</style>