<template>
    <card type="chart" style="padding:10px">
        <div class="row">
            
            <div class="col-lg-12">
                <base-alert type="info" dismissible icon="tim-icons icon-email-85">
                    Are you interested in sending SMS campaigns to the United States? 
                    &nbsp;&nbsp;
                    <router-link 
                        class="btn btn-primary btn-sm" 
                        to="/campaigns/ten-dlc" 
                        tag="button">  
                        Click here
                    </router-link>
                </base-alert>
                <simple-wizard 
                    :nextButtonDisabled="nextButtonDisabled"
                    @wizzard-complete="wizardComplete"
                    :isReset="false"
                    :resetText="null">
                    <template slot="header">
                        <h3 class="card-title">New SMS Campaign</h3>
                    </template>
       
                    <wizard-tab 
                        :nextButtonDisabled="nextButtonDisabled"
                        :before-change="() => validateStep('step1')">
                        <template slot="label">
                            <strong class="fa-stack-1x">1</strong>
                            <p>Name</p>
                        </template>
                        <Name
                            ref="step1"
                            :keys="keys"
                            @validated="stepValidated('step1')"
                            @on-validation-error="onValidatatiopError"
                            @on-validated="onStepValidated" 
                        />
                    </wizard-tab>        

                    <wizard-tab 
                        :nextButtonDisabled="nextButtonDisabled"
                        :before-change="() => validateStep('step2')">
                        <template slot="label">
                            <strong class="fa-stack-1x">2</strong>
                            <p>Audience</p>
                        </template>
                        <Recipients 
                            :loading="loading"
                            :contactCount="contactCount"
                            ref="step2"
                            @validated="stepValidated('step2')"
                            @on-validation-error="onValidatatiopError"
                            @on-validated="onStepValidated" 
                        />
                    </wizard-tab>   

                    <wizard-tab :before-change="() => validateStep('step3')">
                        <template slot="label">
                            <strong class="fa-stack-1x">3</strong>
                            <p>Schedule</p>
                        </template>
                        <Schedule 
                            ref="step3"
                            @validated="stepValidated('step3')"
                            @on-validation-error="onValidatatiopError"
                            @on-validated="onStepValidated" 
                        />
                    </wizard-tab>

                     <wizard-tab :before-change="() => validateStep('step4')">
                        <template slot="label">
                            <strong class="fa-stack-1x">4</strong>
                            <p>Message</p>
                        </template>
                        <Message 
                            ref="step4"
                            @linkInMsg="linkInMsg"
                            @validated="stepValidated('step4')"
                            @on-validation-error="onValidatatiopError"
                            @on-validated="onStepValidated" 
                        />
                    </wizard-tab>

                    
                    <wizard-tab v-if="hasMediaLink" :before-change="() => validateStep('step5')">
                        <template slot="label">
                            <strong class="fa-stack-1x">5</strong>
                            <p>MEDIA (LINK)</p>
                        </template>
                        <Tracking 
                            ref="step5"
                            @validated="stepValidated('step5')"
                            @on-validation-error="onValidatatiopError"
                            @on-validated="onStepValidated" 
                        />
                    </wizard-tab>

                    <wizard-tab :before-change="() => validateStep(`step${summeryStepNumber}`)">
                        <template slot="label">
                            <strong class="fa-stack-1x">{{summeryStepNumber}}</strong>
                            <p>Summery</p>
                        </template>
                        <Summery 
                            :ref="`step${summeryStepNumber}`"
                            :campaign="modal"
                            @validated="stepValidated(`step${summeryStepNumber}`)"
                            @on-validation-error="onValidatatiopError"
                            @on-validated="onStepValidated" 
                        />
                    </wizard-tab> 

                    

                </simple-wizard>
            </div>
        </div>
    </card>
  
</template>

<script>
import { SimpleWizard, WizardTab, BaseAlert } from 'src/components';
import Name from './Wizzard/Name.vue'
import Message from './Wizzard/Message.vue'
import Recipients from './Wizzard/Recipients.vue'
import Tracking from './Wizzard/Tracking.vue'
import Schedule from './Wizzard/Schedule.vue'
import Summery from './Wizzard/Summery.vue'



export default {
    components: {
        SimpleWizard,
        WizardTab,
        BaseAlert,
        Name,
        Message,
        Recipients,
        Tracking,
        Schedule,
        Summery
    },
    data() {
        return {
            summeryStepNumber: 5,
            hasMediaLink: false,
            showNextButton: true,
            activeTabIndex: null,
            tabCount: null,
            hideNext: true,
            modal: {},
            contactCount: 0,
            loading:false,
            nextButtonDisabled: false,
            keys: []
        };
    },
    mounted(){
        this.getKeys()
    },
    methods: {
        linkInMsg(exists){
            this.hasMediaLink = exists
            this.summeryStepNumber = exists ? 6 : 5
        },
        async getKeys(){
            
            //const res = await this.$http.get(`${api_endpoint}/routing/token/read`)
            const res = await this.$http.get(`${process.env.VUE_APP_API_ENDPOINT}/apis/keys/campaign/read`)
            if(res && res.status == 200) {
                this.keys = res.data
            }
        },
        onStepValidated(validated, modal){
            this.modal = { ...this.modal, ...modal }
        },
        onValidatatiopError(){},
        async confirmRecipients(ref){
            
            const recipients = this.$refs[ref].getData();
            
            //if(recipients.length) {
                this.loading = !this.loading
                const api_endpoint = process.env.VUE_APP_API_ENDPOINT
                const res = await this.$http.post(`${api_endpoint}/routing/lists/campaigns`, recipients)
                if(res && res.status == 200){
                    this.contactCount = res.data.count
                    this.loading = !this.loading
                    //return true
                }
                
            //}

            return true

        },
        validateStep(ref) {
            
            const valid = this.$refs[ref].validate();
            //console.log(`valid: ${valid} ${ref}`)

            if(valid){
                if(ref == 'step1'){
                    this.showNextButton = false
                }else{
                    this.showNextButton = true
                }
                return valid
            }

        },
        stepValidated(){},
        async wizardComplete(){
            
            
            let action = 'create-with-token'
            
            if(this.keys.length){
                const token = this.keys[0].http_api_jwt_short_token
                action = 'create'
                this.modal = {...this.modal, ...{token:token}}
            }

            

            const res = await this.$http.post( `${process.env.VUE_APP_API_ENDPOINT}/campaigns/${action}` , this.modal, { })
            if(res && res.status == 200){
                if(res.data) this.$router.push('/campaigns/list');
            }
        }
    }
}
</script>

<style>

</style>
