<template>
    <card>
         <template slot="header">
            <h4 class="card-title">Global Statistics Glance</h4>
            <h5 class="card-category">Global View Of Your Campaigns </h5>
        </template>
        <div class="row">
            <div class="col-lg-12">

                <WorldMap  ref="map" class="map map-big" />
                <i v-if="loading" class="fa fa-spinner fa-spin" style="color:white"></i>
                <div v-if="!data.length && !loading" style="min-height: 400px" class="d-flex justify-content-center" >
                    <p>
                    <i class="fa fa-exclamation-triangle" style="color:white; font-size: 16px"></i> &nbsp;
                        No data to show. Consider expending your search dates
                    </p>
                </div>
            </div>
        </div>
    </card>
</template>

<script>
import WorldMap from './WorldMapBase.vue';
const TwolettertoThreeletter = require('../../../../json/../../json/2letterto3letter.json')

export default {
    components: {
        WorldMap
    },
    props: ['data', 'loading'],
    watch:{
        'data': function(nv) {      
            this.mapData = Object.values(nv).reduce((hash, val) => {  
                const sent = Number(val.sent)  || 0
                const clicks = Number(val.clicks)  || 0
                const spent = Number(val.spent) || 0

                if(hash[TwolettertoThreeletter[val.ccode]]){
                    hash[TwolettertoThreeletter[val.ccode]].sent += sent
                    hash[TwolettertoThreeletter[val.ccode]].clicks += clicks
                    hash[TwolettertoThreeletter[val.ccode]].spent += spent
                }else{
                    hash[TwolettertoThreeletter[val.ccode]] = {
                        sent: sent,
                        clicks: clicks,
                        spent: spent
                    }
                }
                return  hash
            }, {})

            this.$refs.map.initVectorMap(this.mapData);
        }
    },
    data(){
        return {
            mapData: {},
        }
    },
    async mounted() {
        //this.$refs.map.initVectorMap();
    }
}
</script>

<style>

</style>