<template>
    <div>
        <div class="row justify-content-center">
            <h2>Welcome to the payment wizzard</h2>
            
        </div>
        <div class="row justify-content-center">
            <p>please follow this wizzard step by step tp complete your payment</p>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        validate() {
            this.$emit("on-validated", true, {});
            return true
        }
    }
}
</script>

<style>

</style>