<template>
  <div>
    <card>
        <template slot="header">
            <h4 class="card-title">Details</h4>
            <p class="card-category">Total by country by operator</p>
            <base-button size="sm" type="default" >
                <download-csv :data = "FilterChartData">
                    <mdicon name="file-export-outline" size="18"/>
                </download-csv>                
            </base-button>
        </template>
        
        <div class="row">
            <div class="col-md-12 ml-auto mr-auto">
                <div class="table-responsive">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td></td>
                                <td>Country</td>
                                <td>Operator</td>
                                <td>Sent</td>
                                <td>Sent Parts</td>
                                <td>Sent Cost</td>
                                <td>Received</td>
                                <td>Received Parts</td>
                                <td>Received Cost</td>
                                
                            </tr>
                            
                            <tr v-for="(item, index) in FilterChartData" :key="index">

                                <td><country-flag :country='item.country' size='normal'/></td>
                                <td>{{countryCodes[item.country] ? countryCodes[item.country].name : item.country}}</td>
                                <td>{{item.operator}}</td>
                                <td>{{item.sent}}</td>
                                <td>{{item.parts}}</td>
                                <td>{{item.cost.toLocaleString('en', {maximumFractionDigits: 4})}}</td>
                                
                                <td v-if="item.operator!='UNKNOWN'">0</td>
                                <td v-else>{{ moByCcode[item.country] ? moByCcode[item.country].sent : 0 }}</td>

                                <td v-if="item.operator!='UNKNOWN'">0</td>
                                <td v-else>{{ moByCcode[item.country] ? moByCcode[item.country].parts : 0 }}</td>

                                <td v-if="item.operator!='UNKNOWN'">0</td>
                                <td v-else>{{ moByCcode[item.country] ? moByCcode[item.country].cost : 0 }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        
    </card>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import DownloadCsv from 'vue-json-csv'
export default {
    components: {
        CountryFlag,
        DownloadCsv
    },
    props:['FilterChartData', 'countryCodes', 'moByCcode'],
}
</script>

<style>

</style>