<template>
 <card class="card-register card-white" style="min-height:530px;" showFooterLine>
    <template slot="header">
      <img class="card-img" style="max-height:250px" src="/img/card-primary.png" alt="Card image"/>
      <h4 class="card-title">Signup</h4>
    </template>
    
    <div v-if="!socialRegisterMustAgreeShow">    
        <base-button 
            @click=EmailSignUp() type="primary" round block size="lg">
            Signup with Email
        </base-button>

        <base-button 
        @click=GoogleSignUp() type="primary" round block simple size="lg">
            <i class="fab fa-google"></i> Signup with Google
        </base-button>
        <!--
        <base-button 
        @click=FacebookSignUp() type="primary" round block simple size="lg">
            <i class="fab fa-facebook"></i> Signup with Facebook
        </base-button>
        -->
    </div>
    <div v-if="socialRegisterMustAgreeShow">    
        <register-social-must-agree :registeredData="registeredData" @socialTermsConfirmed="SocialSignUpTermsConfirmed"/>
    </div>

    <template slot="footer">
        Already have an account? 
        <router-link class="nav-item"  to="login"> Login</router-link>
        <h5 class="text-danger d-flex justify-content-center"  v-if="registerErrorText">
            {{registerErrorText}}
        </h5>
    </template>
    
</card>  
</template>

<script>


const handleMessage = (e, callback) => {
  if (typeof callback === 'function' && e.data.auth === 'passport') { callback(e.data); }
}
const base_url = process.env.VUE_APP_BASE_DOMAIN_URI
import RegisterSocialMustAgree from './Register/RegisterSocialMustAgree.vue';
      
export default {
    components: {
        RegisterSocialMustAgree
    },
    data() {
        return {
            blah: this.$route.query,
            registerErrorText: "",
            registeredData: { user: { user_account_uuid: null, user_email: null } },
            socialRegisterMustAgreeShow: false
        };
    },
    created: async function () {
        
        if(this.$store.getters.user && 
            Object.keys(this.$store.getters.user).length){

            const { user_account_uuid, user_email, user_confirm_terms } = this.$store.getters.user
            this.registeredData = {
                user: {
                    user_account_uuid: user_account_uuid,
                    user_email: user_email
                }
                
            }

            if(!user_confirm_terms || user_confirm_terms == "null"){
                this.socialRegisterMustAgreeShow = true
            }else{
                try{    
                    await this.$store.dispatch('logout')
                }catch(e){
                    const msg = e.message ? e.message : e
                    this.$toast.error(msg)
                }

            }
                

        }
        

        window.addEventListener('message', this.handleOAuthMessageCb);
    },
    destroyed: function() {
        window.removeEventListener('message', this.handleOAuthMessageCb);
    },
    methods: {
        
        GoogleSignUp(){
            //window.open("http://localhost/auth/google/register", 'targetWindow', `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=400px,height=500px`);
            window.open(base_url + '/auth/google/register', 'targetWindow', `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=400px,height=500px`);
            
            },
        FacebookSignUp(){
            //window.open("http://localhost/auth/facebook/register", 'targetWindow',`toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=700px,height=900px`);
            window.open(base_url + '/auth/facebook/register', 'targetWindow', `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=400px,height=500px`);
            },
        EmailSignUp() {
            this.$emit('emailSignUp', true);
        },
        async SocialSignUpTermsConfirmed(confirmed){
            
            try{

                if(confirmed == true){
                    const storeConfirmUser = await this.$store.dispatch('confirm', { 
                        user_confirm_terms: confirmed 
                    } )

                    if(storeConfirmUser){
                        this.$router.push(`/login`)
                    }

                    return
                    
                }

                throw new Error(confirmed)
            }catch(e){
                const msg = e.message ? e.message : e
                console.log(msg)
                //this.$toast.error('Something went wrong')
                if(msg == 404) await this.$store.dispatch('logout')
            }

        },
        handleOAuthMessageCb: function(e) {
            return handleMessage(e, async data => {  


                try{
                    const { user, token, info } = data      
                    const nextPage = await this.$store.dispatch('loginFBGGL', {user, token, info})
                    if(nextPage == 'signup'){
                        this.socialRegisterMustAgreeShow = !data.info.error
                        this.registeredData = data
                    }else{
                        this.$router.push(`/${nextPage}`)
                    }
                }catch(e){
                    const msg = e.message ? e.message : e
                    this.$toast.error(msg)
                }

                /*
                
                //console.log(data);
                return
                if(data.info.error){
                    this.registerErrorText = data.info.message ? data.info.message:"";
                    this.$toast.error("Unable to register");
                }else{

                }
                */

            });
        }
    }
};
</script>
<style></style>
