<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 mr-auto">
        <register-options v-if="!emailSignUp" @emailSignUp="updateEmail"/>
        <register-form v-if="emailSignUp" @emailSignUp="updateEmail"/>
      </div>
      <div class="d-none d-md-block col-md-6 ml-auto">
      
        <div class="info-area info-horizontal mt-2">
          <div class="icon icon-warning">
            <mdicon name="account-group-outline" size="34" />
          </div>
          <div class="description">
            <h3 class="info-title">Reach Your Audience</h3>
            <p class="description">
              Text messages is the premier most effective tool to instantly reach existing and potential 
              audiences 
            </p>
          </div>
        </div>
        <div class="info-area info-horizontal">
          <div class="icon icon-primary">
            <mdicon name="cellphone-message" size="34" />
          </div>
          <div class="description">
            <h3 class="info-title">The Ultimate Messaging Solution</h3>
            <p class="description">
              Ever evolving features, dynamic routing and much more. <br>
              A scalable, global and efficient messaging solution.
            </p>
          </div>
        </div>
        <div class="info-area info-horizontal">
          <div class="icon icon-info">
            <mdicon name="code-tags" size="34" />
          </div>
          <div class="description">
            <h3 class="info-title">API</h3>
            <p class="description">
              With our robust APIs businesses can engage their customers instantly, 
              easily integrate your existing systems with our API's.
            </p>
          </div>
        </div>
        <div class="info-area info-horizontal">
          <div class="icon icon-info">
            <mdicon name="chart-bar" size="34" />
          </div>
          <div class="description">
            <h3 class="info-title">Analytics</h3>
            <p class="description">
              Powerful real time analytics, enabling you to monitor your messaging performance with actionable insights and 
              key metrics instantly.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import RegisterForm from './Register/RegisterForm';
import RegisterOptions from './RegisterOptions';


/*
import { BaseCheckbox } from 'src/components';
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";


extend("email", email);
extend("required", required);
*/
export default {
  components: {
    
    RegisterForm,
    RegisterOptions
  },
  data() {
    return {
      emailSignUp: false
    };
  },
created: function () {
  //console.log(RegisterForm);
},
  methods: {
   
    /*
    submit() {
      alert("Form has been submitted!");
    }
    */
    updateEmail (value) { 
      this.emailSignUp = !this.emailSignUp;
    }
  }
};
</script>
<style>





</style>
