<template>
  <card>
    <template slot="header">
        <h3 class="card-title">Canceled Payments</h3>
        <p class="card-category">Payments that have been canceled</p>
        <!--
        <router-link to="/billing/funds"
            tag="a" 
            class="btn btn-primary btn-simple">
            Add Funds
        </router-link>
        -->
    </template>

    <Table 
      :cols="cols" 
      :rows="rows"
      comments="Payment is canceled"
      :options="[]"
    />
    
  </card>
</template>

<script>
import Table from '../Table.vue'
export default {
    components:{
        Table
    },
    props:{
        
        rows: {
            type: Array
        }
    },
    data(){
        return {
            loading: false,
            cols: [
                //{
                //    prop: 'proforma_invoice_wave_invoice_number',
                //    label: 'Invoice Number'
                //},
                {
                    prop: 'proforma_invoice_number',
                    label: 'Proforma Number'
                },
                {
                    prop: 'proforma_invoice_payment_type',
                    label: 'Payment Type'
                },
                {
                    prop: 'proforma_invoice_sub_total',
                    label: 'Amount'
                },
                {
                    prop: 'proforma_invoice_transaction_fee',
                    label: 'Fees'
                },
                {
                    prop: 'proforma_invoice_total_tax1',
                    label: 'Taxes (VAT)'
                },
                {
                    prop: 'proforma_invoice_total',
                    label: 'Total'
                },
                {
                    prop: 'proforma_invoice_date',
                    label: 'Issue Date'
                }
            ]
        }
    },
    methods:{
        async download(row){
            window.open(row.proforma_invoice_wave_download_link, '_blank');
        }
    }
}
</script>

<style>

</style>