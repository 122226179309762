<template>
<div class="w-100">
  <div class="row">
    <div class="col-lg-6" v-if="gettignStarted">
        <GettingStarted @confirm="gotIt" />
    </div>
    <div class="col-lg-12" v-else>
        <Wizzard />
    </div>
    <div class="col-lg-4">
        
    </div>
  </div>
</div>  
</template>

<script>
import GettingStarted from './GettingStarted.vue'
import Wizzard from './Wizzard.vue'
export default {
    components:{
        Wizzard,
        GettingStarted
    },
    data() {
        return {
            gettignStarted: false        
        };
    },
    methods: {
        gotIt(){
            // Update user profile dont show again
            this.gettignStarted = !this.gettignStarted
        }
    }
}
</script>

<style>

</style>