var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var valid = ref.valid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required|entity_name:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',[_vm._v(" Your first name"),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v("Of your new account")])]),_c('base-input',{class:{  'has-success': passed,  'has-danger': failed },attrs:{"type":"text","placeholder":"Your name"},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}}),_c('span',{staticClass:"validation-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',[_vm._v(" Your last name"),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v("Of your new account")])]),_c('base-input',{class:{  'has-success': passed,  'has-danger': failed },attrs:{"type":"text","placeholder":"Your last name"},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}}),_c('span',{staticClass:"validation-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',[_vm._v(" Your phone number (International Format)"),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v("For support, 2FA authentication and notifications")])]),_c('base-input',{class:{  'has-success': passed,  'has-danger': failed },attrs:{"type":"text","placeholder":"Your phone number"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('span',{staticClass:"validation-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',[_vm._v(" Your Skype ID "),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v("For direct support assistance")])]),_c('base-input',{class:{  'has-success': passed,  'has-danger': failed },attrs:{"type":"text","placeholder":"Skype id"},model:{value:(_vm.form.skype_id),callback:function ($$v) {_vm.$set(_vm.form, "skype_id", $$v)},expression:"form.skype_id"}}),_c('span',{staticClass:"validation-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('hr'),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-lg-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"d-block"},[_vm._v(" Account type "),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v("Personal/Business account")])]),_c('base-checkbox',{staticStyle:{"display":"block"},attrs:{"checked":_vm.form.is_corporate,"inline":""},model:{value:(_vm.form.is_corporate),callback:function ($$v) {_vm.$set(_vm.form, "is_corporate", $$v)},expression:"form.is_corporate"}},[_vm._v(" Business account ")]),_c('base-checkbox',{staticStyle:{"display":"block"},attrs:{"checked":_vm.form.is_corporate == false,"inline":""},on:{"input":function($event){_vm.form.is_corporate = false}}},[_vm._v(" Personal account ")]),(failed)?_c('span',{staticClass:"validation-error text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_c('hr'),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-lg-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{staticClass:"d-block"},[_vm._v(" Are you a developer? "),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v("Are you a software developer")])]),_c('base-checkbox',{staticStyle:{"display":"block"},attrs:{"inline":""},model:{value:(_vm.form.is_developer),callback:function ($$v) {_vm.$set(_vm.form, "is_developer", $$v)},expression:"form.is_developer"}},[_vm._v(" Im a Software Developer ")]),(failed)?_c('span',{staticClass:"validation-error text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_c('hr'),_c('base-button',{staticClass:"btn-fill",attrs:{"native-type":"submit","type":"primary"}},[_vm._v(" Save and continue ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }