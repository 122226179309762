<template>
    <div>
        <ValidationObserver  v-slot="{ handleSubmit, valid }">
            <form @submit.prevent="handleSubmit(submit)">
                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <ValidationProvider
                            rules="required|entity_name:2"
                            v-slot="{ passed, failed, errors }">
                                <base-input
                                    :class="{ 
                                        'has-success': !errors[0] && billing.billing_entity_name, 
                                        'has-danger': errors[0] || !billing.billing_entity_name 
                                    }"
                                    type="text"
                                    label="Buisness Name"
                                    placeholder="Buisness Name"
                                    v-model="billing.billing_entity_name">
                                </base-input>
                            <span class="validation-error text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <ValidationProvider
                            rules="required|regs:5"
                            v-slot="{ passed, failed, errors }">
                            <base-input
                            :class="{ 
                                'has-success': !errors[0] && billing.billing_entity_registration_number, 
                                'has-danger': errors[0] || !billing.billing_entity_registration_number 
                            }"
                            type="text"
                            label="Registration number"
                            placeholder="Entity registration number"
                            v-model="billing.billing_entity_registration_number"
                            >
                            </base-input>
                            <span class="validation-error text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <ValidationProvider
                        rules="required|taxvat:5"
                        v-slot="{ passed, failed, errors }">
                        <base-input
                        :class="{ 
                            'has-success': !errors[0] && billing.billing_entity_vat_number, 
                            'has-danger': errors[0] || !billing.billing_entity_vat_number 
                        }"
                        type="text"
                        label="VAT number"
                        placeholder="Entity VAT number"
                        v-model="billing.billing_entity_vat_number"
                        >
                        </base-input>
                        <span class="validation-error text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                    <ValidationProvider
                        rules="required|taxvat:5"
                        v-slot="{ passed, failed, errors }">
                        <base-input
                        :class="{ 
                            'has-success': !errors[0] && billing.billing_entity_tax_number, 
                            'has-danger': errors[0] || !billing.billing_entity_tax_number 
                        }"
                        type="text"
                        label="TAX id (Same as VAT id?)"
                        placeholder="Entity TAX id"
                        v-model="billing.billing_entity_tax_number"
                        >
                        </base-input>
                        <span class="validation-error text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <div class="form-group has-label">
                        <label>Country</label>
                        <div class="mb-0">
                            <ValidationProvider rules="required" v-slot="{ passed, failed, errors }">
                            <base-input
                                style="display:none"
                                type="text"
                                v-model="billing_country.country">
                            </base-input>                                 
                            
                            <el-select 
                                filterable
                                v-model="billing_country" 
                                value-key="abbreviation"
                                label="Country"
                                placeholder="Country">
                                <el-option
                                v-for="item in countries"
                                :key="item.abbreviation"
                                :label="item.country"
                                :value="item">
                                </el-option>
                            </el-select>
                            <span v-if="!billing_country.country" class="validation-error text-danger">Please select a country</span>  
                            </ValidationProvider>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <ValidationProvider
                            rules="required|city:3"
                            v-slot="{ passed, failed, errors }">
                            <base-input
                            :class="{ 
                                'has-success': !errors[0] && billing.billing_entity_city, 
                                'has-danger': errors[0] || !billing.billing_entity_city 
                            }"
                            type="text"
                            label="City"
                            placeholder="City"
                            v-model="billing.billing_entity_city"
                            >
                            </base-input>
                            <span class="validation-error  text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <ValidationProvider
                            rules="required|min:3|max:10"
                            v-slot="{ passed, failed, errors }">
                            <base-input
                            :class="{ 
                                'has-success': !errors[0] && billing.billing_entity_postal_code, 
                                'has-danger': errors[0] || !billing.billing_entity_postal_code 
                            }"
                            type="text"
                            label="Postal code"
                            placeholder="Postal code"
                            v-model="billing.billing_entity_postal_code"
                            >
                            </base-input>
                            <span class="validation-error  text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <ValidationProvider
                            rules="required|addr:5"
                            v-slot="{ passed, failed, errors }">
                            <base-input

                            :class="{ 
                                'has-success': !errors[0] && billing.billing_entity_address_one, 
                                'has-danger': errors[0] || !billing.billing_entity_address_one 
                            }"
                            type="text"
                            label="Address one"
                            placeholder="Address one"
                            v-model="billing.billing_entity_address_one"
                            >
                            </base-input>
                            <span class="validation-error text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <ValidationProvider
                            rules="addr:5"
                            v-slot="{ passed, failed, errors }">
                            <base-input

                            :class="{ 
                                'has-success': !errors[0] && billing.billing_entity_address_two, 
                                'has-danger': errors[0] || !billing.billing_entity_address_two 
                            }"
                            type="text"
                            label="Address two"
                            placeholder="Address two"
                            v-model="billing.billing_entity_address_two"
                            >
                            </base-input>
                            <span class="validation-error text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <br/>
                <base-button :loading="loading" native-type="submit" type="primary" class="btn-fill">
                    Save and continue
                </base-button>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { extend } from "vee-validate";
import { required,numeric,min,max,email, phone } from "vee-validate/dist/rules";
import { Select, Option } from 'element-ui'
import countries from 'country-json/src/country-by-abbreviation.json';
import continents from '../../../../json/country-continent.json';

extend("min",{
  ...min,
  message: 'Number is too short'
})
extend("max",{
  ...max,
  message: 'Number is too long'
})


extend("city",{
  ...min,
  message: 'City is too short'
})

extend("addr",{
  ...min,
  message: 'Address is too short'
})

extend("taxvat",{
  ...min,
  message: 'Tax/VAT is too short'
})

extend("regs",{
  ...min,
  message: 'Registraion is too short'
})

extend("entity_name",{
  ...min,
  message: 'Name is too short'
})
extend("required", {
  ...required,
  message: 'This field is required'
});

extend("phone", {
  validate: (value) => {    
    return value.match(/^\+?[0-9]{10,15}$/);
  },
  message:`Please enter a valid phone number.`
});



export default {
    components:{
        [Select.name]: Select,
        [Option.name]: Option
    },

    data() {
        return {
            loading: false,
            countries: countries,
            continents: continents,
            billing_country: {
                country: null,
                abbreviation: null,
                continent:  null
            },
            billing: {
                billing_entity_currency: null,
                billing_entity_name: null,
                billing_entity_registration_number: null,
                billing_entity_vat_number: null,
                billing_entity_tax_number: null,
                billing_entity_email: null,
                billing_entity_phone_number: null,
                billing_entity_country_name: null,
                billing_entity_country_code: null,
                billing_entity_city: null,
                billing_entity_address_one: null,
                billing_entity_address_two: null,
                billing_entity_postal_code: null,
                billing_accounts_uuid: null,
                billing_entity_country_continent: null
            }
        }
    },
    methods: {
        async submit(){
            try{
                this.loading = !this.loading
                
                
                const { country, abbreviation} = this.billing_country
                const { 
                    client_account_currency,
                    client_accounts_cliend_uuid
                } = this.$store.getters.account

                const {
                    user_email
                } = this.$store.getters.user

                this.billing.billing_entity_country_continent =this.continents[abbreviation]
                this.billing.billing_accounts_uuid = client_accounts_cliend_uuid
                this.billing.billing_entity_currency = client_account_currency
                this.billing.billing_entity_country_code = abbreviation
                this.billing.billing_entity_country_name = country
                this.billing.billing_entity_email = user_email
                //this.billing.billing_entity_phone_number

                const res = await this.$http.post(`${process.env.VUE_APP_API_ENDPOINT}/billing/update`, this.billing)
                this.loading = !this.loading
                if(res && res.status == 200){
                    //const billing = await this.$store.dispatch('companyDetails', res.data)
                    this.$emit('submit', this.form)
                }

            }catch(e){
                this.loading = !this.loading
                this.$toast.error(e.message)
            }
            

            
        }
    }
}
</script>

<style>

</style>