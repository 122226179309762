<template>
    <div class="row">
        <div class="col-lg-4">
            <card class="card-chart">
                <template slot="header">
                    <h4 class="card-title">Top 5 Countries Clicks</h4>
                    <h5 class="card-category">Clicks In Period</h5>
                </template>
                <div class="chart-area">
                    <i v-if="loading" class="fa fa-spinner fa-spin" style="color:white"></i>
                    <div v-else-if="filteredCountries.length">
                        <table class="table">
                            <thead>
                                <th style="width:20px"></th>
                                <th style="width:100px">Country</th>
                                <th style="width:40px">Total</th>
                                <th style="width:40px">% Of total</th>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in filteredCountries.slice(0,5)" :key="index">
                                    <td><country-flag :country="item.country" size='normal'/></td>
                                    <td>{{countryCodes[item.country] ? countryCodes[item.country].name : item.country }}</td> 
                                    <td>{{item.total}}</td> 
                                    <td>{{ ( ( ( total ? item.total / total : 0 ) * 100 ) || 0 ).toFixed(2) }}%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-flex justify-content-center" v-else>
                        <p>
                            <i class="fa fa-exclamation-triangle" style="color:white; font-size: 16px"></i> &nbsp;
                            No data to show. Consider expending your search dates
                        </p>
                    </div>
                </div>
            </card>
        </div>
        <div class="col-lg-4">
            <card class="card-chart">
                <template slot="header">
                    <h4 class="card-title">
                        Top 5 Cities Clicks
                    </h4>
                    <h5 class="card-category">Clicks In Period</h5>
                </template>
                <div class="chart-area">
                    
                    <i v-if="loading" class="fa fa-spinner fa-spin" style="color:white"></i>
                    
                    <div v-else-if="filteredCities.length">
                        <table class="table">
                            <thead>
                                <th style="width:20px"></th>
                                <th style="width:100px">City</th>
                                <th style="width:40px">Total</th>
                                <th style="width:40px">% Of total</th>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in filteredCities.slice(0,5)" :key="index">
                                    <td><country-flag :country="item.country" size='normal'/></td>
                                    <td>{{item.city}}</td> 
                                    <td>{{item.total}}</td> 
                                    <td>{{ ( ( ( item.total / total ) * 100 ) || 0 ).toFixed(2) }} %</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-flex justify-content-center" v-else>
                        <p>
                            <i class="fa fa-exclamation-triangle" style="color:white; font-size: 16px"></i> &nbsp;
                            No data to show. Consider expending your search dates
                        </p>
                    </div>
                </div>
            </card>
        </div>
        <div class="col-lg-4">
            <card class="card-chart" >
                <template slot="header">
                    <h4 class="card-title">
                        Clicks By Device
                    </h4>
                    <h5 class="card-category">Clicks In Period</h5>
                </template>
                <div class="chart-area">
                    <i v-if="loading" class="fa fa-spinner fa-spin" style="color:white"></i>
                    <div v-else-if="filteredPlatform.length">
                        <table class="table">
                            <thead>
                                <th style="width:100px">Device</th>
                                <th style="width:40px">Total</th>
                                <th style="width:40px">% Of total</th>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in filteredPlatform.slice(0,5)" :key="index">
                                    <td style="padding:10px 0px 5px 15px !important">{{item.platform}}</td> 
                                    <td>{{item.total}}</td> 
                                    <td>
                                        <span v-if="item.total">
                                            {{ ( item.total / total ).toFixed(2) * 100 }}
                                        </span>
                                        <span v-else>
                                            0
                                        </span>
                                        %
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-flex justify-content-center" v-else>
                        <p>
                            <i class="fa fa-exclamation-triangle" style="color:white; font-size: 16px"></i> &nbsp;
                            No data to show. Consider expending your search dates
                        </p>
                    </div>
                </div>
            </card>
        </div>
    </div>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import { 
    Collapse, 
    CollapseItem 
} from 'src/components';

import { Tooltip } from 'element-ui'

export default {
    components: {
        CountryFlag,
        Collapse,
        CollapseItem,
        [Tooltip.name]: Tooltip
    },
    props:{
        total: {
            type: Number,
            default: 0
        },
        loading: {
            type: Boolean,
            default: false
        },
        countries:{
            type: Object,
            default: () => {}
        },
        cities:{
            type: Object,
            default: () => {}
        },
        platforms:{
            type: Object,
            default: () => {}
        },
        countryCodes:{
            type: Object,
            default: () => {}
        },
    },
    watch:{
        'cities': {
            handler: function(newVal, oldVal) {
                
                this.filteredCities = Object.keys(newVal).map(city => {
                    return {
                        city: city,
                        country: newVal[city].country,
                        total: newVal[city].clicks
                    }
                }).sort((a, b) => b.total - a.total)
                

            },
            deep: true
        },
        'countries': {
            handler: function(newVal, oldVal) {
                this.filteredCountries = Object.keys(newVal).map(ccode => {
                    return {
                        country: ccode,
                        total: newVal[ccode].clicks
                    }
                }).sort((a, b) => b.total - a.total)
            },
            deep: true
        },
        'platforms': {
            handler: function(newVal, oldVal) {
                this.filteredPlatform = Object.keys(newVal).map(platorm => {
                    return {
                        platform: platorm,
                        total: newVal[platorm].clicks
                    }
                }).sort((a, b) => b.total - a.total)
            },
            deep: true
        }
    },
    data() {
        return {
            filteredCities: [],
            filteredCountries: [],
            filteredPlatform: []
        }
    },
    methods: {
    }
}
</script>

<style scoped>
    .card-chart .table td {
        padding: 0px 0px 0px 15px !important;
        border: none;
    }

    .table thead th {
        border-top: none;
        color: #ffffff;
        font-weight: 100;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1)
    }

    .card-collapse .card .card-body {
        padding: 0px 0px 0px 15px !important;
    }

    .chart-area {
        padding: 0px 10px 0px 10px;
    }

    .card-chart .chart-area {
        height: 250px;
    }
</style>