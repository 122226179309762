<template>
    <card>
         <template slot="header">
            <h4 class="card-title">Global Statistics Glance</h4>
            <h5 class="card-category">Global View Of Your Messages </h5>
        </template>
        <div class="row">
            <div class="col-lg-12">

                <WorldMap  ref="map" class="map map-big" />
                <i v-if="loading" class="fa fa-spinner fa-spin" style="color:white"></i>
                <div v-if="!data.length && !loading" style="min-height: 400px" class="d-flex justify-content-center" >
                    <p>
                    <i class="fa fa-exclamation-triangle" style="color:white; font-size: 16px"></i> &nbsp;
                        No data to show. Consider expending your search dates
                    </p>
                </div>
            </div>
        </div>
    </card>
</template>

<script>
import WorldMap from './WorldMapBase.vue';
const TwolettertoThreeletter = require('../../../../json/2letterto3letter.json')

export default {
    components: {
        WorldMap
    },
    props: ['data', 'loading'],
    watch:{
        'data': function(nv) {      
            
            this.mapData = Object.values(nv).reduce((hash, val) => {  

                const total = Number(val.total)  || 0
                const delivered = Number(val.delivered)  || 0
                const failed = Number(val.failed) || 0
                const processing = Number(val.processing) || 0
                const other = Number(val.other) || 0
                

                if(hash[TwolettertoThreeletter[val.country]]){
                    hash[TwolettertoThreeletter[val.country]].total += total
                    hash[TwolettertoThreeletter[val.country]].delivered += delivered
                    hash[TwolettertoThreeletter[val.country]].failed += failed
                    hash[TwolettertoThreeletter[val.country]].processing += processing
                    hash[TwolettertoThreeletter[val.country]].other += other
                }else{
                    hash[TwolettertoThreeletter[val.country]] = {
                        total: total,
                        delivered: delivered,
                        failed: failed,
                        processing: processing,
                        other: other
                    }
                }

                return  hash

            }, {})

            

            this.$refs.map.initVectorMap(this.mapData);
        }
    },
    data(){
        return {
            mapData: {},
        }
    },
    async mounted() {
        //this.$refs.map.initVectorMap();
    }
}
</script>

<style>

</style>