<template>
  <div class="w-100">
    
    <div class="row">
      <div class="col-lg-12">
        <card>
          <div class="row">
            <div class="col-lg-3">
                <router-link 
                  class="btn btn-primary btn-simple" 
                  to="/campaigns/new" tag="button">  
                      <i class="fa fa-plus" aria-hidden="true"></i> &nbsp; New Campaign
                </router-link>
            </div>
          </div>
        </card>
      </div>
    </div>

    
    <div class="row">
      <div class="col-lg-12" id="cmp-tbl-wrraper">
            
          <tabs type="primary" square :clickHandler="currentTab">
            <tab-pane label="Active">
              <div slot="label">
                  Active Campaigns
              </div>
              <Table 
              :rows="tabsRows" 
              :pageCount="pageCount" 
              :perPage="perPage" 
              :total="tabsRows.length" 
              :tabName="currentTabName"
              :action="action"
              :loading="loading"
              />
            </tab-pane>
            
            <tab-pane label="Expired" >
              <div slot="label">
                Expired Campaigns
              </div>
              <Table 
                :rows="tabsRows" 
                :pageCount="pageCount" 
                :perPage="perPage" 
                :total="tabsRows.length" 
                :tabName="currentTabName"
                :action="action"
                :loading="loading"
              />
            </tab-pane>

            <tab-pane label="Stopped" >
              <div slot="label">
                Stopped Campaigns
              </div>
              <Table 
                :rows="tabsRows" 
                :pageCount="pageCount" 
                :perPage="perPage" 
                :total="tabsRows.length" 
                :tabName="currentTabName"
                :action="action"
                :loading="loading"
              />
            </tab-pane>            
          </tabs>
      </div>
    </div>

    <modal 
      style="padding:25px;"
      :show.sync="showShoretUrlWarningModal" 
      size="large"       
      footerClasses="footer-padding">
      <h3 slot="header" class="title title-up">
        URL shortener in SMS Campaigns
      </h3>
      <p>
        Our platform provides short URLs for your campaigns when you so require with our free to use URL shortener, 
        but please be aware that some carriers or devices may block them, impacting click-through rates. <br><br>
        If you notice a CTR below 5%, we recommend contacting our support team for assistance and alternative solutions.
      </p>
      <template slot="footer">

        <base-button 
          native-type="submit" 
          type="primary"
          simple
          @click="confirmShortUrlDisclaimer()">
          GOT IT
        </base-button>
        
      </template>
    </modal>
    


  </div>
</template>

<script>
import { Modal } from 'src/components';

//import Table from './Componenets/Table.vue';
import Table from './Componenets/Tablee.vue';
import { TabPane, Tabs, Badge } from 'src/components';
export default {
  components: {
    Table,
    TabPane,
    Tabs,
    Badge,
    Modal
  },
  data(){
    return {
      showShoretUrlWarningModal: false,
      data: [],
      tabsRows: [],
      perPage: 10,
      pageCount: 1,
      loading: false,
      currentTabName: "Active"
    }
  },
  created(){
    const { shortUrlDisclaimer } = this.$store.getters
    
    if(!shortUrlDisclaimer) this.showShoretUrlWarningModal = true
    //this.loadData()
    this.currentTab()

  },
  methods: {
    async confirmShortUrlDisclaimer(){
      this.showShoretUrlWarningModal = false
      const done = await this.$store.dispatch('shortUrlDisclaimer', true)  
      //this.$store.
      //
    },
    async currentTab(currentTab){
      this.currentTabName = currentTab || this.currentTabName
      this.tabsRows = []
      await this.loadData()
    },
    async action(action, data){
      try{
        const idx = this.data.findIndex(item => item.id == data.id)
        this.loading = !this.loading
        const res = await this.$http.get(`${process.env.VUE_APP_API_ENDPOINT}/campaigns/${action}/${data.id}`)
        if(res && res.status == 200){ 
          
          const type = res.data ? 'success' : 'error'
          const text = type == 'success' ? `${action}ed` : 'Something went wrong'
          this.$toast[type](`campaign ${data.campaign_id} ${text}`);
          this.data[idx] = res.data
          this.currentTab(this.currentTabName)
        }
        this.loading = !this.loading
      }catch(e){
        this.loading = !this.loading
      }
    },
    async loadData(){
      try{
        this.loading = !this.loading
        const api_endpoint = process.env.VUE_APP_API_ENDPOINT
        //const res = await this.$http.get(`${api_endpoint}/campaigns/read`)
        const res = await this.$http.get(`${api_endpoint}/campaigns/${this.currentTabName.toLowerCase()}`)
        if(res && res.status == 200){
          this.data = res.data
          this.tabsRows = res.data
          this.pageCount = Math.ceil(this.tabsRows.length / this.perPage)
        }
        this.loading = !this.loading
      }catch(e){
        this.loading = !this.loading
      }
    }
  }
}
</script>

<style >
  #cmp-tbl-wrraper .tab-content {
    width: 100% !important;
  }

  #cmp-tbl-wrraper .el-table table {
    width: 100% !important;
  }
</style>